import React, { useEffect, useState } from 'react'
import uiConfig from '../../ui/uiConfig'
import {
    CircularProgress,
    Typography
} from '@material-ui/core'
import ClosableDialogTitle from '../../ui/ClosableDialogTitle'

import DniReport from './DniReport'

import { fetchStats, aggregateNaturtypeStats, statType } from '../../service/statsService'

const ReportSidebarContainer = props => {
    const { title, top, fullWidth, expanded, selectedFeatureType, selectedFeatures, onClose } = props


    const [dniData, setDniData] = useState(null)
    const [tilstandData, setTilstandData] = useState(null)
    const [beskyttelseData, setBeskyttelseData] = useState(null)
    const [procesData, setProcesData] = useState(null)
    const [loadingDni, setLoadingDni] = useState(true)

    const [error, setError] = useState(false)
    const paddingLeft = expanded ? 16 : 0
    const paddingTop = 8
    const width = expanded ? fullWidth - paddingLeft : 0
    const contentTopMargin = 0
    const heightStyle = 'calc(100vh - ' + contentTopMargin + 'px - ' + top + 'px - ' + 1 * paddingTop + 'px)'
    const transitionStyle = 'all ' + uiConfig.report.animationSpeed + 's'
    //const aggregatedFeatureData = aggregateFeatureData(featureData)    

    //console.log('selectedfeaturetype', selectedFeatureType)

    useEffect(() => {
        //console.log('useEffect')
        setLoadingDni(true)
        setTimeout(async () => {
            if (!selectedFeatures || selectedFeatures.length === 0) {
                setDniData(null)
                return
            }
            try {
                const data = await fetchStats(selectedFeatureType, selectedFeatures.map(s => s.features[0].attributes), statType.dni)
                //console.log('data', data)
                setDniData(aggregateNaturtypeStats(data, statType.dni))
                setLoadingDni(false)
                setError(false)

                const _tilstandData = await fetchStats(selectedFeatureType, selectedFeatures.map(s => s.features[0].attributes), statType.tilstand)
                setTilstandData(aggregateNaturtypeStats(_tilstandData, statType.tilstand))
                const _beskyttelseData = await fetchStats(selectedFeatureType,selectedFeatures.map(s => s.features[0].attributes), statType.beskyttelse)
                setBeskyttelseData(aggregateNaturtypeStats(_beskyttelseData, statType.beskyttelse))
                const _procesData = await fetchStats(selectedFeatureType,selectedFeatures.map(s => s.features[0].attributes), statType.proces)
                setProcesData(aggregateNaturtypeStats(_procesData, statType.proces))
            } catch (error) {
                console.error(error)
                setLoadingDni(false)
                setError({ code: 'LOAD_ERROR' })
            }
        }, 1)
    }, [selectedFeatures, selectedFeatureType, setLoadingDni, setError])
    //    console.log(aggregatedFeatureData)
    return (
        <div style={
            {
                position: 'fixed',
                top,
                right: 0,
                display: 'block',
                minHeight: heightStyle,
                maxHeight: heightStyle,
                transition: transitionStyle,
                paddingLeft: paddingLeft,
                paddingTop: paddingTop,
                overflowY: 'auto',
                overflowX: 'hidden',
                minWidth: width, width, maxWidth: width, backgroundColor: 'white', margin: 0
            }}>
            <ClosableDialogTitle onClose={() => onClose()}>{title}</ClosableDialogTitle>
            {
                loadingDni && <CircularProgress />
            }
            { error ?
                <Typography variant='h6'>Ingen data</Typography>
                :
                (!dniData ?
                    <CircularProgress />
                    :
                    <React.Fragment>
                        <DniReport legend legendPosition={'bottom'} title='Dansk Naturindikator' data={dniData} />
                        <DniReport title='Tilstand' data={tilstandData} />
                        <DniReport title='Beskyttelse' data={beskyttelseData} />
                        <DniReport title='Proces' data={procesData} />
                    </React.Fragment>
                )
            }
        </div>
    )
}
export default ReportSidebarContainer