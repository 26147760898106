import {
    score0100RedColors 
} from './layerColors'
import layerTypes from './layerTypes'
import { bioscoreHelp } from  '../../map/layers/help/helpTilstand'

import {
    numberMapper
} from './dataMappers'

export const bioscore = {
    title: 'Tilstand',
    awsTiles: false,
    type: layerTypes.layer,                     
    id: 'tilstand',
    legend: [
        { title: '0', color: score0100RedColors[0] },
        { title: '12', color: score0100RedColors[1] },
        { title: '25', color: score0100RedColors[2] },
        { title: '37', color: score0100RedColors[3] },
        { title: '50', color: score0100RedColors[4] },
        { title: '62', color: score0100RedColors[5] },
        { title: '75', color: score0100RedColors[6] },
        { title: '87', color: score0100RedColors[7] },
        { title: '100', color: score0100RedColors[8] }        
    ],
    help: bioscoreHelp,
    dataMapper: numberMapper
}

const layers = [
    bioscore
]

export default layers