import React from 'react'
import { HelpSection, HelpContainer, HelpParagraph, HelpLink, InfoBox } from './help'
// import { Typography } from '@material-ui/core'

export const bioscoreHelp = (layer) => (
    <HelpContainer title={'Tilstand'}>
        <HelpSection>
            <HelpParagraph>
                Naturtilstanden er beregnet som bioscoren fra biodiversitetskortet.
                Denne score er beregnet ved at kombinere viden om fund og levesteder for rødlistede arter af planter,
                dyr og svampe med kortlag over egenskaber ved miljøet som indikerer gode levesteder for rødlistede arter.
                Beregningen af bioscoren er forklaret <HelpLink href="https://dce2.au.dk/pub/TR122.pdf" value="her"/>.
            </HelpParagraph>
            <HelpParagraph>
                I DNI har vi re-skaleret bioscoren (0 - 20), så alle værdier af bioscoren på 12 og derover sættes til 100 i erkendelse af, at der kan være forskel på hvor
                godt arterne er registreret forskellige steder i Danmark, ligesom der er forskel på forskellige naturtypers naturlige artsrigdom.
            </HelpParagraph>
        </HelpSection>     
        <InfoBox version={layer.version} />  
    </HelpContainer>
)
