import React from 'react'

import { Map, View } from 'ol';
import { createWmtsFromDniLayer } from './ol/olUtils'

// POI 
import Feature from 'ol/Feature';
//import Overlay from 'ol/Overlay';
import Point from 'ol/geom/Point';
import { Icon, Style } from 'ol/style';
import VectorSource from 'ol/source/Vector';
import { Vector as VectorLayer } from 'ol/layer';
// End

import { Group as LayerGroup } from 'ol/layer'
import { defaults as interactionDefaults, DoubleClickZoom, Draw } from 'ol/interaction'


import { defaults as defaultControls } from 'ol/control';
import LegendControl from './controls/LegendControl'
//import DrawControl from './controls/DrawControl'
import BoxShadowControl from './controls/BoxShadowControl'

import selectedFeaturesHandler from './layers/vector/selectedFeaturesLayer'
import kortforsyningen from './kortforsyningen'
import mapConfig from './mapConfig'

import 'ol/ol.css';
import mapMarkerPng from './icons/map-marker.png'
import { range } from '../service/utils';
import uiConfig from '../ui/uiConfig'

const createBackgroundLayerGroup = (layerDefintions) => {
    const layers = layerDefintions.map((l, idx) => kortforsyningen(l, l.title, mapConfig.kortforsyningen.token, idx === 0))
    return new LayerGroup(
        {
            title: 'Skærmkort',
            layers
        })
}

const createOverlayGroup = (layerDefintions) => {
    const layers = layerDefintions.map(l => createWmtsFromDniLayer(l))
    return new LayerGroup(
        {
            layers
        }
    )
}

const createAoiGroup = (layerDefintions) => {
    const layers = layerDefintions.map(l => l.handler.layerGroup).filter(l => l)
    layerDefintions.forEach(l => l.handler.setVisible(false))
    return new LayerGroup(
        {
            layers
        }
    )
}


var poiIconFeature = new Feature({
    geometry: new Point([482535.46645291976, 6097299.506691199]),
    name: 'Null Island',
    population: 4000,
    rainfall: 500,
});

var poiIconStyle = new Style({
    image: new Icon({
        anchor: [0.5, 1],
        anchorXUnits: 'fraction',
        anchorYUnits: 'fraction',
        color: '#0088ff',
        src: mapMarkerPng,
        scale: 0.08,
        imgSize: [384, 512]
    }),
});
// './logo192.png'
//'map/icons/map-marker-alt-solid.svg'

poiIconFeature.setStyle(poiIconStyle);

var poiVectorSource = new VectorSource({
    features: [poiIconFeature],
});

var poiVectorLayer = new VectorLayer({
    visible: false,
    source: poiVectorSource,
});

const createMap = (mapOptions) => {
    const {
        target,
        viewSettings,
        onMapClicked,
        kortforsyningLayers,
        selectedLayers,
        aoiLayers,
        areaOfInterestLayer,
        onZoom,
        doubleClickZoom
    } = mapOptions
    console.log(mapOptions)
    const { bounds, extent } = viewSettings

    const legend = selectedLayers && selectedLayers.length > 0 ? selectedLayers.map(l => l.legend).reduce((p, c) => c.concat(p)) : []
    const title = selectedLayers && selectedLayers.length === 1 ? selectedLayers[0].title : 'Fejl'
    const legendControl = new LegendControl({ title, legend })

    const controls = [
        legendControl
    ]


    const backgroundLayerGroup = createBackgroundLayerGroup(kortforsyningLayers)
    const dniLayerGroup = createOverlayGroup(selectedLayers)
    const aoiLayerGroup = createAoiGroup(aoiLayers)
    const map = new Map({
        target: target,
        controls: defaultControls().extend(controls),
        interactions: interactionDefaults({ doubleClickZoom }),
        renderer: 'webgl',
        layers: [
            backgroundLayerGroup,
            dniLayerGroup,
            aoiLayerGroup,
            selectedFeaturesHandler.layer,
            poiVectorLayer
        ],
        view: new View({
            projection: mapConfig.projection,
            extent: extent
        })
    });
    if (onMapClicked) {
        map.on('click', evt => {
            onMapClicked(evt, map.getView().getResolution(), mapConfig.projection)
        })
    }
    map.on('moveend', evt => {
        const zoom = map.getView().getZoom()
        const currentExtent = map.getView().calculateExtent(map.getSize())
        onZoom(zoom, currentExtent)
    })
    map.getView().fit(bounds, map.getSize());
    const zoom = map.getView().getZoom()
    const currentExtent = map.getView().calculateExtent(map.getSize())
    onZoom(zoom, currentExtent)
    map.addControl(new BoxShadowControl())
    return { map, dniLayerGroup, backgroundLayerGroup, legendControl, areaOfInterestLayer, selectedFeaturesHandler }
}

class ReactMap extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
        this.map = null
        this.dniLayerGroup = null
        this.backgroundLayerGroup = null
        this.legendControl = null
        this.projection = mapConfig.projection
        this.selectedLayer = null
        this.matrikelLayer = null
        this.selectedFeaturesHandler = null
        this.dniLayerOpacity = 1
    }

    setSelectedBackgroundLayer = (layer) => {
        this.selectedBackgroundLayer = layer
        if (!this.backgroundLayerGroup) {
            return
        }
        const layerId = layer.layer
        this.backgroundLayerGroup.getLayers().getArray().forEach(l => {
            const source = l.getSource()
            console.log(source.getLayer())
            const visible = source.getLayer() === layerId
            l.setVisible(visible)
        })
        this.backgroundLayerGroup.changed()
    }

    setDoubleClickZoom = (doubleClickZoom) => {
        const interactions = this.map.getInteractions()
        //console.log('doubleClickZoom', doubleClickZoom)
        if (doubleClickZoom) {
            let hasDoubleClickZoom = false
            for (let i = 0;i < interactions.getLength();i += 1) {
                let interaction = interactions.item(i)
                if (interaction instanceof DoubleClickZoom) {
                    hasDoubleClickZoom = true
                }
            }
            console.log('hasDoubleClickZoom', hasDoubleClickZoom)
            if (!hasDoubleClickZoom) {
                console.log('adding interaction')
                this.map.addInteraction(new DoubleClickZoom())
            }
            for (let i = 0;i < interactions.getLength();i += 1) {
                let interaction = interactions.item(i)
                if (interaction instanceof DoubleClickZoom) {
                    console.log('found doubleclickzoom', interaction)
                    hasDoubleClickZoom = true
                }
            }
        } else if (!doubleClickZoom) {
            this.map.getInteractions().forEach(i => {
                for (let i = 0;i < interactions.getLength();i += 1) {
                    let interaction = interactions.item(i)
                    //console.log('interaction', interaction)
                    if (interaction instanceof DoubleClickZoom) {
                        //console.log('removing doubleclickzoom interaction', interaction)
                        this.map.removeInteraction(interaction)
                        break;
                    }
                }
            })
        }        
    }

    setSelectedAoiLayer = (layer) => {
        this.selectedAoiLayer = layer
        this.aoiLayers.forEach(l => {
            if (l !== layer) {
                l.handler.clear()
                l.handler.setVisible(false)
            } else {
                const zoom = this.map.getView().getZoom()
                if (l.zoom && (zoom > l.zoom.min || zoom < l.zoom.max)) {
                    l.handler.setVisible(true)
                    const currentExtent = this.map.getView().calculateExtent(this.map.getSize())
                    l.handler.update(currentExtent)
                }
            }
        })
    }

    setSelectedLayers = (layers) => {
        this.selectedLayers = layers
        if (!this.dniLayerGroup) {
            return
        }
        const legend = []
        const existingLayers = []
        this.dniLayerGroup.getLayers().getArray().forEach(olLayer => {
            if (Boolean(layers.find(layer => olLayer.wmtsId === layer.wmtsId))) {
                existingLayers.push(olLayer)
            }
        })
        const layersToAdd = layers.filter(layer => !Boolean(existingLayers.find(ol => ol.wmtsId === layer.wmtsId))).map(layer => createWmtsFromDniLayer(layer))

        this.dniLayerGroup.getLayers().getArray().splice(0, this.dniLayerGroup.getLayers().getArray().length)
        console.log(existingLayers.map(l => l.wmtsId), this.dniLayerGroup.getLayers().getArray().map(l => l.wmtsId))
        console.log(layersToAdd.map(l => l.wmtsId))
        const newLayers = existingLayers.concat(layersToAdd)
        newLayers.sort((a, b) => a.idx - b.idx)
        newLayers.forEach(l => {
            this.dniLayerGroup.getLayers().getArray().push(l)
        })

        console.log(this.dniLayerGroup.getLayers().getArray())
        this.dniLayerGroup.changed()
        const title = this.selectedLayers && this.selectedLayers.length === 1 ? this.selectedLayers[0].title : 'Fejl'
        this.dniLayerGroup.getLayers().getArray().forEach(l => {
            
            l.legend.forEach(a => {
                legend.push(a)
            })
        })
        this.legendControl.updateLegend({ title, legend })
    }

    setSelectedFeatures = (features) => {
        if (!features || features.length === 0) {
            this.selectedFeaturesHandler.clear()
        } else {
            this.selectedFeaturesHandler.setFeatures(features)
        }
    }

    setPoi = (coordinate) => {
        if (coordinate) {
            poiIconFeature.setGeometry(new Point(coordinate))
            poiVectorLayer.setVisible(true)
            poiVectorLayer.changed()
        } else {
            poiVectorLayer.setVisible(false)
            poiVectorLayer.changed()
        }
    }

    setDrawInteraction = (enabled, onFeaturesSelected) => {
        if (enabled && this.draw) {
            return
        }
        if (!enabled && !this.draw) {
            return
        }
        if (enabled) {
            this.draw = new Draw({
                source: this.source,
                type: 'Polygon'
            })
            this.draw.on('drawend', (evt) => {
                const feature = evt.feature
                console.log(evt, feature.values_)
                const selectedFeatures = this.selectedAoiLayer.handler.getOverlappingGeometries(feature)
                onFeaturesSelected(selectedFeatures)
            })
            this.map.addInteraction(this.draw)
            this.map.getTargetElement().style.cursor = 'none'
        } else {
            this.map.removeInteraction(this.draw)
            this.map.getTargetElement().style.cursor = this.props.cursor
            this.draw = null
        }
    }

    getSelectedLayer = () => this.selectedLayer

    setDniLayerOpacity = opacity => {
        if (opacity >= 0 || opacity <= 1) {
            this.dniLayerOpacity = opacity
            this.dniLayerGroup.setOpacity(this.dniLayerOpacity)
            //this.dniLayerGroup.getLayersArray().forEach(l => l.setOpacity(this.dniLayerOpacity))
        }
    }

    componentDidMount = () => {
        const {
            id,
            bounds,
            kortforsyningLayers,
            selectedLayers,
            aoiLayers,
            extent,
            selectedAoiLayer,
            selectedBackgroundLayer,
            cursor,
            dniLayerOpacity,
            doubleClickZoom
        } = this.props

        const mapOptions = {
            target: id,
            viewSettings: { bounds, extent },
            kortforsyningLayers,
            selectedLayers,
            aoiLayers,
            doubleClickZoom: Boolean(doubleClickZoom),
            onClearAll: () => this.props.onClearAll(),
            onMapClicked: (evt, viewResolution, projection) => this.props.onMapClicked(evt, viewResolution, projection),
            onZoom: (zoom, extent) => {
                this.props.onZoom(zoom)
                if (this.selectedAoiLayer) {
                    if (this.selectedAoiLayer.zoom && (zoom < this.selectedAoiLayer.zoom.min || zoom > this.selectedAoiLayer.zoom.max)) {
                        this.selectedAoiLayer.handler.clear()
                    } else {
                        this.selectedAoiLayer.handler.update(extent)
                    }
                }
            }
        }
        const { map, dniLayerGroup, backgroundLayerGroup, legendControl, selectedFeaturesHandler } = createMap(mapOptions)
        this.map = map
        this.aoiLayers = this.props.aoiLayers
        this.dniLayerGroup = dniLayerGroup
        this.backgroundLayerGroup = backgroundLayerGroup
        this.legendControl = legendControl
        this.areaOfInterestLayer = selectedAoiLayer.handler
        this.selectedFeaturesHandler = selectedFeaturesHandler
        this.selectedLayers = selectedLayers
        this.setSelectedAoiLayer(selectedAoiLayer)
        this.setSelectedBackgroundLayer(selectedBackgroundLayer)
        this.setDniLayerOpacity(dniLayerOpacity)

        this.setDrawInteraction(this.props.drawInteraction, this.props.onFeaturesSelected)
        this.map.getTargetElement().style.cursor = cursor
    }

    componentDidUpdate(prevProps) {
        //console.log(this.props)
        if (this.props.selectedFeatures !== prevProps.selectedFeatures) {
            this.setSelectedFeatures(this.props.selectedFeatures)
        }
        if (this.props.poiLocation !== prevProps.poiLocation) {
            this.setPoi(this.props.poiLocation)
        }
        if (this.props.selectedLayers !== prevProps.selectedLayers) {
            //console.log('hello', this.props.selectedLayers)
            this.setSelectedLayers(this.props.selectedLayers)
        }
        if (this.props.selectedAoiLayer !== prevProps.selectedAoiLayer) {
            this.setSelectedAoiLayer(this.props.selectedAoiLayer)
        }
        if (this.props.selectedBackgroundLayer !== prevProps.selectedBackgroundLayer) {
            this.setSelectedBackgroundLayer(this.props.selectedBackgroundLayer)
        }
        if (this.props.showMatrikelLayer !== prevProps.showMatrikelLayer) {
            this.setShowMatrikelLayer(this.props.showMatrikelLayer)
        }
        if (this.props.left !== prevProps.left) {
            const duration = uiConfig.sidebar.animationSpeed * 1000 + 250
            range(150, duration, 15).map(t => setTimeout(() => this.map.updateSize(), t))
        }
        if (this.props.right !== prevProps.right) {
            this.legendControl.setPositionRight(this.props.right)
            const duration = uiConfig.report.animationSpeed * 1000 + 250
            range(150, duration, 15).map(t => setTimeout(() => this.map.updateSize(), t))
        }
        if (this.props.drawInteraction !== prevProps.drawInteraction) {
            this.setDrawInteraction(this.props.drawInteraction, this.props.onFeaturesSelected)
        }
        if (this.props.cursor !== prevProps.cursor) {
            this.map.getTargetElement().style.cursor = this.props.cursor
        }
        if (this.props.dniLayerOpacity !== prevProps.dniLayerOpacity) {
            this.setDniLayerOpacity(this.props.dniLayerOpacity)
        }
        if (this.props.doubleClickZoom !== prevProps.doubleClickZoom) {
            this.setDoubleClickZoom(this.props.doubleClickZoom)
        }
    }

    setShowMatrikelLayer = (visible) => {
        this.matrikelLayer.setVisible(visible)
    }

    render = () => {
        const { id, left, right, top } = this.props // 
        // const { wide } = this.props
        const height = 'calc(100vh - ' + top + 'px)'
        const width = 'calc(100% - ' + left + 'px - ' + right + 'px)'
        //const width = 'calc(100% - ' + left + 'px)'
        const widthDelay = '0s'; // wide ? '0s' : '0.8s'
        const widthDuration = uiConfig.sidebar.animationSpeed + 's'; // wide ? '0.8s' : '0s'
        const transition = 'left ' + uiConfig.sidebar.animationSpeed + 's 0s, width ' + widthDuration + ' ' + widthDelay
        return (
            <div key={id} id={id} style={{ transition, position: 'fixed', top, left, width, height }}>
            </div>
        )
    }
}
// style = { transition: 'width 0.8s', width, height, float: position }
export default ReactMap