//import React from "react"
import { useTheme } from '@material-ui/core/styles'

const colorMap = {
    'primary': theme => theme.palette.primary.main,
    'secondary': theme => theme.palette.secondary.main,
    'inherit': theme => theme.palette.action.active,
    'black': theme => '#222',
    'white': theme => '#f8f8f8',
    'gray': theme => '#e8e8e8',
    'light-gray': theme => '#d0d0d0',
    'dark-gray': theme => '#505050',
    'disabled': theme => theme.palette.action.disabled
}

const getColor = (colorName, theme) => {
    if (colorName.startsWith('#')) {
        console.log(colorName)
        return colorName
    }
    if (!colorMap[colorName]) {
        return colorMap.inherit(theme)
    }
    return colorMap[colorName](theme)
}

const withIcon = child => props => {
    const title = props.title
    const disabled = Boolean(props.disabled)
    const colorName = disabled ? 'disabled' : props.color
    const theme = useTheme()
    const color = getColor(colorName, theme)
    const width = props.size || 32
    const height = props.size || 32
    const style = { fill: color }

    return child({ title, style, width, height})
}

export default withIcon