import React from 'react'
import { HelpSection, HelpContainer, HelpParagraph, InfoBox, HelpLink } from './help'
// import { Typography } from '@material-ui/core'

export const trussel1Help = (layer) => (
    <HelpContainer title={'Hugst'}>
        <HelpSection>
            <HelpParagraph>
                <HelpLink href='/dokumentation/1_trusselkatalog#træfældning-hugst-tynding-og-sankning-i-skov' value="Læs mere her." />
            </HelpParagraph>
        </HelpSection>
        <InfoBox version={layer.version} />
    </HelpContainer>
)

export const trussel2Help = (layer) => (
    <HelpContainer title={'Rydning af vedplanter'}>
        <HelpSection>
            <HelpParagraph>
                <HelpLink href='/dokumentation/1_trusselkatalog#rydning-af-vedplanter-fra-lysåbne-arealer' value="Læs mere her." />
            </HelpParagraph>
        </HelpSection>
        <InfoBox version={layer.version} />
    </HelpContainer>
)

export const trussel3Help = (layer) => (
    <HelpContainer title={'Overgræsning'}>
        <HelpSection>
            <HelpParagraph>
                <HelpLink href='/dokumentation/1_trusselkatalog#overgr%C3%A6sning-med-husdyr-om-sommeren' value="Læs mere her." />
            </HelpParagraph>
        </HelpSection>
        <InfoBox version={layer.version} />
    </HelpContainer>
)

export const trussel4Help = (layer) => (
    <HelpContainer title={'Grødeskæring i vandløb'}>
        <HelpSection>
            <HelpParagraph>
                <HelpLink href='/dokumentation/1_trusselkatalog#grødeskæring-i-vandløb' value="Læs mere her." />
            </HelpParagraph>
        </HelpSection>
        <InfoBox version={layer.version} />
    </HelpContainer>
)

export const trussel5Help = (layer) => (
    <HelpContainer title={'Fjernelse af ådsler'}>
        <HelpSection>
            <HelpParagraph>
                <HelpLink href='/dokumentation/1_trusselkatalog#fjernelse-af-potentielle-ådsler-fra-naturområder' value="Læs mere her." />
            </HelpParagraph>
        </HelpSection>
        <InfoBox version={layer.version} />
    </HelpContainer>
)

export const trussel6Help = (layer) => (
    <HelpContainer title={'Konvertering til jordbrug'}>
        <HelpSection>
            <HelpParagraph>
                <HelpLink href='/dokumentation/1_trusselkatalog#konvertering-af-naturområder-til-jordbrug' value="Læs mere her." />
            </HelpParagraph>
        </HelpSection>
        <InfoBox version={layer.version} />
    </HelpContainer>
)

export const trussel7Help = (layer) => (
    <HelpContainer title={'Konvertering til skovbrug'}>
        <HelpSection>
            <HelpParagraph>
                <HelpLink href='/dokumentation/1_trusselkatalog#konvertering-af-naturområder-til-skovbrug-intensiv-forstlig-drift' value="Læs mere her." />
            </HelpParagraph>
        </HelpSection>
        <InfoBox version={layer.version} />
    </HelpContainer>
)

export const trussel8Help = (layer) => (
    <HelpContainer title={'Konvertering til bebyggelse'}>
        <HelpSection>
            <HelpParagraph>
                <HelpLink href='/dokumentation/1_trusselkatalog#konvertering-af-naturområder-til-bebyggelse-og-befæstelse' value="Læs mere her." />
            </HelpParagraph>
        </HelpSection>
        <InfoBox version={layer.version} />
    </HelpContainer>
)

export const trussel9Help = (layer) => (
    <HelpContainer title={'Konvertering til råstofindvinding'}>
        <HelpSection>
            <HelpParagraph>
                <HelpLink href='/dokumentation/1_trusselkatalog#konvertering-af-naturområder-til-råstofindvinding' value="Læs mere her." />
            </HelpParagraph>
        </HelpSection>
        <InfoBox version={layer.version} />
    </HelpContainer>
)

export const trussel10Help = (layer) => (
    <HelpContainer title={'Konvertering til infrastruktur'}>
        <HelpSection>
            <HelpParagraph>
                <HelpLink href='/dokumentation/1_trusselkatalog#konvertering-af-naturområder-til-infrastruktur' value="Læs mere her." />
            </HelpParagraph>
        </HelpSection>
        <InfoBox version={layer.version} />
    </HelpContainer>
)


export const trussel11Help = (layer) => (
    <HelpContainer title={'Efterstræbelse af arter'}>
        <HelpSection>
        
            <HelpParagraph>
                <HelpLink href='/dokumentation/1_trusselkatalog#efterstræbelse-af-udryddelsestruede-sjældne-og-sårbare-arter' value="Læs mere her." />
            </HelpParagraph>
        </HelpSection>
        <InfoBox version={layer.version} />
    </HelpContainer>
)

export const trussel12Help = (layer) => (
    <HelpContainer title={'Biocider'}>
        <HelpSection>
            <HelpParagraph>
                <HelpLink href='/dokumentation/1_trusselkatalog#biocider-i-naturområder-sprøjtegifte-ormemidler-og-gnavergift' value="Læs mere her." />
            </HelpParagraph>
        </HelpSection>
        <InfoBox version={layer.version} />
    </HelpContainer>
)

export const trussel13Help = (layer) => (
    <HelpContainer title={'Miljøfremmede stoffer'}>
        <HelpSection>
            <HelpParagraph>
                <HelpLink href='/dokumentation/1_trusselkatalog#udledning-af-miljøfremmede-stoffer-fra-industri-trafik-og-private-hushold-til-vandmiljøet' value="Læs mere her." />
            </HelpParagraph>
        </HelpSection>
        <InfoBox version={layer.version} />
    </HelpContainer>
)

export const trussel14Help = (layer) => (
    <HelpContainer title={'Gødskning'}>
        <HelpSection>
            <HelpParagraph>
                <HelpLink href='/dokumentation/1_trusselkatalog#direkte-gødskning-af-naturområder' value="Læs mere her." />
            </HelpParagraph>
        </HelpSection>
        <InfoBox version={layer.version} />
    </HelpContainer>
)


export const trussel15Help = (layer) => (
    <HelpContainer title={'Kvælstofdeposition'}>
        <HelpSection>
            <HelpParagraph>
                <HelpLink href='/dokumentation/1_trusselkatalog#våd--og-tørdeposition-af-kvælstof-fra-lokale-landbrug' value="Læs mere her." />
            </HelpParagraph>
        </HelpSection>
        <InfoBox version={layer.version} />
    </HelpContainer>
)

export const trussel16Help = (layer) => (
    <HelpContainer title={'Tab af næringsstoffer'}>
        <HelpSection>
            <HelpParagraph>
                <HelpLink href='/dokumentation/1_trusselkatalog#utilsigtet-tilførsel-af-næringsstoffer-afdrift-jordfygning-udvaskning-fra-naboarealer-til-naturområder' value="Læs mere her." />
            </HelpParagraph>
        </HelpSection>
        <InfoBox version={layer.version} />
    </HelpContainer>
)

export const trussel17Help = (layer) => (
    <HelpContainer title={'Dræning'}>
        <HelpSection>
            <HelpParagraph>
                <HelpLink href='/dokumentation/1_trusselkatalog#dræninggrøftning-af-våde-terrestriske-naturområder' value="Læs mere her." />
            </HelpParagraph>
        </HelpSection>
        <InfoBox version={layer.version} />
    </HelpContainer>
)

export const trussel18Help = (layer) => (
    <HelpContainer title={'Vandindvinding'}>
        <HelpSection>
            <HelpParagraph>
                <HelpLink href='/dokumentation/1_trusselkatalog#indvinding-af-grundvand-og-overfladevand-fra-vandløb' value="Læs mere her." />
            </HelpParagraph>
        </HelpSection>
        <InfoBox version={layer.version} />
    </HelpContainer>
)

export const trussel19Help = (layer) => (
    <HelpContainer title={'Udretning af vandløb'}>
        <HelpSection>
            <HelpParagraph>
                <HelpLink href='/dokumentation/1_trusselkatalog#udretning-kanalisering-sikring-mod-mæandrering-nedgravning-etablering-af-diger-og-opstemning-af-vandløb' value="Læs mere her." />
            </HelpParagraph>
        </HelpSection>
        <InfoBox version={layer.version} />
    </HelpContainer>
)

export const trussel20Help = (layer) => (
    <HelpContainer title={'Regulering og jagt'}>
        <HelpSection>
            <HelpParagraph>
                <HelpLink href='/dokumentation/1_trusselkatalog#reguleringjagt-af-vilde-pattedyr' value="Læs mere her." />
            </HelpParagraph>
        </HelpSection>
        <InfoBox version={layer.version} />
    </HelpContainer>
)

export const trussel21Help = (layer) => (
    <HelpContainer title={'Tilskudsfodring'}>
        <HelpSection>
            <HelpParagraph>
                <HelpLink href='/dokumentation/1_trusselkatalog#reduktion-og-homogenisering-af-græsning-som-proces-i-økosystemet-som-følge-af-fodring-eller-etablering-af-fodermarker-og-vildtagre' value="Læs mere her." />
            </HelpParagraph>
        </HelpSection>
        <InfoBox version={layer.version} />
    </HelpContainer>
)

export const trussel22Help = (layer) => (
    <HelpContainer title={'Mangel på græsning'}>
        <HelpSection>
            <HelpParagraph>
                <HelpLink href='/dokumentation/1_trusselkatalog#mangel-på-græsning-som-proces-i-økosystemet-som-følge-af-vinteropstaldning' value="Læs mere her." />
            </HelpParagraph>
        </HelpSection>
        <InfoBox version={layer.version} />
    </HelpContainer>
)

export const trussel23Help = (layer) => (
    <HelpContainer title={'Slukning af naturbrande'}>
        <HelpSection>
            <HelpParagraph>
                <HelpLink href='/dokumentation/1_trusselkatalog#slukning-af-naturbrande' value="Læs mere her." />
            </HelpParagraph>
        </HelpSection>
        <InfoBox version={layer.version} />
    </HelpContainer>
)

export const trussel24Help = (layer) => (
    <HelpContainer title={'Kystsikring'}>
        <HelpSection>
            <HelpParagraph>
                <HelpLink href='/dokumentation/1_trusselkatalog#kystsikring-i-form-af-diger-høfder-og-lignende' value="Læs mere her." />
            </HelpParagraph>
        </HelpSection>
        <InfoBox version={layer.version} />
    </HelpContainer>
)

export const trussel25Help = (layer) => (
    <HelpContainer title={'Sandflugtsdæmpning'}>
        <HelpSection>
            <HelpParagraph>
                <HelpLink href='/dokumentation/1_trusselkatalog#sandflugtsdæmpning-i-form-af-tilplantning-og-opsætning-af-gran-ris' value="Læs mere her." />
            </HelpParagraph>
        </HelpSection>
        <InfoBox version={layer.version} />
    </HelpContainer>
)


export const trusselHelpList = [
    trussel1Help,
    trussel2Help,
    trussel3Help,
    trussel4Help,
    trussel5Help,
    trussel6Help,
    trussel7Help,
    trussel8Help,
    trussel9Help,
    trussel10Help,
    trussel11Help,
    trussel12Help,
    trussel13Help,
    trussel14Help,
    trussel15Help,
    trussel16Help,
    trussel17Help,
    trussel18Help,
    trussel19Help,
    trussel20Help,
    trussel21Help,
    trussel22Help,
    trussel23Help,
    trussel24Help,
    trussel25Help
]
