import {
    score0100BlueColors
} from './layerColors'
import layerTypes from './layerTypes'
import { 
    samletBeskyttelseHelp
 } from '../../map/layers/help/helpBeskyttelse'

 import {
     floatMapper
 } from './dataMappers'

 import trusselLayers from './trusselLayers'
 

export const samletBeskyttelsesLayer = {
    title: 'Beskyttelse',
    type: layerTypes.layer,                     
    id: 'beskyttelse',
    awsTiles: false,
    legend: [
        { title: '0', color: score0100BlueColors[0] },
        { title: '12', color: score0100BlueColors[1] },
        { title: '25', color: score0100BlueColors[2] },
        { title: '37', color: score0100BlueColors[3] },
        { title: '50', color: score0100BlueColors[4] },
        { title: '62', color: score0100BlueColors[5] },
        { title: '75', color: score0100BlueColors[6] },
        { title: '87', color: score0100BlueColors[7] },
        { title: '100', color: score0100BlueColors[8] }
    ],
    help: samletBeskyttelseHelp,
    dataMapper: floatMapper
}

const layers = [
    samletBeskyttelsesLayer
].concat(trusselLayers)

export default layers
