import WMTS from 'ol/source/WMTS';
import WMTSTileGrid from 'ol/tilegrid/WMTS';
import TileLayer from 'ol/layer/Tile';
import mapConfig from '../mapConfig'
import TileWMS from 'ol/source/TileWMS';
import uiConfig from '../../ui/uiConfig'

const getTileGrid = lowres => {
    const tileGridConf = lowres ? mapConfig.geoserver.wmts.lowResTileGrid : mapConfig.geoserver.wmts.tileGrid
    const extent = mapConfig.geoserver.wmts.tileGrid.extent
    return new WMTSTileGrid({
        extent,
        resolutions: tileGridConf.resolutions,
        matrixIds: tileGridConf.matrixIds,
    });
}

const awsMaxZoomLevels = {
    "1": { min: 0, max: 3 },
    "2": { min: 0, max: 7 },
    "3": { min: 0, max: 15 },
    "4": { min: 0, max: 31 },
    "5": { min: 0, max: 63 },
    "6": { min: 0, max: 127 },
    "7": { min: 0, max: 255 },
    "8": { min: 0, max: 511 },
    "9": { min: 0, max: 1023 },
}

//let currentAwsServerIdx = 0
let awsServerPrefixes = [ 'a', 'b', 'c', 'd']

let counts = {
    a: 0,
    b: 0,
    c: 0,
    d: 0
}

export const createWmtsFromDniLayer = (layer) => {
    const { title, legend, wmtsId, idx } = layer
    const tileGrid = getTileGrid(Boolean(layer.lowres))
    const awsUrl = mapConfig.geoserver.wmts.awsUrl
    const baseUrl = mapConfig.geoserver.wmts.url
    const gridsetName = mapConfig.geoserver.wmts.gridset;
    const style = ''
    const protocol = window.location.protocol
    console.log(window.location.protocol)
    console.log({ protocol })
    const wmts = layer.awsTiles ? new WMTS({
        url: baseUrl,
        service: 'WMTS',
        matrixSet: gridsetName,
        layer: wmtsId,
        format: 'image/png',
        tileGrid: tileGrid,
        style: style,
        size: [256, 256],
        tileLoadFunction: (imageTile, src) => {
            const [z, x, y] = imageTile.getTileCoord()
            const {min, max } = awsMaxZoomLevels[z] || { min: 0, max: 10 } 
            let idx = (x + (31 * y)) % awsServerPrefixes.length
            counts[awsServerPrefixes[idx]]++
            const serverUrl = awsServerPrefixes[idx] + '.' + awsUrl 
            const newurl = `${protocol}//${serverUrl}/${wmtsId.split(':')[1]}/${z}/${x}/${max-(y+min)}.png`
            imageTile.getImage().src = newurl
        }
    }) : new WMTS({
        url: baseUrl,
        service: 'WMTS',
        matrixSet: gridsetName,
        layer: wmtsId,
        format: 'image/png',
        tileGrid: tileGrid,
        style: style,
        size: [256, 256]        
    })

    const tileLayer = new TileLayer({
        opacity: uiConfig.map.dniLayer.opacity,
        title: title,
        visible: true,
        source: wmts,      
    })
    tileLayer.idx = idx
    tileLayer.wmtsId = wmtsId
    tileLayer.legend = legend
    return tileLayer
}


export const createWmsSource = (layerId) => {
    const format = 'image/png';
    return new TileWMS({
        url: mapConfig.geoserver.wms.url,
        params: {
            'FORMAT': format,
            'VERSION': '1.3.0',
            tiled: true,
            "LAYERS": layerId,
            "exceptions": 'application/vnd.ogc.se_inimage',
            tilesOrigin: mapConfig.geoserver.wms.tilesOrigin
        }
    })
}

