import {
    get
} from './fetchUtil'

/*
this.fetchRegelsaetToC().then(data => regelsaet.toc = data)
            this.fetchRegelsaetDocs().then(data => regelsaet.content = data)
            this.fetchIkkeArealBundneToC().then(data => ikkeArealBundne.toc = data)
            this.fetchIkkeArealBundneDocs().then(data => ikkeArealBundne.content = data)
            */

class VectorLayerService {

    constructor() {
        /* global DNI_CONFIG */
        this.baseUrl = DNI_CONFIG.featuresUrl + "vector/"
        this.environment = DNI_CONFIG.environment
    }

    getBaseUrl() {
        return this.baseUrl
    }

    setBaseUrl(url) {
        this.baseUrl = url
    }

    async fetchLayerVersions() {
        return get(this.baseUrl, `vector-${this.environment}.json`)
    }
}


export default new VectorLayerService()