import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App.jsx';
import {
    BrowserRouter as Router,
} from "react-router-dom";

import * as serviceWorker from './serviceWorker';
import {
    ThemeProvider
} from '@material-ui/core/styles';

import theme from './ui/dniTheme'

import initChartjs from './chartjs3/init.js'

initChartjs()

const startReact = () => {
    ReactDOM.render( 
        <Router>
            <ThemeProvider theme = {theme}>
                <App /> 
            </ThemeProvider>
        </Router> ,
        document.getElementById('root')
    );
}

//const start = () => OlMap('root')
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

document.addEventListener("DOMContentLoaded", startReact);


serviceWorker.unregister();

/*
import {
    Map,
    View
} from 'ol';
import TileLayer from 'ol/layer/Tile';
import XYZSource from 'ol/source/XYZ';
import {
    fromLonLat
} from 'ol/proj';
import './ol.css';

const start = () => {
    console.log('content loaded')
    new Map({
        target: 'root',
        layers: [
            new TileLayer({
                source: new XYZSource({
                    url: 'http://tile.stamen.com/terrain/{z}/{x}/{y}.jpg'
                })
            })
        ],
        view: new View({
            center: fromLonLat([0, 0]),
            zoom: 2
        })
    });
}

*/