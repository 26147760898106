import React from 'react'
import { useState } from 'react'
import MapWrapper from '../map/MapWrapper'
import mapConfig from '../map/mapConfig';
    
//const laesoe = [620535, 6347299, 630204, 6360999]
const dk = [402535, 6017299, 919204, 6460999]
const MapPage = props => {
    //const { showSidebar } = props
    const [ showSidebar, setShowSidebar ] = useState(true)
    return (
        <MapWrapper id='map-container'
        heightReduction={64}
        bounds={dk}
        showSidebar={showSidebar}
        onOpenSidebar={() => setShowSidebar(true)}
        onCloseSidebar={() => setShowSidebar(false)}
        extent={mapConfig.geoserver.wmts.tileGrid.extent} />
    )
}

export default MapPage