import React from 'react'
import {
    Dialog,
    DialogContent,
    Paper
} from '@material-ui/core'
import ClosableDialogTitle from '../../ui/ClosableDialogTitle'
import dniLayerDefinitionService from '../../service/dniLayerDefinitionService'
import PoiGroupData from './PoiGroupData'
import Draggable from 'react-draggable'

function PaperComponent(props) {
    return (
        <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} />
        </Draggable>
    );
}

const PoiPopup = props => {
    const { point, show, onClose } = props
    //console.log(point)
    return (
        <Dialog
            PaperComponent={PaperComponent}
            onClose={onClose}
            scroll='paper'
            aria-labelledby="draggable-dialog-title"
            maxWidth={'lg'}
            open={show}>
            <ClosableDialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title" onClose={onClose}>
                Information om punktet
            </ClosableDialogTitle>
            <DialogContent dividers>
                {point ?
                    <div key={JSON.stringify(point.coordinate)} style={{ width: 480 }}>
                        <PoiGroupData title={'Naturindikator'} largeTitle point={point} layers={[dniLayerDefinitionService.getNaturindikator()]} />
                        <PoiGroupData title={'Tilstand'} point={point} layers={dniLayerDefinitionService.getTilstandLayers()} />
                        <PoiGroupData title={'Beskyttelse'} point={point} layers={dniLayerDefinitionService.getBeskyttelseLayers()} />
                        <PoiGroupData title={'Processer'} point={point} layers={dniLayerDefinitionService.getProcesLayers()} />
                        <div style={{ paddingBottom: 16}} />
                    </div> : null
                }
            </DialogContent>
        </Dialog>
    )
}

export default PoiPopup