import React from "react";
import withIcon from './withIcon'
const Icon = props => {
    const title = props.title || 'Paragraf'
    const { style, width, height } = props
    return (
        <svg
            id={title}
            aria-label={title}
            width={width}
            height={height}
            style={style}
            data-name={title} xmlns="http://www.w3.org/2000/svg"
            viewBox="12 10 67.04 65.04">
            <path d="M49.94,32.88a11.8,11.8,0,0,0-5.23-1.12c-2.36,0-3.59.76-3.59,1.92s1.39,1.91,4.27,2.79c4.31,1.32,7.59,2.88,7.59,7a5.71,5.71,0,0,1-2.92,4.88A4.66,4.66,0,0,1,51.82,52c0,4.88-5.11,6.39-8.83,6.39a14.61,14.61,0,0,1-7.47-1.75L36.68,53a13.26,13.26,0,0,0,6,1.68c2.12,0,3.64-.72,3.64-2.08s-1.2-2-4.24-3c-4.23-1.28-8-2.88-8-6.87a5.67,5.67,0,0,1,3.32-4.88,5,5,0,0,1-1.6-3.63c0-4,3.84-6.19,8.71-6.19A14.72,14.72,0,0,1,50.9,29.2ZM42.35,44.74c1.84.68,3.8,1.24,4.72,1.72a2.54,2.54,0,0,0,1.16-2.08c0-1.16-.8-2.11-2.76-2.87-1.68-.64-4-1.4-5.15-2a2.39,2.39,0,0,0-1.44,2.08C38.88,42.94,40,43.9,42.35,44.74Z" />
            <path d="M43.32,73.53h0a1,1,0,0,1-.63-.22C17.58,58.8,18.57,22.21,18.59,21.84a1,1,0,0,1,1.33-.91,21.18,21.18,0,0,0,22.64-5.26,1,1,0,0,1,1.5,0,21.19,21.19,0,0,0,22.65,5.26,1,1,0,0,1,.89.12,1,1,0,0,1,.43.79c0,.37,1,37-24.08,51.47A1,1,0,0,1,43.32,73.53ZM20.58,23.24c.09,6.57,1.7,35.64,22.73,48.13C64.34,58.88,66,29.81,66,23.24a23.51,23.51,0,0,1-22.73-5.49A23.5,23.5,0,0,1,20.58,23.24Z" />
        </svg>
    )
}

export default withIcon(Icon)