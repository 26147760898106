import React from "react"
import withIcon from './withIcon'
const Icon = props => {
    const title = props.title || 'Bison'
    const { style, width, height } = props
    return (
        <svg id={title} aria-label={title} width={width}
            height={height}
            style={style}
            data-name={title} xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 19.81 13.81">
            <path d="M19.19 7.51a.72.72 0 01-.52-.86.64.64 0 01.38-.46 2.27 2.27 0 012.8 2.66 4.67 4.67 0 00-.15 2.2.76.76 0 01-.29.57 2.13 2.13 0 00-.89 1.7c0 .45-.42.58-.76.71-.07 0-.24-.08-.28-.17-.27-.52-.52-1-.77-1.59-.17-.37-.65-.47-.78-.11-.32.87-1.2 1.14-1.74 1.76A12.19 12.19 0 0115 15l-.38-.59c-.69 1.24-.13 2.24.73 3.29h-2c-.11 0-.3-.19-.29-.27.09-.47-.49-.83-.2-1.32 0-.06 0-.17-.05-.31-.48.29-.71 0-.85-.36a11.44 11.44 0 01-.51-1.53c0-.07.06-.2.13-.26s.32-.19.48-.29l-.13-.27a4 4 0 00-.85 0c-.58.13-1.16.32-1.74.48a2.19 2.19 0 01-2.09-.45.72.72 0 01-.25-.61 3.7 3.7 0 01.29-.73 1.14 1.14 0 00-.12-1.24c.35.88-.34 1.48-.56 2.21l-.3 1.05c-.09.31-.18.62-.24.93a.53.53 0 00.06.35c.41.62.82 1.23 1.25 1.83.19.28.41.53.62.81a1 1 0 01-1.26-.2c-.07-.07-.19-.07-.28-.11s-.21-.08-.21-.13c-.08-.73-.7-1.11-1.07-1.63a2.8 2.8 0 01-.66-2.28.75.75 0 00-.09-.5c-.26.61-.51 1.23-.8 1.83a2.53 2.53 0 00.39 3 2.16 2.16 0 01.21.29c-.82.43-1.42.17-1.62-.68s-.4-1.88-.54-2.82a1.62 1.62 0 01.17-.94 2.7 2.7 0 000-1.73A8.81 8.81 0 012.5 8.1a1.66 1.66 0 011.34-1.2C5.29 6.54 6.63 6.12 8 5.75c1.66-.45 3.32-.92 5-1.29a9 9 0 013.86.33 2.43 2.43 0 011.05.71 2.46 2.46 0 01.35.81l.59-.78.14.11c-.12.25-.26.5-.37.76-.22.58.06 1.09.59 1.11-.16.16-.31.47-.47.47-.51 0-.72-.39-.91-.8a2.05 2.05 0 00.53.85" transform="translate(-2.1 -4.35)" />
        </svg>)
}
export default withIcon(Icon)