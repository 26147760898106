import React from "react"
import withIcon from './withIcon'
const Icon = props => {
    const title = props.title || 'Query'
    const { style, width, height } = props
    return (
        <svg id={title} aria-label={title} width={width}
            height={height}
            style={style}
            data-name={title} xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512">
            <path d="M18,65.41C56.76,159.27,94.44,250.51,132.65,343l60.54-61L321.83,410.74l43.29-43.17L236.82,239.33l59.33-59ZM367.46,243.49c10-3.25,18.82-7.23,28.11-8.92,12.54-2.28,26-2.64,34.17,9.46,8.33,12.33,2.32,24-5.14,34.71-3.48,5-7.57,9.54-11.13,14.48-5.24,7.24-11,14.27-15.23,22.06-8.62,15.73-11.85,32.54-7.91,50.22h51.05c-3.73-27,10.42-45.4,26.8-63.4A110.32,110.32,0,0,0,487,273.57c14.18-31.44,2.63-65.53-27.07-78.28-35.7-15.33-70.55-9-105.31,7.1ZM448.17,419.1c.08-21.09-14-35.62-34.54-35.57-20,.06-34.14,14.35-34.34,34.73-.21,20.82,14.21,35.58,34.62,35.46S448.08,439.59,448.17,419.1Z" /><path d="M18,65.41l278.15,114.9-59.33,59,128.3,128.24-43.29,43.17L193.19,282l-60.54,61C94.44,250.51,56.76,159.27,18,65.41Z" /><path d="M367.46,243.49l-12.87-41.1c34.76-16.15,69.61-22.43,105.31-7.1C489.6,208,501.15,242.13,487,273.57a110.32,110.32,0,0,1-18.79,28.53c-16.38,18-30.53,36.44-26.8,63.4H390.33c-3.94-17.68-.71-34.49,7.91-50.22,4.28-7.79,10-14.82,15.23-22.06,3.56-4.94,7.65-9.5,11.13-14.48,7.46-10.69,13.47-22.38,5.14-34.71-8.17-12.1-21.63-11.74-34.17-9.46C386.28,236.26,377.41,240.24,367.46,243.49Z" /><path d="M448.17,419.1c-.09,20.49-13.94,34.49-34.26,34.62s-34.83-14.64-34.62-35.46c.2-20.38,14.34-34.67,34.34-34.73C434.14,383.48,448.25,398,448.17,419.1Z" />
        </svg>
    )
}
export default withIcon(Icon)