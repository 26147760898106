import 'ol/ol.css';
import {
    Group as LayerGroup
} from 'ol/layer'
import GeoJSON from 'ol/format/GeoJSON';
import {
    Vector
} from 'ol/source';
//import VectorLayer from 'ol/layer/Vector';
import VectorImageLayer from 'ol/layer/VectorImage';
import {
    Fill,
    Stroke,
    Style
} from 'ol/style'

import matrikelService from '../../../service/matrikelService'

import {
    refresh,
    getFeatureFromCoordinate,
    getOverlappingGeometries
} from './vectorUtils'

const style = new Style({
    fill: new Fill({
        color: 'rgba(0, 0, 128, 0)',
    }),
    stroke: new Stroke({
        color: 'rgba(0, 0, 0,0.5)',
        width: 0.5,
    })
});

const createVectorSource = (box) => {
    const source = new Vector({
        format: new GeoJSON({
            featureProjection: "EPSG:28532"
        }),
        overlaps: false,
        loader: (extent, resolution, projection) => {
            matrikelService.fetchBox(box).then(featureCollection => {
                if (featureCollection) {
                    // console.log(box)
                    //source.clear(true)
                    source.featureCollection = featureCollection
                    source.addFeatures(source.getFormat().readFeatures(featureCollection))
                }
            })
        }
    })
    return source
}



const createLayer = (box) => {
    //console.log(box)
    const source = createVectorSource(box)
    //console.log(source)
    const layer = new VectorImageLayer({
        visible: true,
        source: source,
        style: function (feature) {
            return style;
        },
    });
    layer.id = box
    return layer
}

const layerGroup = new LayerGroup({
    title: 'Matrikel',
    visible: true,
    layers: []
})

const update = function (bbox, layerGroup) {
    const boxes = matrikelService.calcBoxes(bbox)
    //console.log(layerGroup.getLayers().getArray())
    if (boxes.length > 42) {
        console.log('too many boxes', bbox, boxes.length)
        return
    }
    //console.log('updating..')
    const existingLayers = layerGroup.getLayers().getArray().filter(l => boxes.find(b => b === l.id))
    layerGroup.getLayers().getArray().splice(0, layerGroup.getLayers().getArray().length)
    existingLayers.forEach(l => {
        layerGroup.getLayers().getArray().push(l)
    })
    layerGroup.changed()
    for (var i = 0; i < boxes.length; i++) {
        const box = boxes[i]
        if (!existingLayers.find(l => l.id === box)) {
            /**/
            const layer = createLayer(box)
            layerGroup.getLayers().getArray().push(layer)
            //layerGroup.addLayer()            
        }
    }
    //layerGroup.getLayers().getArray().forEach(l => l.getSource().changed())
    layerGroup.changed()
    // console.log('update complete')
}


const self = {
    layerGroup: layerGroup,
    setVisible: (visible) => layerGroup.setVisible(visible),
    clear: () => {
        layerGroup.getLayers().getArray().splice(0, layerGroup.getLayers().getArray().length)
    },
    update: (extent) => { update(extent, layerGroup);[300, 600, 900, 2000, 4000].forEach(delay => refresh(layerGroup, delay)) },
    getOverlappingGeometries: (polygon) => getOverlappingGeometries(polygon, layerGroup),
    getFeature: (coordinate) => getFeatureFromCoordinate(coordinate, layerGroup)
}

export default self