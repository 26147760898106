import Control from 'ol/control/Control';
import uiConfig from '../../ui/uiConfig'

import './LegendControl.css'

const CSS_PREFIX = 'legend-control-';

const legendEntry = (title, color) => {
    const e = document.createElement('div')
    e.className = CSS_PREFIX + 'row'
    const box = document.createElement('div')
    box.className = CSS_PREFIX + 'box'
    box.style = 'background-color: ' + color
    e.appendChild(box)

    const text = document.createElement('span')
    text.className = CSS_PREFIX + 'text'
    text.innerHTML = title
    e.appendChild(text)
    return e
}

const legendTitle = (title) => {
    const e = document.createElement('div')
    e.className = CSS_PREFIX + 'row'
    const text = document.createElement('span')
    text.className = CSS_PREFIX + 'title'
    text.innerHTML = title
    e.appendChild(text)
    return e
}


const pointerEvents = 'pointer-events: auto;'

const transitionStyle = speed => 'transition: all ' + speed + 's;'

export default class LegendControl extends Control {
    constructor(opt_options) {
        const options = opt_options || { title: '', legend: []};
        console.log('options', options)


        const containerElement = document.createElement('div');
        containerElement.className = CSS_PREFIX + 'container';
        super({
            element: containerElement,
            target: options.target
        });

        this.containerElement = containerElement
        this.updateLegend(options)
        this.setPositionRight(0)
    }

    updateLegend(options) {
        this.containerElement.innerHTML = ''
        this.containerElement.appendChild(legendTitle(options.title))
        options.legend.forEach(l => {
            this.containerElement.appendChild(legendEntry(l.title, l.color))
        })
    }

    /**
     * Set the map instance the control is associated with.
     * @param {ol/Map~Map} map The map instance.
     */
    setMap(map) {
        super.setMap(map);
    }

    setPositionRight(position) {
        const right = (position + 10) + 'px'
        this.containerElement.setAttribute('style', pointerEvents + ' ' + transitionStyle(uiConfig.report.animationSpeed) + ' right: ' + right + ';')
    }
}