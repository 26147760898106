import React from 'react'

const dataToString = (type, value) => {
    if (type === 'null') {
        return 'Ingen data'
    } else if (type === 'string') {
        return value
    } else if (type === 'list') {
        const labels = value.filter(v => v.value).map(v => v.label)
        if (labels.length > 0) {
            return labels.join(', ')
        } else {
            return ' - '
        }
    } else if (type === 'number') {
        return value
    } else if (type === 'boolean') {
        if (value === true) {
            return 'Ja'
        } else if (value === false) {
            return 'Nej'
        }
    }
}

export const PoiDataValue = props => {
    const { type, value } = props.data
    return (<span>{dataToString(type, value)}</span>)
}

const PoiDataLoading = props => {
    return (<span style={{ backgroundColor: '#ddd', marginLeft: 8, paddingRight: 80, paddingTop: 0, paddingBottom: 0, fontSize: 9}}></span>)
}

const PoiData = props => {
    const { title, data } = props
    return (
        <div>
            <span>{title}: </span>
            { data === null ?
                <PoiDataLoading />
                :
                <PoiDataValue data={data} />
            }
            
        </div>
    )
}

export default PoiData 