import React, { useEffect } from 'react'
import { useState, useRef } from 'react'

import MapSidebar from './sidebar/MapSidebar'
import ReportSidebarContainer from './reportSidebar/ReportSidebarContainer'
import PoiPopup from './singlePointReport/PoiPopup'

import Map from './Map'

import aoiLayers, { isAoiLayerActive } from './layers/aoiLayers'
import { skaermkortDaempet, skaermkortGraa, skaermkortOrtoForaar } from './kortforsyningen'
//import DniLayerControl from './map/DniLayerControl'
import dniLayerDefinitionService from '../service/dniLayerDefinitionService'

import uiConfig from '../ui/uiConfig'
import LayerSelectControl from './externalControls/LayerSelectControl'
import ExternalDrawControl from './externalControls/ExternalDrawControl'
const sidebarCollapsedWidth = 64

const MapWrapper = props => {
    const { showSidebar, onOpenSidebar, onCloseSidebar } = props
    const { heightReduction: top } = props
    const [showPopup, setShowPopup] = useState(false)

    const [selectedAoiLayer, setSelectedAoiLayer] = useState(aoiLayers[0])
    const [selectedBackgroundLayer, setSelectedBackgroundLayer] = useState(skaermkortDaempet)
    //const [showMatrikelLayer, setShowMatrikelLayer] = useState(true)
    const [selectedFeatures, setSelectedFeatures] = useState([])
    const [ dniLayerOpacity, setDniLayerOpacity] = useState(1)
    const [selectedFeaturesTitle, setSelectedFeaturesTitle] = useState('')
    //const [selectedFeaturesData, setSelectedFeaturesData] = useState([])
    const [poi, setPoi] = useState(null)
    const [zoom, setZoom] = useState(1)
    const [selectedLayers, setSelectedLayers] = useState([])
    const [ samletLayers, setSamletLayers ] = useState([])
    const [ tilstandLayers, setTilstandLayers ] = useState([])
    const [ beskyttelseLayers, setBeskyttelseLayers ] = useState([])
    const [ reglerLayers, setReglerLayers ] = useState([])
    const [ procesLayers, setProcesLayers ] = useState([])
    const [ drawControlDisabled, setDrawControlDisabled ] = useState(false)
    const [ drawInteraction, setDrawInteraction ] = useState(false)
    const [ doubleClickZoom, setDoubleClickZoom ] = useState(true)
    const [ mapCursor, setMapCursor] = useState('default')

    const drawControlRef = useRef()
    
    const backgroundLayers = [skaermkortDaempet, skaermkortGraa, skaermkortOrtoForaar]

    const sidebarFullWidth = uiConfig.sidebar.width
    const sidebarPadding = showSidebar ? uiConfig.sidebar.padding : uiConfig.sidebar.collapsed.padding
    const sidebarWidth = showSidebar ? uiConfig.sidebar.width : uiConfig.sidebar.collapsed.width
    const totalSBWidth = sidebarWidth + 1 * sidebarPadding
    const showReportSidebar = showPopup && selectedFeatures.length > 0
    const reportSidebarWidth = showReportSidebar ? uiConfig.report.width : 0

    useEffect(() => {
        dniLayerDefinitionService.fetchLatestVersions()
            .then(() => {
                setSelectedLayers([dniLayerDefinitionService.getSamletLayers()[0]])
                setSamletLayers(dniLayerDefinitionService.getSamletLayers())                
                setTilstandLayers(dniLayerDefinitionService.getTilstandLayers())
                setBeskyttelseLayers(dniLayerDefinitionService.getBeskyttelseLayers())
                setReglerLayers(dniLayerDefinitionService.getReglerLayers())
                setProcesLayers(dniLayerDefinitionService.getProcesLayers())
            })
    }, [])

    const updateSelectedFeatures = (features) => {
        setSelectedFeatures(features)
        if (features.length === 0) {
            setSelectedFeaturesTitle('')
            return
        }
        if (features.length === 1) {
            setSelectedFeaturesTitle(features[0].features[0].attributes.title)
        } else {
            setSelectedFeaturesTitle('Flere valgt')
        }
        /*fetchMatrikelRules(features.reduce((p, c) => p.concat(c.features), []).map(f => f.attributes))
            .then(data => { setLoadingFeatureData(false) ; setSelectedFeaturesData(data); })*/
        
    }

    const updateSelectedAoiLayer = (_selectedAoiLayer, zoom) => {
        if (_selectedAoiLayer === selectedAoiLayer) {
            return
        }
        updateSelectedFeatures([])
        setSelectedAoiLayer(_selectedAoiLayer)
        if (_selectedAoiLayer && _selectedAoiLayer.zoom) {
            const aoiZoom = _selectedAoiLayer.zoom
            if (_selectedAoiLayer.disableDrawControls || aoiZoom.min > zoom || aoiZoom.max < zoom) {
                setDrawControlDisabled(true)
            } else {
                //console.log('enabling draw controls from zoom')
                setDrawControlDisabled(false)
            }
        }
    }

    const heightStyle = `calc(100vh - ${top}px)`

    //console.log('selectedLayers', selectedLayers)
    return (
        <div style={{ width: '100%', paddingTop: top, height: heightStyle, maxHeight: heightStyle, minHeight: heightStyle, overflow: 'auto' }}>
            <MapSidebar 
                top={0}
                heightStyle={heightStyle}
                expanded={showSidebar}
                collapsedWidth={sidebarCollapsedWidth}
                fullWidth={sidebarFullWidth} 
                padding={sidebarPadding}
                mapZoom={zoom}
                onSelectedLayersChanged={layers => setSelectedLayers(layers)}
                onSelectedAoiLayerChanged={layer => updateSelectedAoiLayer(layer, zoom)}
                onSelectedBackgroundLayerChanged={layer => setSelectedBackgroundLayer(layer)}
                selectedLayers={selectedLayers}
                samletLayers={samletLayers}
                beskyttelseLayers={beskyttelseLayers}
                reglerLayers={reglerLayers}
                procesLayers={procesLayers}
                tilstandLayers={tilstandLayers}
                dniLayerOpacity={dniLayerOpacity}
                onDniLayerOpacityChanged={value => setDniLayerOpacity(value)}
                onOpen={() => onOpenSidebar()}
                onClose={() => onCloseSidebar()}
                >
                
            </MapSidebar>
            <Map
                id={props.id}
                wide={!showSidebar}
                left={totalSBWidth}
                right={showReportSidebar ? reportSidebarWidth : 0 }
                top={top}
                bounds={props.bounds}
                extent={props.extent}
                aoiLayers={aoiLayers}
                dniLayerOpacity={dniLayerOpacity}
                selectedLayers={selectedLayers}
                kortforsyningLayers={backgroundLayers}
                selectedAoiLayer={selectedAoiLayer}
                selectedBackgroundLayer={selectedBackgroundLayer}
                selectedFeatures={selectedFeatures}
                poiLocation={poi && poi.coordinate}
                drawControlDisabled={drawControlDisabled}
                doubleClickZoom={doubleClickZoom}
                onMapClicked={(evt, viewResolution, projection) => {
                    if (drawControlRef && drawControlRef.current) {
                        drawControlRef.current.mapClicked(evt, viewResolution, projection)
                    }
                }}
                onFeaturesSelected={(features) => {
                    updateSelectedFeatures(features)
                    const show = (features && features.length > 0)
                    setShowPopup(show)
                }}
                drawInteraction={drawInteraction}
                onZoom={(zoom) => {
                    //console.log('zoom level changed', zoom)
                    setZoom(zoom)
                    updateSelectedAoiLayer(selectedAoiLayer, zoom)
                }}
                cursor={mapCursor}
            >
            </Map>
            <LayerSelectControl 
                position={{ left: totalSBWidth, bottom: 10 }} 
                zoom={zoom}
                groups={[{ id: 'skaermkort', title: 'Skærmkort', layers: backgroundLayers, selectedLayer: selectedBackgroundLayer }, { id: 'arealer', title: 'Arealer', layers: aoiLayers, selectedLayer: selectedAoiLayer} ]}
                onChange={(group, layer) => {
                    if (group === 'skaermkort') {
                        setSelectedBackgroundLayer(layer)
                    } else if (group === 'arealer') {
                        updateSelectedAoiLayer(layer, zoom)
                    }
                }}
                />
            <ExternalDrawControl ref={drawControlRef} 
                position={{ right: showReportSidebar ? reportSidebarWidth + 10 : 10, top: top + 10 }}
                onCreateDrawInteraction={() => setDrawInteraction(true)}
                onDestroyDrawInteraction={() => setDrawInteraction(false)}
                onEnableDoubleClickZoom={() => setDoubleClickZoom(true)}
                onDisableDoubleClickZoom={() => setDoubleClickZoom(false)}
                selectedAoiLayer={selectedAoiLayer}
                selectedFeatures={selectedFeatures}
                aoiLayerActive={isAoiLayerActive(zoom, selectedAoiLayer)}
                setMapCursor={setMapCursor}
                onClearAll={() => {
                    updateSelectedFeatures([])
                    setPoi(null)
                }}
                onQueryCoordinate={(coordinate, viewResolution, projection) => {                    
                    setPoi({ coordinate, viewResolution, projection })
                }}
                onFeatureToggled={feature => {
                    //console.log(selectedFeatures.map(sf => sf.attributes), feature.attributes)
                    const remove = selectedFeatures.find(f => f.id === feature.id)
                   // console.log('remove', remove)
                    let newFeatures = []
                    if (remove) {
                        newFeatures = selectedFeatures.filter(f => f.id !== feature.id)
                    } else {
                        const attributes = feature && feature.features && feature.features[0] && feature.features[0].attributes
                        console.log('selecting: ', attributes)
                        if (attributes.isLarge) {
                            newFeatures = [ feature ]
                        } else {
                            newFeatures = selectedFeatures.concat([feature])
                        }
                    }
                    //console.log(newFeatures)
                    updateSelectedFeatures(newFeatures)
                    setShowPopup(true)
                }
                } />
            <ReportSidebarContainer 
                title={selectedFeaturesTitle} 
                fullWidth={reportSidebarWidth} 
                expanded={showReportSidebar} 
                top={top} 
                selectedFeatureType={selectedAoiLayer.featureType}
                selectedFeatures={selectedFeatures} 
                onClose={() => updateSelectedFeatures([])}
                />
            <PoiPopup show={Boolean(poi)} point={poi} onClose={() => setPoi(null)} />
        </div>
    )
}

export default MapWrapper