import {
    score0100GreenColors,
    score0100RedColors,
    hede,
    overdrev,
    soe,
    eng,
    andet,
    mark,
    graesningColors,
    booleanColors
} from './layerColors'
import layerTypes from './layerTypes'
import {
    samletProcessHelp,
    hydrologiHelp,
    hydrologiWeightHelp,
    grasningHelp,
    grasningDensitetHelp,
    sandHelp,
    sandWeightHelp,
    sandBarriererHelp,
    sandKystsikringHelp,
    floodingScoreHelp,
    floodingWeightHelp,
    floodingKystsikringHelp,
    grasningWeightHelp,
    hydrologiGroeftetalHelp,
    hydrologiSlyngningHelp,
    hydrologiPlantetalHelp,
    hydrologiNaturandelHelp,
    hydrologiArealtyperHelp
} from '../../map/layers/help/helpProcesser'
import {
    naturandelMapper,
    numberMapper,
    intEnumMapper,
    booleanMapper
} from './dataMappers'

const processLegend = [
    { title: '0', color: score0100GreenColors[0] },
    { title: '12', color: score0100GreenColors[1] },
    { title: '25', color: score0100GreenColors[2] },
    { title: '37', color: score0100GreenColors[3] },
    { title: '50', color: score0100GreenColors[4] },
    { title: '62', color: score0100GreenColors[5] },
    { title: '75', color: score0100GreenColors[6] },
    { title: '87', color: score0100GreenColors[7] },
    { title: '100', color: score0100GreenColors[8] }
]

export const samletProcessScoreLayer = {
    title: 'Processer',
    type: layerTypes.layer,
    id: 'proces',
    awsTiles: false,
    legend: processLegend,
    help: samletProcessHelp,
    dataMapper: numberMapper
}

const layers = [
    samletProcessScoreLayer,
    {
        title: 'Sandflugt',
        type: layerTypes.layer,
        id: 'sandScore',
        legend: processLegend,
        help: sandHelp,
        dataMapper: numberMapper
    },
    {
        title: 'Sandflugt (vægt)',
        type: layerTypes.layer,
        id: 'sandWeight',
        legend: processLegend,
        help: sandWeightHelp,
        dataMapper: numberMapper
    }, {
        title: 'Sandflugt - Kystsikring',
        type: layerTypes.layer,
        id: 'k01_sandflugt_kystsikring',
        legend: [
            { title: 'Kystsikring', color: booleanColors[0] }
        ],
        help: sandKystsikringHelp,
        dataMapper: booleanMapper
    },
    {
        title: 'Sandflugt - Barrierer',
        type: layerTypes.layer,
        id: 'k02_sandflugt_barrierer',
        legend: [
            { title: 'Bebyggelse', color: booleanColors[0] },
            { title: 'Landbrug', color: booleanColors[1] },
            { title: 'Trædække', color: booleanColors[2] }
        ],
        help: sandBarriererHelp,
        dataMapper: intEnumMapper([
            { value: 10, label: "Bebyggelse" },
            { value: 20, label: "Landbrug" },
            { value: 30, label: "Trædække" }
        ])
    },
    {
        title: 'Oversvømmelse fra havet',
        type: layerTypes.layer,
        id: 'floodingScore',
        legend: processLegend,
        help: floodingScoreHelp,
        dataMapper: numberMapper
    },
    {
        title: 'Oversvømmelse fra havet (vægt)',
        type: layerTypes.layer,
        id: 'floodingWeight',
        legend: processLegend,
        help: floodingWeightHelp,
        dataMapper: numberMapper
    },
    {
        title: 'Oversvømmelse - Kystsikring',
        type: layerTypes.layer,
        id: 'k03_oversvoemmelse_kystsikring',
        legend: [
            { title: 'Kystsikring', color: booleanColors[0] }
        ],
        help: floodingKystsikringHelp,
        dataMapper: booleanMapper
    },
    {
        title: 'Græsning',
        type: layerTypes.layer,
        id: 'graesningScore',
        legend: processLegend,
        help: grasningHelp,
        dataMapper: numberMapper
    },
    {
        title: 'Græsning (vægt)',
        type: layerTypes.layer,
        id: 'graesningWeight',
        legend: processLegend,
        help: grasningWeightHelp,
        dataMapper: numberMapper
    }, {
        title: 'Græsning densitet',
        type: layerTypes.layer,
        id: 'g01_graesningDensitet',
        legend: [
            { title: '<5 kg/ha', color: graesningColors[0] },
            { title: '5 - 15 kg/ha', color: graesningColors[1] },
            { title: '15 - 50 kg/ha', color: graesningColors[2] },
            { title: '50 - 100 kg/ha', color: graesningColors[3] },
            { title: '100 - 200 kg/ha', color: graesningColors[4] },
            { title: '200 - 300 kg/ha', color: graesningColors[5] },
            { title: '300 - 400 kg/ha', color: graesningColors[6] },
            { title: '>400 kg/ha', color: graesningColors[7] }
        ],
        help: grasningDensitetHelp,
        dataMapper: intEnumMapper([
            { label: '<5 kg/ha', value: 1 },
            { label: '5 - 15 kg/ha', value: 2 },
            { label: '15 - 50 kg/ha', value: 3 },
            { label: '50 - 100 kg/ha', value: 4 },
            { label: '100 - 200 kg/ha', value: 5 },
            { label: '200 - 300 kg/ha', value: 6 },
            { label: '300 - 400 kg/ha', value: 7 },
            { label: '>400 kg/ha', value: 8 }
        ])
    },
    {
        title: 'Hydrologi',
        type: layerTypes.layer,
        id: 'hydroScore',
        legend: processLegend,
        help: hydrologiHelp,
        dataMapper: numberMapper
    }, {
        title: 'Hydrologi (vægt)',
        type: layerTypes.layer,
        id: 'hydroWeight',
        legend: processLegend,
        help: hydrologiWeightHelp,
        dataMapper: numberMapper
    }, {
        title: 'Hydrologi - Arealtyper',
        type: layerTypes.layer,
        id: 'hydroArealtyper',
        legend: [
            { title: 'Mose, vandløb, sø', color: soe },
            { title: 'Hede', color: hede },
            { title: 'Eng, strandeng, skov', color: eng },
            { title: 'Overdrev', color: overdrev },
            { title: 'Mark, bebyggelse', color: mark },
            { title: 'Andre arealer', color: andet },
        ],
        help: hydrologiArealtyperHelp,
        dataMapper: intEnumMapper(
            [
                { value: 7, label: "Andre arealer" },
                { value: 8, label: "Mark, bebyggelse" },
                { value: 9, label: "Mark, bebyggelse" },
                { value: 10, label: "overdrev" },
                { value: 11, label: "Eng, strandeng, skov" },
                { value: 12, label: "Eng, strandeng, skov" },
                { value: 13, label: "Eng, strandeng, skov" },
                { value: 14, label: "Hede" },
                { value: 15, label: "Mose, vandløb, Sø" },
                { value: 18, label: "Mose, vandløb, Sø" },
                { value: 19, label: "Mose, vandløb, Sø" },
                { value: 20, label: "Mose, vandløb, Sø" },
                { value: 21, label: "Mose, vandløb, Sø" }
            ]
        )
    }, {
        title: 'Hydrologi - Naturandel',
        type: layerTypes.layer,
        id: 'natAndelScore',
        legend: [
            { title: '<20%', color: score0100GreenColors[0] },
            { title: '20-40%', color: score0100GreenColors[2] },
            { title: '40-60%', color: score0100GreenColors[4] },
            { title: '60-80%', color: score0100GreenColors[6] },
            { title: '>80%', color: score0100GreenColors[8] }
        ],
        help: hydrologiNaturandelHelp,
        dataMapper: naturandelMapper
    }, {
        title: 'Hydrologi - Plantetal',
        type: layerTypes.layer,
        id: 'plTalScore',
        legend: [
            { title: 'Lavt', color: score0100GreenColors[4] },
            { title: 'Moderat', color: score0100GreenColors[6] },
            { title: 'Høj', color: score0100GreenColors[8] }
        ],
        help: hydrologiPlantetalHelp,
        dataMapper: intEnumMapper([
            { value: -10, label: "Lavt" },
            { value: 10, label: 'Moderat' },
            { value: 20, label: 'Højt' },
            { value: 246, label: "Lavt" }
        ])
    }, {
        title: 'Hydrologi - Slyngning ådal',
        type: layerTypes.layer,
        id: 'slyngningAadal',
        legend: [
            { title: 'Ikke slynget', color: score0100RedColors[8] },
            { title: 'Slynget', color: score0100GreenColors[7] }
        ],
        help: hydrologiSlyngningHelp,
        dataMapper: intEnumMapper([
            { value: -10, label: 'Ikke slynget' },
            { value: 10, label: 'Slynget' },
            { value: 246, label: 'Ikke slynget' }
        ])
    }, {
        title: 'Hydrologi - Grøftetæthed',
        type: layerTypes.layer,
        id: 'groeftScore',
        legend:  [
            { title: 'Høj', color: score0100GreenColors[4] },
            { title: 'Moderat', color: score0100GreenColors[6] },
            { title: 'Lav', color: score0100GreenColors[8] }
        ],
        help: hydrologiGroeftetalHelp,
        dataMapper: intEnumMapper([
            { value: -30, label: 'Høj' },
            { value: -10, label: 'Moderat' },
            { value: 0, label: 'Lav' },
            { value: 226, label: 'Høj' },
            { value: 246, label: 'Moderat' }
        ])
    }
]


export default layers