import React, {  useState } from 'react'

import DniLayerControl from './DniLayerControl'


// Menu Start
import {
    MenuList,
    MenuItem,
    ListItemIcon,
    Tooltip,
    Slider,
    Typography
} from '@material-ui/core'

import IconButton from '@material-ui/core/IconButton';
//import MenuIcon from '@material-ui/icons/Menu';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';

//import BlankIcon from '../icons/BlankIcon'
import DniIcon from '../icons/SamletIcon'
import VeterantraeIcon from '../icons/VeterantraeIcon'
import ParagrafIcon from '../icons/ParagrafIcon'
import BisonIcon from '../icons/BisonIcon'
import TrusselIcon from '@material-ui/icons/Gavel';
import uiConfig from '../../ui/uiConfig'

import { reglerVejledendeBeskrivelse } from '../../map/layers/help/helpBeskyttelse'

const menuItemStyle = { height: 48 } //, borderBottomColor: '#ccc', borderBottomWidth: 1, borderBottomStyle: 'solid' 
const menuItemStyleSelected = { height: 48, marginRight: 0, borderRightColor: uiConfig.colors.secondary.main, borderRightWidth: 3, borderRightStyle: 'solid' }

const getColor = active => active ? 'secondary' : 'secondary'

// Menu End


const SidebarMenu = props => {
    const { showToggle, width, setCurrentMenu, showSidebar, currentMenu, menus } = props
    const menuStyle = {
        position: 'fixed',
        backgroundColor: uiConfig.colors.secondary.light,
        paddingTop: 8,
        display: 'inline-block', height: '100vh', overflow: 'hidden', width, marginTop: 0, marginRight: 0, marginLeft: 0, float: 'left'
    }

    return (
        <div className={'MuiPaper-elevation6'} style={menuStyle}>
            { showToggle ?
                <div style={{ marginLeft: 20 }}>
                    <IconButton onClick={() => showSidebar()} edge="start" color="inherit" aria-label="menu">
                        <ChevronRight />
                    </IconButton>
                </div>
                :
                <div style={{ marginLeft: 16, height: 48 }}>
                </div>

            }
            <MenuList>
                {menus.map(menu => (
                    <Tooltip key={menu.id} title={menu.label}>
                        <MenuItem
                            style={currentMenu === menu.id ? menuItemStyleSelected : menuItemStyle}
                            selected={currentMenu === menu.id}
                            onClick={() => setCurrentMenu(menu.id)}>
                            <ListItemIcon>
                                {menu.icon(currentMenu === menu.id)}
                            </ListItemIcon>
                        </MenuItem>
                    </Tooltip>
                ))}
            </MenuList>
        </div>
    )
}

const SidebarContent = props => {
    const { onDniLayerOpacityChanged, onClose, onSelectedLayersChanged } = props
    const { transitionStyle, heightStyle, contentTopMargin, contentWidth } = props
    const { selectedLayers, dniLayerOpacity, currentMenu, menus } = props

    const contentStyle = {
        marginLeft: 64,
        paddingTop: 8,
        transition: transitionStyle,
        overflow: 'auto',
        maxHeight: heightStyle,
        marginTop: contentTopMargin,
        display: 'inline-block',
        width: contentWidth,
        backgroundColor: uiConfig.colors.gray.light
    }

    return (
        <div style={contentStyle}>
            <div style={{ marginRight: 16, float: 'right' }}>
                <IconButton onClick={() => onClose()} edge="start" color="inherit" aria-label="menu">
                    <ChevronLeft />
                </IconButton>
            </div>
            {menus.filter(menu => menu.id === currentMenu).map(menu => (
                <DniLayerControl
                    key={'content_' + menu.id}
                    title={menu.label}
                    helpEnabled={true}
                    selectedLayers={selectedLayers}
                    layers={menu.layers}
                    description={menu.description}
                    onSelectedLayersChanged={layers => {
                        onSelectedLayersChanged(layers)
                    }} />
            ))}
            <div style={{ margin: 32 }}>
                <Typography variant='caption'>Gennemsigtighed</Typography>
                <Slider min={0} max={100} step={5} onChange={(evt, value) => { onDniLayerOpacityChanged(value / 100) }} value={dniLayerOpacity * 100} />
            </div>
        </div>)
}

const MapSidebar = props => {
    const { onClose, onOpen } = props
    const { onDniLayerOpacityChanged, dniLayerOpacity } = props
    const { heightStyle, expanded, collapsedWidth, fullWidth, top, padding, onSelectedLayersChanged, } = props
    const { samletLayers, tilstandLayers, beskyttelseLayers, reglerLayers, procesLayers } = props
    

    const menus = [
        { id: 'samlet', label: 'Samlet', value: 'samlet', icon: isSelected => (<DniIcon color={getColor(isSelected)} />), layers: samletLayers },
        { id: 'tilstand', label: 'Tilstand', value: 'tilstand', icon: isSelected => (<VeterantraeIcon color={getColor(isSelected)} />), layers: tilstandLayers },
        { id: 'beskyttelse', label: 'Beskyttelse', value: 'beskyttelse', icon: isSelected => (<ParagrafIcon color={getColor(isSelected)} />), layers: beskyttelseLayers },
        { id: 'processer', label: 'Processer', value: 'processer', icon: isSelected => (<BisonIcon color={getColor(isSelected)} />), layers: procesLayers },
        { id: 'regler', description: () => reglerVejledendeBeskrivelse(), label: 'Kortlægning af regler', value: 'regler', icon: isSelected => (<TrusselIcon color={getColor(isSelected)} style={{ width: 30, height: 40 }} />), layers: reglerLayers }
    ]
    const { selectedLayers } = props

    const width = expanded ? fullWidth : collapsedWidth
    // Menu Stuff
    const [currentMenu, setCurrentMenu] = useState('samlet')
    // End Menu stuff

    const contentTopMargin = 0
    //const heightStyle = `calc(100vh - ${contentTopMargin}px - ${top}px)`
    const menuWidth = expanded ? 64 : width
    const contentWidth = expanded ? fullWidth - 1 - menuWidth + padding : 0

    const menuMarginLeft = expanded ? -16 : 0
    const transitionStyle = 'all ' + uiConfig.sidebar.animationSpeed + 's'
    //const innerheight = 'calc(100vh - ' + heightReduction + 'px - ' + (48 + padding * 2) + 'px)'
    const containerStyle = {
        display: 'block',
        backgroundColor: uiConfig.colors.gray.light,
        transition: transitionStyle,
        maxHeight: heightStyle, height: heightStyle,
        width, marginTop: top + contentTopMargin,
        paddingLeft: padding
    }
    return (
        <div style={containerStyle}>
            <div style={{ transition: transitionStyle, marginLeft: menuMarginLeft }}>
                <SidebarMenu
                    showToggle={!expanded}
                    width={menuWidth}
                    menus={menus}
                    currentMenu={currentMenu}
                    showSidebar={() => onOpen()}
                    hideSidebar={() => onClose()}
                    setCurrentMenu={menu => { setCurrentMenu(menu); onOpen(); }} />
                <SidebarContent
                    menus={menus}
                    currentMenu={currentMenu}
                    onClose={onClose}
                    selectedLayers={selectedLayers}
                    onSelectedLayersChanged={onSelectedLayersChanged}
                    contentWidth={contentWidth}
                    transitionStyle={transitionStyle}
                    contentTopMargin={contentTopMargin}
                    onDniLayerOpacityChanged={onDniLayerOpacityChanged}
                    dniLayerOpacity={dniLayerOpacity}
                />
            </div>
        </div>
    )
}

//            

export default MapSidebar