import Control from 'ol/control/Control';
import './BoxShadowControl.css'

const boxclass = 'box-shadow-control'
const elevationclass = 'MuiPaper-elevation4'

export default class BoxShadowControl extends Control {
    constructor(opt_options) {
        const options = opt_options || {};

        const containerElement = document.createElement('div');
        containerElement.className = [ boxclass, elevationclass ].join(' ')
        super({
            element: containerElement,
            target: options.target
        });
        
        this.containerElement = containerElement        
    }

    /**
     * Set the map instance the control is associated with.
     * @param {ol/Map~Map} map The map instance.
     */
    setMap(map) {
        super.setMap(map);
    }
}