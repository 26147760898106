import { get } from "./fetchUtil"
import vectorLayerService from "./vectorLayerService"

const splitFeature = (feature, idProperty, attributes) => {
    return feature.geometry.rings.map(r => {
        const f = {
            type: 'Feature',
            attributes,
            geometry: {
                type: 'Polygon',
                coordinates: [r]
            }
        }
        return f
    })
}

const toGeoJson = (data, idProperty, titleFn, prefix) => {
    data.type = "FeatureCollection"
    const features = []
    data.features.forEach(f => {
        if (!f.attributes) {
            return
        }
        const attributes = f.attributes
        attributes.isLarge = true
        attributes.mId = f.attributes[idProperty] + ''
        attributes.title = titleFn(f.attributes)
        Array.prototype.push.apply(features, splitFeature(f, idProperty, attributes));
    })
    //console.log(features)
    data.features = features
    return data
}

class KommuneService {
    constructor() {
        this.baseurl = vectorLayerService.getBaseUrl()
        this.kommuneUrl = 'kommuner'
        this.kommuneVersion = null
        this.nationalparkUrl = 'nationalparker'
        this.nationalparkVersion = null
        this.fuglebeskytteseUrl = 'fuglebeskyttelsesomraader'
        this.fuglebeskyttelseVersion = null
        this.habitatomraaderUrl = 'habitatomraader'
        this.habitatomraaderVersion = null
        this.init()
    }

    init = async () => {
        const data = await vectorLayerService.fetchLayerVersions()
        this.kommuneVersion = data.kommune.version
        this.nationalparkVersion = data.nationalparker.version
        this.fuglebeskyttelseVersion = data.fuglebeskyttelse.version
        this.habitatomraaderVersion = data.habitatomraader.version
    }

    fetchKommuner = async () => {
        try {
            console.log("Fetch kommuner")
            if (!this.kommuneVersion) {
                await this.init()
            }
            const data = await get(this.baseurl, this.kommuneUrl + '/kommuner_' + this.kommuneVersion + '.json')            
            //console.log()
            return toGeoJson(data, 'KOMKODE', attributes => attributes.KOMNAVN + ' kommune', true)
        } catch (error) {
            console.log(error)
            return null
        }
    }
    fetchNationalparker = async () => {
        try {
            if (!this.nationalparkVersion) {
                await this.init()
            }
            const data = await get(this.baseurl, this.nationalparkUrl + '/nationalparker_' + this.nationalparkVersion + '.json')            
            return toGeoJson(data, 'key', attributes => `Nationalpark ${attributes.navn}`)
        } catch (error) {
            console.log(error)
            return null
        }
    }
    fetchHabitatomraader = async () => {
        try {
            if (!this.habitatomraaderVersion) {
                await this.init()
            }
            const data = await get(this.baseurl, this.habitatomraaderUrl + '/habitatomraader_' + this.habitatomraaderVersion + '.json')            
            return toGeoJson(data, 'SITE_NR', attributes => attributes.OBJEKTNAVN)
        } catch (error) {
            console.log(error)
            return null
        }
    }
    fetchFuglebeskyttelsesomraader = async () => {
        try {
            if (!this.fuglebeskyttelseVersion) {
                await this.init()
            }
            const data = await get(this.baseurl, this.fuglebeskytteseUrl + '/fuglebeskyttelsesomraader_' + this.fuglebeskyttelseVersion + '.json')
            return toGeoJson(data, 'SITE_NR', attributes => attributes.OBJEKTNAVN)
        } catch (error) {
            console.log(error)
            return null
        }
    }
}

export default new KommuneService()