import React from 'react'
import { Typography } from '@material-ui/core'
//import D3NkiChart from '../../report/D3NkiChart'
import { objectMap, sum } from '../../service/utils'
import uiConfig from '../../ui/uiConfig'
import WeightedBarChart from '../../chartjs3/react/WeightedBarChart'
import { hexToRgba } from '../../service/colorUtils'

/*
const DniMedianRadar = props => {
    const { data } = props
   // const dniMedian = median(data.dni, data.area)
    const tilstandMedian = median(data.t, data.area)
    const beskyttelseMedian = median(data.b, data.area)
    const processMedian = median(data.p, data.area)
    return (<div>
        <RadarChart title='' data={[ 
            { label: 'Tilstand', value: tilstandMedian }, 
            { label: 'Beskyttelse', value: beskyttelseMedian},
            { label: 'Process', value: processMedian} ]} />
    </div>)
}*/

const round = value => {
    if (value < 5) {
        return Math.round(value * 10) / 10
    } else {
        return Math.round(value)
    }
}

const DniReport = props => {
    const { title, data } = props
    if (!data) {
        return null
    }
    console.log(data)
    const _data = objectMap(data, (k, d) => ({a: d.area, s: (d.sum / d.area), sum: d.sum, label: d.naturtype.label }))
    const colors = objectMap(data, (k, d) => d.naturtype.color)
    const totalArea = sum(_data, d => d.a)
    const totalSum = sum(_data, d => d.sum)
    const dniAverage = round(totalSum / totalArea)
    

    const barData = _data.map(d => d.s)
    const barLabels = _data.map(d => d.label)
    const barWeights = _data.map(d => d.a)


    //console.log(title, barLabels, barData, barWeights )

    return (
        <div>
            <Typography variant='h6'>{title}: {dniAverage} </Typography>
            <WeightedBarChart      
                key={barLabels.join('_')} 
                tooltipLabelFn={tooltipItem => ` ${title}: ${~~tooltipItem.dataset.data[tooltipItem.dataIndex]}`}
                tooltipAfterLabelFn={tooltipItem => ` Arealandel: ${~~(0.5 + tooltipItem.chart.data.weightsPct[tooltipItem.dataIndex] * 1000) / 10} %`}
                legendEnabled={props.legend}
                legendPosition={props.legendPosition}     
                labels={barLabels}
                data={barData}
                weights={barWeights}
                backgroundColors={colors.map(h => hexToRgba(h, 0.2))}
                borderColors={colors}
                width={uiConfig.report.width - 40}
                height={210} />
        </div>
    )
}

/* 
<D3NkiChart
data={_data}
width={uiConfig.report.width - 40}
height={240}
colors={colors}
/>
*/
export default React.memo(DniReport)