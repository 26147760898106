import beskyttelselayers from './data/beskyttelsesLayers'
import reglerLayers from './data/reglerLayers'
import processLayers from './data/processLayers'
import tilstandLayers from './data/tilstandLayers'
import samletLayers, { dniScore } from './data/samletLayers'
import { get } from './fetchUtil'

class DniLayerDefinitionService {
    constructor() {        
        this.baseUrl = "https://features.dni.bioskaloe.net/layers/"
        /* global DNI_CONFIG */
        this.versionFile = `${DNI_CONFIG.environment}.json`
        this.versions = null
    }
    
    async fetchLatestVersions() {
        if (this.versions) {
            return
        }
        const versiondata = await get(this.baseUrl, this.versionFile)        
        this.versions = {}
        versiondata.forEach(row => {
            this.versions[row.id] = row.version            
        })
    }

    getNaturindikator() {
        let version = this.versions['dni']
        return { ...dniScore, wmtsId: `dni:${dniScore.id}_${version}`}
    }

    enrichLayer(layer) {
        if (layer.version) {
            return { ...layer, wmtsId: `dni:${layer.id}_${layer.version}`}
        }
        if (this.versions === null) {
            return layer
        }
        const id = layer.id
        const version = this.versions[id]
        if (!version) {
            return null
        }
        return { ...layer, wmtsId: `dni:${id}_${version}`, version: version}
    }

    enrichLayers(layers) {
        const res = []
        layers.forEach(l => {
            const tmp = this.enrichLayer(l)
            if (tmp) {
                res.push(tmp)
            }
        })
        return res
    }

    getReglerLayers() {
        return this.enrichLayers(reglerLayers)
    }

    getSamletLayers() {
        return this.enrichLayers(samletLayers)
    }

    getTilstandLayers() {
        return this.enrichLayers(tilstandLayers)
    }

    getBeskyttelseLayers() {
        return this.enrichLayers(beskyttelselayers)
    }

    getProcesLayers() {
        return this.enrichLayers(processLayers)
    }
}

export default new DniLayerDefinitionService()