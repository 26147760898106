import { LinearScale } from 'chart.js';

class LinearLabelScale extends LinearScale {
    getLabelForValue(value) {
        const me = this;
        const labels = me.getLabels();
        if (value !== null) {
            return labels[value]
        }
        return '';
    }
}

LinearLabelScale.id = 'linearlabel'
LinearLabelScale.defaults = LinearScale.defaults

export default LinearLabelScale