import TileLayer from 'ol/layer/Tile';
import WMTSTileGrid from 'ol/tilegrid/WMTS';
import WMTS from 'ol/source/WMTS';

const extent= [120000, 5661139.2, 1378291.2, 6500000];

const attributionText = '&copy; <a target="_blank" href="https://download.kortforsyningen.dk/content/vilk%C3%A5r-og-betingelser">Styrelsen for Dataforsyning og Effektivisering</a>';

const ortoTileGrid = new WMTSTileGrid({
    extent: [120000, 5661139.2, 1378291.2, 6500000],
    resolutions: [1638.4, 819.2, 409.6, 204.8, 102.4, 51.2, 25.6, 12.8, 6.4, 3.2, 1.6, 0.8, 0.4, 0.2],
    matrixIds: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13']
});

const dfTileGrid = new WMTSTileGrid({
	extent: extent,
	resolutions: [1638.4, 819.2, 409.6, 204.8, 102.4, 51.2, 25.6, 12.8, 6.4, 3.2, 1.6, 0.8, 0.4, 0.2, 0.1, 0.05],
	matrixIds: ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15']
})


const username = "IIXOVQWAAR"
const password = encodeURIComponent("NcYuYWVg*KQ5j9tcE")

export const skaermkortOrtoForaar = {
    title: 'Orto forår',
    layer: 'orto_foraar_wmts',
    tileMatrixSet: 'KortforsyningTilingDK',
    tileGrid: ortoTileGrid,
    getUrl: () => `https://services.datafordeler.dk/GeoDanmarkOrto/orto_foraar_wmts/1.0.0/WMTS?username=${username}&password=${password}`
}

export const skaermkortDaempet = {
    title: 'Skærmkort dæmpet',
    layer: 'topo_skaermkort_daempet',
    tileGrid: dfTileGrid,
    getUrl: () => `https://services.datafordeler.dk/Dkskaermkort/topo_skaermkort_daempet/1.0.0/wmts?username=${username}&password=${password}`
}

export const skaermkortGraa = {
    title: 'Skærmkort grå',
    layer: 'topo_skaermkort_graa',
    tileGrid: dfTileGrid,
    getUrl: () => `https://services.datafordeler.dk/Dkskaermkort/topo_skaermkort_graa/1.0.0/wmts?username=${username}&password=${password}`
}

const kortforsyningen = (skaermkort, title, token, visible) => {
    const tilelayer = new TileLayer({
        opacity: 1.0,
        title,
        type: 'base',
        visible,
        source: new WMTS({
            attributions: attributionText,
            url: skaermkort.getUrl(token),
            layer: skaermkort.layer,
            matrixSet: skaermkort.tileMatrixSet || "View1",
            format: "image/jpeg",
            tileGrid: skaermkort.tileGrid,
            style: 'default',
            size: [256, 256]
        })
    })
    return tilelayer
}

export default kortforsyningen