import {
    score0100PurpleColors 
} from './layerColors'
import layerTypes from './layerTypes'
import { dniScoreHelp } from  '../../map/layers/help/helpSamlet'
import { bioscore } from './tilstandLayers'
import { samletBeskyttelsesLayer } from './beskyttelsesLayers'
import { samletProcessScoreLayer } from './processLayers'
import {
    numberMapper
} from './dataMappers'

export const dniScore = {
    title: 'DNI',
    awsTiles: false,
    type: layerTypes.layer,                     
    id: 'dni',
    legend: [
        { title: '0', color: score0100PurpleColors[0] },
        { title: '12', color: score0100PurpleColors[1] },
        { title: '25', color: score0100PurpleColors[2] },
        { title: '37', color: score0100PurpleColors[3] },
        { title: '50', color: score0100PurpleColors[4] },
        { title: '62', color: score0100PurpleColors[5] },
        { title: '75', color: score0100PurpleColors[6] },
        { title: '87', color: score0100PurpleColors[7] },
        { title: '100', color: score0100PurpleColors[8] }          
    ],
    help: dniScoreHelp,
    dataMapper: numberMapper
}

const layers = [
    dniScore,
    bioscore,
    samletBeskyttelsesLayer,
    samletProcessScoreLayer
]

export default layers