export const hexToRgb = (hex) => {
    const _hex = hex.startsWith('#') ? hex.substring(1) : hex
    var bigint = parseInt(_hex, 16);
    var r = (bigint >> 16) & 255;
    var g = (bigint >> 8) & 255;
    var b = bigint & 255;
  
    return { r, g, b}
  }
  
  export const hexToRgba = (hex, alpha) => {
    const rgb = hexToRgb(hex)
    return 'rgba(' + rgb.r + ', ' + rgb.g + ', ' + rgb.b + ', ' + alpha + ')'
  }