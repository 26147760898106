//import matrikelRulesData from './matrikelRulesData'

import { get } from "./fetchUtil"
import vectorLayerService from "./vectorLayerService"

//const baseurl = 'https://dni-public-data-test.s3-eu-west-1.amazonaws.com/epsg_25832/features/dk'

//const regelUrl = 'https://features.dni.bioskaloe.net/epsg25832/dk/matrikel_regler'

const initFeatureCollection = () => ({
    type: 'FeatureCollection',
    features: []
})

const toGeoJson = data => {
    data.type = "FeatureCollection"
    const features = data.features.map(f => {
        f.type = 'Feature'
        f.geometry.type = 'Polygon'
        f.geometry.coordinates = f.geometry.rings
        f.geometry.rings = undefined
        f.attributes.title = 'Matrikel'
        return f
    })
    data.features = features
    return data
}

const appendFeatures = (collection, features) => {
    const newFeatures = features.map(f => {
        f.type = 'Feature'
        f.geometry.type = 'Polygon'
        f.geometry.coordinates = f.geometry.rings
        f.geometry.rings = undefined
        return f
    })
    collection.features = collection.features.concat(newFeatures)
}


class MatrikelService {
    constructor() {
        /* global DNI_CONFIG */
        this.baseUrl = DNI_CONFIG.featuresUrl + "vector/"
        this.matrikelUrl = 'matrikel'
        this.versionUrl = null
    }

    fetchBox = async (box) => {
        try {
            if (!this.versionUrl) {
                const layerVersions = await vectorLayerService.fetchLayerVersions()
                this.versionUrl = layerVersions.matrikel.version
            }
            const data = await get(this.baseUrl, `${this.matrikelUrl}/${this.versionUrl}/${box}`)
            //const data = await response.json()
            //console.log()
            return toGeoJson(data)
        } catch (error) {
            console.log(error)
            return null
        }
    }

    fetchFeatures = async (bbox) => {
        if (!this.versionUrl) {
            const layerVersions = await vectorLayerService.fetchLayerVersions()
            this.versionUrl = layerVersions.matrikel.version
        }
        //console.log(bbox)
        const minX = Math.floor(bbox[0] / 1000)
        const minY = Math.floor(bbox[1] / 1000)
        const maxX = Math.ceil(bbox[2] / 1000)
        const maxY = Math.ceil(bbox[3] / 1000)
        let x = minX - (minX % 5)
        let boxes = []
        while (x < maxX) {
            let y = minY - (minY % 5)
            //console.log(x, y, maxX, maxY)
            while (y < maxY) {
                boxes.push('dk_boxid_' + x + y + '.json')
                y = y + 5
            }
            x = x + 5
        }
        const featureCollection = initFeatureCollection()
        //console.log(boxes)
        for (var i = 0; i < boxes.length; i++) {
            const box = boxes[i]
            //console.log(box)
            try {
                const response = await fetch(this.baseUrl + "/" + this.matrikelUrl + "/" + this.versionUrl + '/' + box)
                const data = await response.json()
                //console.log()
                appendFeatures(featureCollection, data.features)
            } catch (error) {
                //ignore
            }
        }
        return featureCollection
    }

    updateFeatures = async (bbox, vectorSourceHandler) => {
        if (!this.versionUrl) {
            const layerVersions = await vectorLayerService.fetchLayerVersions()
            this.versionUrl = layerVersions.matrikel.version
        }
        console.log(bbox)
        const minX = Math.floor(bbox[0] / 1000)
        const minY = Math.floor(bbox[1] / 1000)
        const maxX = Math.ceil(bbox[2] / 1000)
        const maxY = Math.ceil(bbox[3] / 1000)
        let x = minX - (minX % 5)
        let boxes = []
        while (x < maxX) {
            let y = minY - (minY % 5)
            //console.log(x, y, maxX, maxY)
            while (y < maxY) {
                boxes.push('dk_boxid_' + x + y + '.json')
                y = y + 5
            }
            x = x + 5
        }
        let prevBoxes = vectorSourceHandler.getBoxes()
        let newBoxes = boxes.filter(nb => !Boolean(prevBoxes.find(pb => pb === nb)))
        console.log(boxes, prevBoxes, newBoxes)
        if (newBoxes.length === 0) {
            return {
                boxes
            }
        }
        vectorSourceHandler.removeAllFeatures()
        for (var i = 0; i < boxes.length; i++) {
            const box = boxes[i]
            try {
                const response = await fetch(this.baseUrl + "/" + this.matrikelUrl + "/" + this.versionUrl + '/' + box)
                const json = await response.json()
                let featureCollection = toGeoJson(json)
                //console.log(featureCollection)
                if (featureCollection) {
                    vectorSourceHandler.addFeatures(featureCollection)
                }
            } catch (error) {
                //ignore
            }
        }
        vectorSourceHandler.setBoxes(boxes)
        return {
            boxes
        }
    }

    calcBoxes = (bbox) => {
        const minX = Math.floor(bbox[0] / 1000)
        const minY = Math.floor(bbox[1] / 1000)
        const maxX = Math.ceil(bbox[2] / 1000)
        const maxY = Math.ceil(bbox[3] / 1000)
        let x = minX - (minX % 5)
        let boxes = []
        while (x < maxX) {
            let y = minY - (minY % 5)
            //console.log(x, y, maxX, maxY)
            while (y < maxY) {
                boxes.push('dk_boxid_' + x + y + '.json')
                y = y + 5
            }
            x = x + 5
        }
        return boxes
    }
}




export default new MatrikelService()