import React from 'react'
import { Link, Paper, Card, CardContent, Typography } from '@material-ui/core'
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
export const InfoBox = props => {
    const { version } = props
    return (
        <div style={{ backgroundColor: '#e8e8e8', padding: 8, paddingTop: 8, paddingBottom: 8, borderRadius: 4}}>
            {props.children}
            { version && <HelpInfo label={'Version'} value={version} />}
        </div>
    )
}

export const HelpInfo = props => {
    const { label, value, link, href} = props
    return (
        <div>
            <Typography style={{ lineHeight: 1}} variant='caption'>{label}</Typography>
            <Typography variant='body1'>{link ? 
                <Link href={href || 'https://' + value} target="_blank" rel="noopener" underline='always'>{value}</Link>
                : value
            }
            </Typography>
        </div>
    )
}

export const HelpSection = props => {
    return (
        <div style={{ marginTop: 8, marginBottom: 8}}>
            {props.title ? <Typography variant='subtitle1'>{props.title}</Typography> : null}
            {props.children}
        </div>
    )
}

export const HelpParagraph = props => {
    return (
        <React.Fragment>
            {props.title ? <Typography variant='subtitle1'>{props.title}</Typography> : null}
            <Typography style={{ marginBottom: 8 }} variant='body1'>{props.children}</Typography>
        </React.Fragment>
    )
}

export const HelpLink = props => {
    return (
        <Link href={props.href} target="_blank" rel="noopener" underline="always">{props.value}
            <span style={{ fontSize: 10 }}><OpenInNewIcon style={{ verticalAlign: 'top'}} fontSize='inherit' /></span>
        </Link>                
    )
}

export const HelpContainer = props => {
    const { title } = props
    return (
        <Paper style={{ maxWidth: '480px', maxHeight: '640px' }}>
            <Card>
                <CardContent>
                    <Typography variant='h6'>{title}</Typography>
                    {props.children}
                </CardContent>
            </Card>
        </Paper>
    )
}