export const hede = "#e1428c"
export const mose = "#C83736"
export const eng = "#8bad3f"
export const overdrev = "#ee7f00"
export const strandeng = "#29e3e3"
export const soe = "#003d73"
export const vandloeb = "#37a0cb"
export const mark = '#fabb00'
export const skov = '#00853E'
export const andet = '#FACBB7'

export const booleanColors = [
    "#fabb00",
    "#8bad3f",
    "#ee7f00",
    "#425821",
    "#FACBB7",
    "#37a0cb",
    "#003d73"
]

export const bioscoreColors = [
    "#FACBB7",
    "#fabb00",
    "#ee7f00",
    "#8bad3f",
    "#425821",
]

export const graesningColors = [
    "#D9D9D9",
    "#C1E5D9",
    "#92C5C0",
    "#A0C880",
    "#BCCF00",
    "#E6ED00",
    "#F6C14D",
    "#F09C26"
]

export const hydrologiColors = [
    "#FACBB7",
    "#fabb00",
    "#ee7f00",
    "#8bad3f",
    "#425821",
]

export const score0100Colors = [
    "#FACBB7",
    "#fabb00",
    "#ee7f00",
    "#8bad3f",
    "#425821",
]


export const score0100PurpleColors = [
    "#f9f8fc",
    "#ebe8f5",
    "#dacbe5",
    "#d0add2",
    "#df84b5",
    "#e1428c",
    "#cd2765",
    "#a62058",
    "#7d1842"
]

export const score0100GreenColors = [
    "#F9FCFA",
    "#EBF5EC",
    "#D3E8D4",
    "#B0D7B5",
    "#80C28F",
    "#2BAD70",
    "#009757",
    "#00853E",
    "#00663A"
]

export const score0100BlueColors = [
    "#F9FBFE",
    "#E3EFFB",
    "#CBE0F6",
    "#A8D0F0",
    "#71B7E6",
    "#1D9DD9",
    "#0083C8",
    "#006AB0",
    "#004D88"
]

export const score0100RedColors = [
    "#FFF8F5",
    "#FDE8DF",
    "#FACBB7",
    "#F5A98D",
    "#F08464",
    "#E35744",
    "#C83736",
    "#AE2E31",
    "#812B31"
]

export const score06Colors = [
    "#FACBB7",
    "#fabb00",
    "#ee7f00",
    "#8bad3f",
    "#425821",
    "#37a0cb"
]

export const beskyttelsesgradColors = {
    "high": [
        "#AE2E31",
        "#FFA43B",
        "#004D88"
    ],
    "medium": [
        "#E35744",
        "#FFCE52",
        "#1D9DD9"
    ],
    "low": [
        "#FACBB7",
        "#FFF0CD",
        "#CBE0F6"
    ]
}