import React from 'react'
import { HelpSection, HelpContainer, HelpParagraph, InfoBox } from './help'
// import { Typography } from '@material-ui/core'

export const dniScoreHelp = (layer) => (
    <HelpContainer title={'DNI'}>
        <HelpSection title=''>
            <HelpParagraph>
                DNI er en sammenvejning af de tre indekser for tilstand, beskyttelse og processer.
                De tre indekser er alle beregnet som en score mellem 0-100, hvor 100 point er det maksimalt opnåelige,
                altså den bedst mulige tilstand og beskyttelse og helt naturlige processer.
            </HelpParagraph>
            <HelpParagraph>
                DNI scoren beregnes som produktet af scorerne for tilstand, beskyttelse og processer for at afspejle at det har en særlig
                betydning at de er lokaliseret det samme sted - altså at værdien af en høj naturtilstand
                stiger hvis den også er beskyttet, at værdien er naturlige processer stiger, hvis de finder
                sted i et område med høj naturtilstand og så fremdeles.
            </HelpParagraph>
            <HelpParagraph>
                En DNI som nærmer sig 100 er udtryk for et område med høj naturtilstand,
                hvor man har sikret en effektiv naturbeskyttelse og genoprettet de vigtigste naturlige processer.
            </HelpParagraph>
            <InfoBox version={layer.version} />
        </HelpSection>
    </HelpContainer>
)
