export const defaultMapper = value => ({
    type: 'number',
    value
})

export const beskyttelsesgradMapper = value => {
    if (value === null || typeof value === 'undefined') {
        return { type: 'null' }
    }
    const intValue = parseInt(value)
    if (isNaN(intValue)) {
        return { type: 'null' }
    }
    if (intValue === 65535) {
        return { type: 'null' }
    }
    const b = intValue % 256;
    const tn = intValue >> 8;
    if (tn < 0) {
        return {
            type: 'string',
            value: 'Ikke relevant'
        }
    }
    return {
        type: 'string',
        value: `Beskyttelse: ${b}, Trusselsniveau: ${tn}`
    }
}

export const naturandelMapper = value => {
    if (value === null || typeof value === 'undefined') {
        return { type: 'null' }
    }
    const intValue = parseInt(value)
    if (isNaN(intValue)) {
        return { type: 'null' }
    }
    if (intValue === -20) {
        return { type: 'string', value: '<20%' };
    } else if (intValue === 0) {
        return { type: 'string', value: '20-40%' }
    } else if (intValue === 10) {
        return { type: 'string', value: '40-60%' }
    } else if (intValue === 20) {
        return { type: 'string', value: '60-80%' }
    } else if (intValue === 30) {
        return { type: 'string', value: '>80%' }
    }
    return { type: 'null' }
}


export const slyngningMapper = value => {
    if (value === null || typeof value === 'undefined') {
        return { type: 'null' }
    }
    const intValue = parseInt(value)
    if (isNaN(intValue)) {
        return { type: 'null' }
    }
    if (intValue === -10) {
        return { type: 'string', value: 'Ikke slynget' };
    } else if (intValue === 10) {
        return { type: 'string', value: 'Slynget' }
    }
    return { type: 'null' }
}



export const sandflugtBarriererMapper = value => {
    if (value === null || typeof value === 'undefined') {
        return { type: 'null' }
    }
    const intValue = parseInt(value)
    if (isNaN(intValue)) {
        return { type: 'null' }
    }
    if (intValue === 10) {
        return { type: 'string', value: '' };
    } else if (intValue === 20) {
        return { type: 'string', value: 'Slynget' }
    } else if (intValue === 30) {
        return { type: 'string', value: 'Slynget' }
    }
    return { type: 'null' }
}

export const numberMapper = value => {
    if (value === null || typeof value === 'undefined') {
        return { type: 'null' }
    }
    const intValue = parseInt(value)
    if (isNaN(intValue)) {
        return { type: 'null' }
    }
    if (intValue < 0 || intValue >= 255) {
        return { type: 'null' }
    }
    return { type: 'number', value }
}

export const floatMapper = value => {
    if (value === null || typeof value === 'undefined') {
        return { type: 'null' }
    }
    const floatValue = parseFloat(value)
    if (isNaN(floatValue)) {
        return { type: 'null' }
    }
    if (floatValue < 0 || floatValue > 100) {
        return { type: 'null' }
    }
    return { type: 'number', value: (~~(value * 10) / 10) }
}

export const booleanMapper = value => {
    if (value === null || typeof value === 'undefined') {
        return { type: 'null' }
    }
    const intValue = parseInt(value)
    if (isNaN(intValue)) {
        return { type: 'null' }
    }
    if (intValue < 0 || intValue === 65535 || intValue === 255) {
        return { type: 'null' }
    }
    return { type: 'boolean', value: value > 0 }
}

export const bitMapper = function () {
    const labels = [...arguments]
    return value => {
        if (value === null || typeof value === 'undefined') {
            return { type: 'null' }
        }
        const intValue = parseInt(value)
        if (isNaN(intValue)) {
            return { type: 'null' }
        }
        if (intValue < 0 || intValue === 65535) {
            return { type: 'null' }
        }
        const values = labels.map((label, idx) => {
            const m = 1 << idx
            console.log(idx, m)
            return {
                label: label,
                value: Boolean((intValue & m) > 0)
            }
        })
        return { type: 'list', value: values }
    }
}

export const intEnumMapper = mapdata => value => {
    if (value === null || typeof value === 'undefined') {
        return { type: 'null' }
    }
    const intValue = parseInt(value)
    if (isNaN(intValue)) {
        return { type: 'null' }
    }
    for (let i = 0; i < mapdata.length; i++) {
        if (intValue === mapdata[i].value) {
            return { type: 'string', value: mapdata[i].label };
        }
    }
    return { type: 'null' }
}