import { Projection } from 'ol/proj'

const projection = new Projection({
    code: 'EPSG:25832',
    units: 'm',
    global: false
})

const mapConfig = {
    projection,
    geoserver: {
        wms: {
            url: 'https://geoserver.bioskaloe.net/geoserver/wms',
            tilesOrigin: 441500.00015483226 + "," + 6049780.000121653
        },
        wmts: {
            awsUrl: 'tiles.naturindikator.dk',
            url: 'https://geoserver.bioskaloe.net/geoserver/gwc/service/wmts',
            gridset: 'EPSG:25832',
            tileGrid: {
                matrixIds: ['L0', 'L1', 'L2', 'L3', 'L4', 'L5', 'L6', 'L7', 'L8', 'L9'],
                resolutions: [1638.4, 819.2, 409.6, 204.8, 102.4, 51.2, 25.6, 12.8, 6.4, 3.2],
                extent: [120000, 5661139.2, 1378291.2, 6500000]
            },
            lowResTileGrid: {
                matrixIds: ['L0', 'L1', 'L2', 'L3', 'L4', 'L5', 'L6', 'L7', 'L8'],
                resolutions: [1638.4, 819.2, 409.6, 204.8, 102.4, 51.2, 25.6, 12.8, 6.4],
                extent: [120000, 5661139.2, 1378291.2, 6500000]
            }
        }
    },
    kortforsyningen: {
        token: '5667e4a44eec736f2aa1263ee03e9fd3'
    }
}

export default mapConfig                