import React from 'react'
import { useState } from 'react'
import LayersIcon from '@material-ui/icons/Layers';
import uiConfig from '../../ui/uiConfig'
import {
    FormControl,
    RadioGroup,
    Radio,
    FormControlLabel,
    Typography,
    FormLabel
} from '@material-ui/core';
import OutsideClickHandler from 'react-outside-click-handler';

const createStyle = position => ({
    padding: 10,
    borderRadius: 3,
    borderColor: '#ccc',
    borderStyle: 'solid',
    borderWidth: 1,
    backgroundColor: 'rgba(240, 240, 240, 0.8)',
    position: 'fixed',
    cursor: 'pointer',
    bottom: position.bottom,
    left: position.left + 10,
    transition: 'all ' + uiConfig.sidebar.animationSpeed + 's'
})

const LayerGroup = props => {
    const { group, zoom, onChange } = props
    return (<div key={group.id} style={{ marginBottom: 8 }}>
        <FormControl>
            <FormLabel color='primary' component='legend'>{group.title}</FormLabel>
            <RadioGroup>
                {group.layers.map(layer => (
                    <FormControlLabel key={layer.title} value={layer.title}
                        control={
                            <Radio
                                checked={group.selectedLayer === layer}
                                disabled={layer.zoom && (layer.zoom.min > zoom || layer.zoom.max < zoom)}
                                onChange={(evt, checked) => onChange(layer, checked)}
                                color='primary' />}
                        label={<Typography variant="subtitle2" color="textPrimary">{layer.title}</Typography>}
                    />
                ))}
            </RadioGroup>
        </FormControl>
    </div>)
}

const LayerGroups = props => {
    const { show, groups, zoom, onChange } = props
    //console.log(groups)
    if (!show) {
        return null
    }
    return (
        <div>
            {groups.map(g => <LayerGroup key={g.id} group={g} zoom={zoom} onChange={(layer, checked) => onChange(g.id, layer, checked)} />)}
        </div>
    )
}

const LayerSelectControl = props => {
    const { position, groups, onChange, zoom } = props
    const [expanded, setExpanded] = useState(false)
    const style = createStyle(position)


    return (
        <OutsideClickHandler onOutsideClick={(() => setExpanded(false))}>
            <div style={style} onClick={() => {if (!expanded) { setExpanded(true)}}}>
                <LayerGroups zoom={zoom} show={expanded} groups={groups} onChange={(group, layer, checked) => onChange(group, layer, checked)} />
                <div onClick={() => setExpanded(!expanded)}>
                    <LayersIcon />
                </div>
            </div>
        </OutsideClickHandler>
    )
}

export default LayerSelectControl