import React, { useState, useEffect } from 'react'
import clsx from 'clsx';
import DniDocs from './DniDocs'
import DniDocsToC from './DniDocsToC'

import DniDocsService from '../service/dniDocsService'
import { makeStyles } from '@material-ui/core/styles';
import { Drawer } from '@material-ui/core';
import { useParams } from 'react-router';
import { objectMap } from '../service/utils';

const drawerWidth = 16 * 32

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        minHeight: '100vh',
        backgroundImage: 'url("/images/forside.png")',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'fixed'
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        position: 'fixed',
        whiteSpace: 'wrap',
        width: drawerWidth,
        zIndex: 800,
        top: '64px',
        minHeight: 'calc(100vh - 64px)',
        maxHeight: 'calc(100vh - 64px)',
        overflowY: 'auto',
        overflowX: 'hidden',
        transition: theme.transitions.create('all', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperHidden: {
        overflowX: 'hidden',
        whiteSpace: 'nowrap',
        zIndex: 800,
        opacity: 0,
        transition: theme.transitions.create('all', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        left: -drawerWidth
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        whiteSpace: 'nowrap',
        zIndex: 800,
        opacity: 0,
        transition: theme.transitions.create('all', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        left: -drawerWidth
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        marginLeft: drawerWidth,
        flexGrow: 1,
        overflow: 'auto',
        transition: theme.transitions.create('all', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        })
    },
    contentFull: {
        marginLeft: 0,
        flexGrow: 1,
        overflow: 'auto',
        transition: theme.transitions.create('all', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        })
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    }
}));

const getContentPathRec = (current, selectedMenu, parentContentPath) => {
    if (current.id === selectedMenu) {
        return current.contentPath || parentContentPath
    }
    let parentPath = current.contentPath || parentContentPath
    let contentPath = null
    current.children.forEach(child => {
        contentPath = getContentPathRec(child, selectedMenu, child.contentPath || parentPath) || contentPath
    })
    return contentPath
}

const getContentPath = (root, selectedMenu) => {
    if (!selectedMenu || !root) {
        return null
    }
    let contentPath = null
    root.forEach(entry => {
        contentPath = getContentPathRec(entry, selectedMenu, entry.contentPath) || contentPath
    })
    return contentPath
}

const fetchContent = async (toc, selectedDocs) => {
    const contentPath = getContentPath(toc, selectedDocs)
    if (!contentPath) {
        return null
    }
    return await DniDocsService.fetchContent(contentPath)
}

const DniDocsPage = props => {
    const { showSidebar } = props
    const [toc, setToc] = useState(null)
    const [content, setContent] = useState(null)
    const classes = useStyles()

    const { selectedDocs } = useParams()
    const hash =  window.location.hash ?
        decodeURI( window.location.hash.substring(1))
        : null
    useEffect(() => {
        setTimeout(async () => {
            if (toc) {
                return
            }
            const data = await DniDocsService.fetchToc()
            const _toc = objectMap(data, (key, value) => value)
            _toc.sort((a, b) => a.id.localeCompare(b.id))
            console.log(_toc)
            setToc(_toc)
        }, 0)
    }, [toc, selectedDocs, setToc, setContent])

    useEffect(() => {
        fetchContent(toc, selectedDocs).then(data => setContent(data))
    }, [setContent, toc, selectedDocs])

    //console.log(toc, content)

    if (!(toc)) {
        return null
    }


    return (
        <div className={classes.root}>
            <Drawer                
                classes={{
                    paper: clsx(classes.drawerPaper, showSidebar ? null : classes.drawerPaperClose, showSidebar ? null : classes.drawerPaperHidden),
                }}
                variant="permanent"
                open={showSidebar}

            >
                <DniDocsToC toc={toc} selectedDocs={selectedDocs} hashLocation={hash} />
            </Drawer>
            <main className={showSidebar ? classes.content : classes.contentFull}>
                <DniDocs content={content} toc={toc} selectedDocs={selectedDocs} hashLocation={hash} showSidebar={showSidebar} />
            </main>
        </div>
    )
}

export default DniDocsPage