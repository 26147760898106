import { Typography } from '@material-ui/core'
import React from 'react'
import { HelpSection, HelpContainer, HelpInfo, HelpParagraph, InfoBox, HelpLink } from './help'
// import { Typography } from '@material-ui/core'

export const reglerVejledendeBeskrivelse = () => {
    return <Typography style={{ paddingLeft: 16, paddingRight: 48, paddingBottom: 8 }} variant='body2'>
        Her vises kortlag for regelsættene som de indgår i beskyttelsesscoren i DNI.
        Kortlagene er vejledende og kan ikke danne retsgrundlag. Her henvises til <HelpLink href='https://www.retsinformation.dk/' value="retsinformation.dk" />
    </Typography>
}

export const samletBeskyttelseHelp = (layer) => (
    <HelpContainer title={'Samlet beskyttelse'}>
        <HelpSection>
            <HelpParagraph>
                Beskyttelsen af naturen afspejler i hvilken grad den eksisterende naturbeskyttende lovgivning
                formår at beskytte biodiversiteten mod de vigtigste trusler. Vi har beregnet beskyttelsen ved
                at samle den eksisterende naturbeskyttende lovgivning (samt udarbejde kort over hvor denne
                lovgivning er gældende i Danmark) og udarbejde en liste over de vigtigste trusler mod biodiversiteten.
            </HelpParagraph>
            <HelpParagraph>
                Vigtige trusler kan eksempelvis være fældning af træer, dræning af vådområder, overgræsning med husdyr,
                regulering af erosion eller konvertering af naturområder til byer eller marker.
                Dernæst har vi inviteret en kreds af eksperter i naturbeskyttelse og eksperter i biodiversitet
                til at vurdere styrken af lovgivningen og alvoren af truslerne, hvis der ingen lovgivning fandtes.
                Vi bruger disse vurderinger til at beregne hvor effektivt konkrete naturområder er beskyttet ved at beregne
                et gennemsnit af den lovbestemte beskyttelse mod truslerne som findes på et punkt i en
                konkret naturlokalitet vægtet med betydningen af de trusler der findes.
            </HelpParagraph>
            <HelpParagraph>
                Den endelige beskyttelsesscore angiver således hvor mange procent af det samlede trusselsbillede på et sted,
                som er håndteret effektivt i den naturbeskyttende lovgivning.
            </HelpParagraph>
        </HelpSection>
        <InfoBox version={layer.version} />
    </HelpContainer>
)

export const naturbeskyttelsesloven = (layer) => (
    <HelpContainer title={'§3 Naturbeskyttelsesloven'}>
        <HelpSection>
            <HelpParagraph>
                Reglerne om beskyttede naturtyper tager udgangspunkt i naturbeskyttelseslovens § 3 om beskyttelse
                af visse søer, vandløb, heder, moser, strandenge, ferske enge og overdrev.
                Naturbeskyttelseslovens § 3 beskytter arealerne, som naturtyperne findes på, mod
                tilstandsændringer forårsaget af aktiviteter på eller i nærheden af arealerne.
                Ikke alle søer, vandløb, heder, moser, strandenge, ferske enge og overdrev er
                beskyttede idet en række faktuelle kriterier vedrørende beliggenhed, botanik, drift,
                jordbund og størrelse skal være opfyldt. 
                <HelpParagraph>
                    En række retlige forhold vedrørende bl.a. anmeldelse,
                    driftsaftaler med det offentlige eller kommunal planlægning kan også undtage arealerne fra beskyttelse.
                    Stat og kommuner har pligt til at pleje egne arealer, så de fastholdes i beskyttet tilstand. 
                </HelpParagraph>
                <HelpLink href='/dokumentation/1_regelsaetkatalog#reglerne-om-beskyttede-naturtyper' value="Læs mere her." />
                 </HelpParagraph>
        </HelpSection>
        <InfoBox version={layer.version}>
            <HelpInfo label='Kilde' href='https://arealinformation.miljoeportal.dk' value="miljoeportal.dk" link />
            <HelpInfo label='Dato' value='2019-05-06' />
        </InfoBox>
    </HelpContainer>
)

export const fredningskendelser = (layer) => (
    <HelpContainer title={'Fredningskendelser og deklarationer med sprøjtnings- og gødsknings-forbud'}>
        <HelpSection>
            <HelpParagraph>
                Kortlaget viser arealer som er omfattet af Naturbeskyttelseslovens kapitel 6 om fredninger.
                </HelpParagraph>
                <HelpLink href='/dokumentation/1_regelsaetkatalog#reglerne-om-fredninger-efter-naturbeskyttelsesloven' value="Læs mere her." />
        </HelpSection>
        <InfoBox version={layer.version}>
            <HelpInfo label='Kilde' value='Pers. komm. LBST' />
            <HelpInfo label='Dato' value='NA' />
        </InfoBox>
    </HelpContainer>
)

export const beskyttelseslinjer = (layer) => (
    <HelpContainer title={'Beskyttelseslinjer'}>
        <HelpSection>
            <HelpParagraph>
                Kortlaget viser arealer som er omfattet af Naturbeskyttelseslovens kapitel 2,
                afsnittet om beskyttelseslinjer (§ 15-19) Beskyttelseslinjer efter 
                Naturbeskyttelsesloven forholder sig til kyster, søer, vandløb, skove, fortidsminder og kirker. 
                </HelpParagraph>
                <HelpLink href='/dokumentation/1_regelsaetkatalog#reglerne-om-beskyttelseslinjer' value="Læs mere her." />
            <HelpParagraph title = "Kilder">
                Klitfredede arealer og strandbeskyttelseslinjen er en del af matrikelkortet.
                Sø og å-beskyttelseslinjen er fra Danmarks Arealinformation.
            </HelpParagraph>    
        </HelpSection>
        <InfoBox version={layer.version}>
            <HelpInfo label='Kilde' href='https://download.kortforsyningen.dk/content/matrikelkortet' value='kortforsyningen.dk' link />
            <HelpInfo label='Dato' value='NA' />
            <HelpInfo label='Kilde' value='Arealinformation'  />
            <HelpInfo label='Dato' value='2019-04-09' />
        </InfoBox>
    </HelpContainer>
)

export const skovlovenHelp = (layer) => (
    <HelpContainer title={'Skovloven'}>
        <HelpSection>
            <HelpParagraph>
                Kortlaget viser arealer som er omfattet af fredskovspligt efter Skovlovens § 3.
                Endvidere er arealer som er omfattet af § 25 (naturmæssigt særligt værdifulde skov),
                § 26 (​Bevaringsværdige egekrat)​ og § 28 (beskyttelse af andre naturtyper beliggende i fredskov)
                vist. 
            </HelpParagraph>
                <HelpLink href='/dokumentation/1_regelsaetkatalog#reglerne-om-fredskovspligt-og-beskyttet-natur-på-fredskovspligtige-arealer' value="Læs mere her." />
            <HelpParagraph title="Kilder">
                Fredskovspligtige arealer er en del af matrikelkortet.
                ​Bevaringsværdige egekrat er fra Miljøgis.
                Lysåbne arealer (§ 27) & løvskovsbryn (§ 28) er per 2020-12-31 ikke kortlagt.
            </HelpParagraph>
        </HelpSection>
        <InfoBox version={layer.version}>
            <HelpInfo label='Kilde - Fredskov' href='https://download.kortforsyningen.dk/content/matrikelkortet' value='kortforsyningen.dk' link />
            <HelpInfo label='Dato' value='NA' />
            <HelpInfo label='Kilde - Egekrat' value='Miljøgis' />
            <HelpInfo label='Dato' value='NA' />
        </InfoBox>
    </HelpContainer>
)

export const vandlobslovenHelp = (layer) => (
    <HelpContainer title={'Vandløbsloven'}>
        <HelpSection>
            <HelpParagraph>
                Kortlaget viser vandløb og søer som er omfattet af Vandløbsloven.
                Vandløbsloven omfatter både beskyttelse af søer og vandløb samt krav om vedligeholdelse af vandføringsevne.
                </HelpParagraph>
                <HelpLink href='/dokumentation/1_regelsaetkatalog#vandløbslovens-regler' value="Læs mere her." />
        </HelpSection>
        <InfoBox version={layer.version}>
            <HelpInfo label='Kilde' value='kortforsyningen.dk' link />
            <HelpInfo label='Dato' value='31/10-2020' />
        </InfoBox>
    </HelpContainer>
)

export const okkerlovenHelp = (layer) => (
    <HelpContainer title={'Okkerlovens krav om dræningstilladelse'}>
        <HelpSection>
            <HelpParagraph>
                Kortlaget viser arealer som er omfattet af Okkerloven. Okkerlovens regler betyder,
                at der inden for nærmere afgrænsede områder ikke må etableres yderligere
                afvanding eller dræning uden tilladelse fra kommunen.
                 </HelpParagraph>
                 <HelpLink href='/dokumentation/1_regelsaetkatalog#okkerlovens-krav-om-dræningstilladelse' value="Læs mere her." />
        </HelpSection>
        <InfoBox version={layer.version}>
            <HelpInfo label='Kilde' value='kortforsyningen.dk' link />
            <HelpInfo label='Dato' value='31/10-2020' />
        </InfoBox>
    </HelpContainer>
)

export const vandforsyningslovenHelp = (layer) => (
    <HelpContainer title={'Vandforsyningsloven krav om indvindingstilladelse'}>
        <HelpSection>
            <HelpParagraph>
                Kortlaget viser arealer som er omfattet af Vandforsyningsloven. Vandforsyningslovens
                regler indebærer et krav om, at al indvinding af overfladevand og grundvand
                som udgangspunkt kræver en tilladelse fra kommunen. Tilladelse til vandindvinding
                skal bygge på en vurdering af indvindingens påvirkning på omgivelserne
                </HelpParagraph>
                <HelpLink href='/dokumentation/1_regelsaetkatalog#vandforsyningsloven-krav-om-indvindingstilladelse' value="Læs mere her." />
        </HelpSection>
        <InfoBox version={layer.version}>
            <HelpInfo label='Kilde' value='kortforsyningen.dk' link />
            <HelpInfo label='Dato' value='31/10-2020' />
        </InfoBox>
    </HelpContainer>
)

export const miljobeskyttelseslovenHelp = (layer) => (
    <HelpContainer title={'Miljøbeskyttelseslovens krav om udledningstilladelse'}>
        <HelpSection>
            <HelpParagraph>
                Kortlaget viser søer og vandløb som er omfattet af Miljøbeskyttelseslovens regler om udledning af spildevand.
                Udledning af spildevand til søer og vandløb, bortset fra jordbrugsmæssigt drænvand, kræver 
                udledningstilladelse efter miljøbeskyttelseslovens regler (fra kommunen).
                </HelpParagraph>
                <HelpLink href='/dokumentation/1_regelsaetkatalog#miljøbeskyttelseslovens-krav-om-udledningstilladelse' value="Læs mere her." />
        </HelpSection>
        <InfoBox version={layer.version}>
            <HelpInfo label='Kilde' value='kortforsyningen.dk' link />
            <HelpInfo label='Dato' value='31/10-2020' />
        </InfoBox>
    </HelpContainer>
)

export const raastoflovenHelp = (layer) => (
    <HelpContainer title='Råstofloven'>
        <HelpSection>
            <HelpParagraph>
                Kortlaget viser arealer som er omfattet af Råstofloven. 
                Råstofloven indeholder et krav om at erhvervsmæssig indvinding
                af råstoffer over 200 m<sup>3</sup>/år, herunder sten, grus, sand, ler,
                kalk, kridt, tørv, muld m.v., kræver tilladelse fra regionen.
                For så vidt angår indvinding af sand og sten fra strande er
                Kystdirektoratet myndighed. Nye tilladelser er som udgangspunkt
                begrænset til 10 år. Tilladelserne gives overvejende på baggrund
                af regionernes råstofplanlægning.
            </HelpParagraph>
            <HelpLink href='/dokumentation/1_regelsaetkatalog#råstoflovens-krav-om-råstoftilladelse' value="Læs mere her." />
        </HelpSection>
        <InfoBox version={layer.version}>
            <HelpInfo label='Kilde' value='kortforsyningen.dk' link />
            <HelpInfo label='Dato' value='31/10-2020' />
        </InfoBox>
    </HelpContainer>
)

export const vildtforvaltningsreglerHelp = (layer) => (
    <HelpContainer title={'Jagt- og vildtforvaltningsregler'}>
        <HelpSection>
            <HelpParagraph>
                Kortlaget viser arealer som er omfattet af Jagt og Vildtforvaltningsloven.
                Jagt og vildtforvaltningsloven sætter rammerne for hvilke dyrearter, der
                må jages og reguleres i Danmark, på hvilke måder, de må jages, samt inden
                for hvilke tidsrum, jagten må finde sted. Reglerne er på nogle områder
                forskellige i forskellige dele af landet
            </HelpParagraph>
            <HelpLink href='/dokumentation/1_regelsaetkatalog#jagt--og-vildtforvaltningsreglerne' value="Læs mere her." />
        </HelpSection>
        <InfoBox version={layer.version}>
            <HelpInfo label='Kilde' value='kortforsyningen.dk' link />
            <HelpInfo label='Dato' value='31/10-2020' />
        </InfoBox>
    </HelpContainer>
)


export const reguleringafgodvingsanvendelseHelp = (layer) => (
    <HelpContainer title={'Regulering af gødningsanvendelse'}>
        <HelpSection>
            <HelpParagraph>
                Kortlaget viser arealer som er omfattet af regulering af gødningsanvendelse. 
                Den generelle regulering af jordbrugserhvervenes gødningsanvendelse
                sætter grænser for hvor meget gødning, der på baggrund af afgrødevalg
                m.v. må anvendes på den enkelte bedrift. For organisk gødnings
                vedkommende er der også begrænsninger i anvendelsen i forhold til
                Natura 2000-områder og fosforbelastede søer.
                </HelpParagraph>
                <HelpLink href='/dokumentation/1_regelsaetkatalog#den-generelle-regulering-af-jordbrugerhvervenes-gødningsanvendelse' value="Læs mere her." />
        </HelpSection>
        <InfoBox version={layer.version}>
            <HelpInfo label='Kilde' value='kortforsyningen.dk' link />
            <HelpInfo label='Dato' value='31/10-2020' />
        </InfoBox>
    </HelpContainer>
)


export const husdyrbrugsreguleringenHelp = (layer) => (
    <HelpContainer title={'Husdyrbrugsreguleringen'}>
        <HelpSection>
            <HelpParagraph>
                Hele Danmark er omfattet de generelle bestemmelser om husdyrbrug.
                Kortlaget viser naturområder og beskyttede naturtyper som er beskyttet med
                afstandskrav til husdyrbrug.
                </HelpParagraph>
                <HelpLink href='/dokumentation/1_regelsaetkatalog#regulering-af-husdyrbrug' value="Læs mere her." />
        </HelpSection>
        <InfoBox version={layer.version}>
            <HelpInfo label='Kilde' value='kortforsyningen.dk' link />
            <HelpInfo label='Dato' value='31/10-2020' />
        </InfoBox>
    </HelpContainer>
)


export const pesticidreguleringHelp = (layer) => (
    <HelpContainer title={'Pesticidregulering'}>
        <HelpSection>
            <HelpParagraph>
                Kortlaget viser arealer som er omfattet af regler for anvendelse af pesticider.
                Miljøstyrelsens godkender sprøjtemidler og fører samme​n med
                Landbrugsstyrelsen tilsyn med reglernes overholdelse.
                </HelpParagraph>
                <HelpLink href='/dokumentation/1_regelsaetkatalog#den-generelle-pesticidregulering' value="Læs mere her." />
        </HelpSection>
        <InfoBox version={layer.version}>
            <HelpInfo label='Kilde' value='kortforsyningen.dk' link />
            <HelpInfo label='Dato' value='31/10-2020' />
        </InfoBox>
    </HelpContainer>
)


export const kommuneplansretningslinjerHelp = (layer) => (
    <HelpContainer title={'Kommuneplanretningslinjer'}>
        <HelpSection>
            <HelpParagraph>
                Kortlaget viser arealer som er omfattet af Planlovens bestemmelser om 
                særlige hensyn som kommuner skal tage til naturinteresser i 
                forbindelse med udarbejdelse kommuneplan.
                </HelpParagraph>
                <HelpLink href='/dokumentation/1_regelsaetkatalog#kommuneplanretningslinjer' value="Læs mere her." />
        </HelpSection>
        <InfoBox version={layer.version}>
            <HelpInfo label='Kilde' value='kortforsyningen.dk' link />
            <HelpInfo label='Dato' value='31/10-2020' />
        </InfoBox>
    </HelpContainer>
)


export const natura2000Help = (layer) => (
    <HelpContainer title={'Natura 2000'}>
        <HelpSection>
            <HelpParagraph>
                Kortlaget viser arealer der er udpeget som Natura 2000. Natura 2000 er 
                betegnelsen for et netværk af beskyttede naturområder i EU. 
                Områderne skal bevare og beskytte naturtyper og vilde dyre-
                og plantearter, som er sjældne, truede eller karakteristiske
                for EU-landene.
                </HelpParagraph>
                <HelpLink href='/dokumentation/1_regelsaetkatalog#natura-2000-områder' value="Læs mere her." /> 
        </HelpSection>
        <InfoBox version={layer.version}>
            <HelpInfo label='Kilde' value='kortforsyningen.dk' link />
            <HelpInfo label='Dato' value='31/10-2020' />
        </InfoBox>
    </HelpContainer>
)


export const bilagIvHelp = (layer) => (
    <HelpContainer title={'Bilag IV'}>
        <HelpSection>
            <HelpParagraph>
                Bilag IV henviser til EU’s habitatdirektivs bilag IV, hvor der er
                oplistet dyre- og plantearter, som er omfattet af streng beskyttelse.
                De danske regler om beskyttelse af bilag IV-arter omfatter visse 
                hjemmehørende dyre- og plantearter fra Habitatdirektivets bilag IV. 
                Reglerne er implementeret som et forbud mod, at myndighederne tillader
                projekter, vedtager planer m.v. som kan ødelægge plantearterne og
                deres levesteder eller beskadige eller ødelægge yngle- eller 
                rasteområderne for dyrearterne. Reglerne er gældende i hele Danmark.
                </HelpParagraph>
                <HelpLink href='/dokumentation/1_regelsaetkatalog#reglerne-om-beskyttelse-af-bilag-iv-arter-og-deres-yngle--og-rasteområder' value="Læs mere her." /> 
        </HelpSection>
        <InfoBox version={layer.version}>
            <HelpInfo label='Kilde' value='kortforsyningen.dk' link />
            <HelpInfo label='Dato' value='31/10-2020' />
        </InfoBox>
    </HelpContainer>
)

export const soervandlobHelp = (layer) => (
    <HelpContainer title={'Miljømål for søer og vandløb'}>
        <HelpSection>
            <HelpParagraph>
                Kortlaget viser områder som er omfattet af miljømål for søer og vandløb.
                Regler om miljømål for søer og vandløb fastsættes af
                miljøministeren og implementerer vandrammedirektivets bestemmelser
                vedrørende natur- og vandkvaliteten i en del af vores ferske vande.
                </HelpParagraph>
                <HelpLink href='/dokumentation/1_regelsaetkatalog#miljømål-for-søer-og-vandløb' value="Læs mere her." /> 
        </HelpSection>
        <InfoBox version={layer.version}>
            <HelpInfo label='Kilde' value='kortforsyningen.dk' link />
            <HelpInfo label='Dato' value='31/10-2020' />
        </InfoBox>
    </HelpContainer>
)
export const nationalparkerHelp = (layer) => (
    <HelpContainer title={'Nationalparker'}>
        <HelpSection>
            <HelpParagraph>
                Kortlaget viser arealer som er udpeget til Nationalpark efter lov om nationalparker
                </HelpParagraph>
                <HelpLink href='/dokumentation/1_regelsaetkatalog#nationalparkreglerne' value="Læs mere her." /> 
        </HelpSection>
        <InfoBox version={layer.version}>
            <HelpInfo label='Kilde' value='kortforsyningen.dk' link />
            <HelpInfo label='Dato' value='31/10-2020' />
        </InfoBox>
    </HelpContainer>
)
export const stoetteordningerHelp = (layer) => (
    <HelpContainer title={'Støtteordninger'}>
        <HelpSection>
            <HelpParagraph>
                Kortlaget viser arealer som er omfattet af støtteordninger med varige aftaler.
                </HelpParagraph>
                <HelpLink href='/dokumentation/1_regelsaetkatalog#støtteordninger-med-varige-aftaler' value="Læs mere her." /> 
        </HelpSection>
        <InfoBox version={layer.version}>
            <HelpInfo label='Kilde' value='kortforsyningen.dk' link />
            <HelpInfo label='Dato' value='31/10-2020' />
        </InfoBox>
    </HelpContainer>
)
export const urortskovHelp = (layer) => (
    <HelpContainer title={'Urørt skov'}>
        <HelpSection>
            <HelpParagraph>
            Kortlaget viser områder på Naturstyrelsens arealer som er udlagt til urørt skov.
            Der har siden 1990-erne været udlagt bevoksninger til urørt skov på
            Naturstyrelsen arealer. Udlæggene af urørt skov er sket efter Naturstyrelsens
            egne retningslinjer, og arealerne er meget forskellige. Med Naturpakken fra 
            maj 2016, skal der udlægges yderligere urørt skov på naturstyrelsens arealer.
            De politiske aftaler bag udlæggene er ikke retligt bindende. 
            </HelpParagraph>
            <HelpLink href='/dokumentation/2_ikke-arealbundne#urørt-skov-på-naturstyrelsens-arealer' value="Læs mere her." /> 
        </HelpSection>
        <InfoBox version={layer.version}>
            <HelpInfo label='Kilde' value='kortforsyningen.dk' link />
            <HelpInfo label='Dato' value='31/10-2020' />
        </InfoBox>
    </HelpContainer>
)
export const biodiversitetsskovHelp = (layer) => (
    <HelpContainer title={'Biodiversitetsskov'}>
        <HelpSection>
            <HelpParagraph>
                Kortlaget viser områder på Naturstyrelsens arealer som er udlagt til biodiversitetsskov.
                Målet med biodiversitetsskov er at opnå skov, hvor der forvaltes målrettet
                efter biodiversitet sideløbende med ekstensiv skovdrift.
                </HelpParagraph>
            <HelpLink href='/dokumentation/2_ikke-arealbundne#biodiversitetsskov-på-naturstyrelsens-arealer' value="Læs mere her."/> 
        </HelpSection>
        <InfoBox version={layer.version}>
            <HelpInfo label='Kilde' href="https://miljoegis.mim.dk" value='Miljøgis' link />
            <HelpInfo label='Dato' value='2020-09-29' />
        </InfoBox>
    </HelpContainer>
)



