const getData = async (response) => {
    try {
        return await response.json()
    } catch (error) {
        return {}
    }
}

const call = async (url, config) => {
    const response = await fetch(url, config)
    try {
        const data = await getData(response)
        if (response.status >= 400) {
            return Promise.reject({
                status: response.status,
                error: data
            })
        }
        return Promise.resolve(data)
    } catch (error) {
        return Promise.reject({
            status: response.status,
            error
        })
    }
}

const callRaw = async (url, config) => {
    const response = await fetch(url, config)
    try {
        const data = response.text()
        if (response.status >= 400) {
            return Promise.reject({
                status: response.status,
                error: data
            })
        }
        return Promise.resolve(data)
    } catch (error) {
        return Promise.reject({
            status: response.status,
            error
        })
    }
}

const getConfig = (method) => ({ method })

const queryOptionsToParams = (queryOptions) => {
    //console.log(queryOptions)
    if (!queryOptions) {
        return '';
    }
    let parameters = [];
    for (const [key, value] of Object.entries(queryOptions)) {
        if (value && value !== null) {
            parameters.push(encodeURIComponent(key) + '=' + encodeURIComponent(value));
        }
    }
    if (parameters.length === 0) {
        return '';
    }
    return '?' + parameters.join('&');
}

export const get = async (baseUrl, url, queryOptions) => {
    const config = getConfig('GET')
    const _qoptions = queryOptions ? queryOptionsToParams(queryOptions) : ''
    //console.log('qoptions', _qoptions)
    const _url = baseUrl + url + _qoptions
    return call(_url, config)
}


export const safeget = async (baseUrl, url, queryOptions) => {
    try {
    const config = getConfig('GET')
    const _qoptions = queryOptions ? queryOptionsToParams(queryOptions) : ''
    //console.log('qoptions', _qoptions)
    const _url = baseUrl + url + _qoptions
    const data = await call(_url, config)
    return Promise.resolve({ data, error: null })
    } catch (error) {
        console.error('suppressing', error)
        return Promise.resolve({ data: null, error })
    }
}

export const getText = async (baseUrl, url, queryOptions) => {
    const config = getConfig('GET')
    const _url = baseUrl + url + queryOptionsToParams(queryOptions)
    return callRaw(_url, config)
}
