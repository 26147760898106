import 'ol/ol.css';
import {
    Group as LayerGroup
} from 'ol/layer'
import GeoJSON from 'ol/format/GeoJSON';
import {
    Vector
} from 'ol/source';
//import VectorLayer from 'ol/layer/Vector';
import VectorImageLayer from 'ol/layer/VectorImage';
import {
    Fill,
    Stroke,
    Style
} from 'ol/style'

import {
    refresh, 
    getFeatureFromCoordinate,
    getOverlappingGeometries
} from './vectorUtils'

const timers = [200, 400, 600, 900, 2000 ]

const createStyle = opacity => new Style({
    fill: new Fill({
        color: `rgba(0, 61, 115, ${opacity})`,
    }),
    stroke: new Stroke({
        color: 'rgba(	0, 37, 70,1)',
        width: 1,
    })
});

const createVectorSource = (fetchFn, box) => {
    const source = new Vector({
        format: new GeoJSON({
            featureProjection: "EPSG:28532"
        }),
        overlaps: false,
        loader: (extent, resolution, projection) => {
            fetchFn().then(featureCollection => {
                console.log("Create vector source")
                if (featureCollection) {
                    // console.log(box)
                    //source.clear(true)
                    source.featureCollection = featureCollection
                    source.addFeatures(source.getFormat().readFeatures(featureCollection))
                }
            })
        }
    })
    return source
}



const createLayer = (fetchFn, style, box) => {
    //console.log(box)
    const source = createVectorSource(fetchFn, box)
    //console.log(source)
    const layer = new VectorImageLayer({
        visible: true,
        source: source,
        style: function (feature) {
            return style;
        },
    });
    layer.id = box
    return layer
}

const createLayerGroup = title => {
    return new LayerGroup({
        title,
        visible: true,
        layers: []
    })
}

const update = function (fetchFn, style, bbox, layerGroup) {
    //console.log('updating..')
    if (layerGroup.getLayers().getArray().length > 0) {
        return
    }
    layerGroup.getLayers().getArray().splice(0, layerGroup.getLayers().getArray().length)

    layerGroup.changed()

    const layer = createLayer(fetchFn, style, bbox)
    layerGroup.getLayers().getArray().push(layer)
    //layerGroup.getLayers().getArray().forEach(l => l.getSource().changed())
    layerGroup.changed()
    timers.forEach(delay => refresh(layerGroup, delay))
    // console.log('update complete')
}

const simpleVectorLayer = (title, _fetchFn, opacity) => {
    const style = createStyle(opacity)
    const layerGroup = createLayerGroup(title)
    const fetchFn = _fetchFn
    return {
        layerGroup: layerGroup,
        setVisible: (visible) => layerGroup.setVisible(visible),
        clear: () => {
            layerGroup.getLayers().getArray().splice(0, layerGroup.getLayers().getArray().length)
        },
        update: (extent) => {
            update(fetchFn, style, extent, layerGroup)
        },
        getOverlappingGeometries: (polygon) => getOverlappingGeometries(polygon, layerGroup),
        getFeature: (coordinate) => getFeatureFromCoordinate(coordinate, layerGroup)
    }
}

const self = {
    create: (title, fetchFn, opacity) => simpleVectorLayer(title, fetchFn, opacity)
}

export default self