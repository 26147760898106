import {
  strandeng,
  vandloeb,
  eng,
  soe, 
  mark,
  skov,
  overdrev,
  mose,
  hede,
  andet
} from '../../service/data/layerColors'

const mapper = {
  '10': { label: 'Strandeng', color: strandeng },
  '11': { label: 'Vandløb', color: vandloeb },
  '5': { label: 'Eng', color: eng },
  '12': { label: 'Sø', color: soe },
  '13': { label: 'Mark', color: mark },
  '9': { label: 'Skov', color: skov},
  '8': { label: 'Overdrev', color: overdrev },
  '7': { label: 'Mose', color: mose },
  '6': { label: 'Hede', color: hede},
  '99': { label: 'Andet', color: andet }
}



export const naturtypeId2Obj = id => mapper[id]