import React from "react"
import withIcon from './withIcon'
const Icon = props => {
    const title = props.title || 'Hand'
    const { style, width, height } = props
    return (
        <svg id={title} aria-label={title} width={width}
            height={height}
            style={style}
            data-name={title} xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512">
            <path d="M169.7 218.64l-1.78.45c-3.55-6.85-7-13.74-10.69-20.53-5.73-10.63-14.58-17.06-26.62-18.49a54.42 54.42 0 00-34.18 7.09c-12.87 7.39-16.76 18.23-12 32.33 6.6 19.39 13.86 38.59 19.59 58.23 10.71 36.64 26.71 70.33 49.75 101C173.05 404.3 191 431 198.5 462.92c1.94 8.29 6.8 10.68 15 10.63 46.52-.31 93.06-.41 139.58.06 9.47.1 13.34-3.61 15.64-12.14 9.23-34.26 19-68.38 28.42-102.57 4.53-16.39 11.84-32.68 12.66-49.27 1.9-38.35 1.07-76.86.44-115.28-.38-23.44-22.93-39.25-45.61-33.21-3.18.85-6.33 1.8-9.57 2.73-4.85-33.4-19.36-42.32-56.15-35.2-4.85-43.15-17.57-53.2-56.44-43.89 0-3.56.18-7 0-10.41-1.32-22.07-21.12-37.73-42.59-33.75-18.64 3.46-30.12 17.63-30.15 37.66q-.12 70.18-.03 140.36z" />
            <path d="M169.7 218.64V78.28c0-20 11.51-34.2 30.15-37.66 21.47-4 41.27 11.68 42.59 33.75.21 3.41 0 6.85 0 10.41 38.87-9.31 51.59.74 56.44 43.89 36.79-7.12 51.3 1.8 56.15 35.2 3.24-.93 6.39-1.88 9.57-2.73 22.68-6 45.23 9.77 45.61 33.21.63 38.42 1.46 76.93-.44 115.28-.82 16.59-8.13 32.88-12.66 49.27-9.45 34.19-19.19 68.31-28.42 102.57-2.3 8.53-6.17 12.24-15.64 12.14-46.52-.47-93.06-.37-139.58-.06-8.23.05-13.09-2.34-15-10.63-7.49-32-25.45-58.62-44.7-84.23-23-30.64-39-64.33-49.75-101-5.73-19.64-13-38.84-19.59-58.23-4.79-14.1-.9-24.94 12-32.33a54.42 54.42 0 0134.18-7.09c12 1.43 20.89 7.86 26.62 18.49 3.66 6.79 7.14 13.68 10.69 20.53zm17.3-52.32c0 29.76-.1 59.52.09 89.28 0 5.64-1.54 10.74-7.2 10.74-3.5 0-8.43-3.49-10.28-6.79-8.49-15.07-16.92-30.27-23.84-46.09-8.89-20.34-22.74-18.92-37.55-13.09-7.23 2.85-10 7.21-7.06 15.55 10.93 30.69 20.79 61.77 32 92.36 4.19 11.48 10.37 22.35 16.66 32.9 7.24 12.13 15.45 23.7 23.5 35.33 15.92 23 32.45 45.7 38.88 73.69 1.13 4.93 4.13 6 8.82 5.94q62-.24 123.94 0c5.76 0 7.85-2 9.31-7.34 9.39-34.8 19.2-69.49 28.8-104.23 3.45-12.5 9.09-25 9.53-37.6 1.29-36.93.56-73.94.38-110.92 0-7.79-3.74-13.83-11.15-17.09-13.15-5.79-26.95 3-27.37 17.84-.39 13.86 0 27.73-.17 41.6-.06 5.31-2.42 9-8.31 8.85s-8-3.77-8.09-9.15v-6.06c0-23.41.15-46.81-.09-70.21-.11-11.08-8.45-19.12-19.21-19.38-11.33-.28-19.93 7.59-20.22 19.17-.31 12.71-.07 25.43-.08 38.14 0 7.8 0 15.6-.07 23.4-.06 5.55-3.31 9.54-8.43 8.49-3-.61-5.48-5.24-7.51-8.49-.91-1.46-.17-3.95-.17-6 0-32.07.06-64.14 0-96.21 0-14.11-8.16-23.52-19.89-23.57-12.07-.06-19.66 9-19.66 23.41v102.27c0 2.32.81 5.19-.25 6.82-2.12 3.24-5.27 8-8 8s-6.3-4.48-8.42-7.7c-1.21-1.83-.37-5.06-.37-7.65V76.92c0-14.17-12.08-23.3-25-19.28-8.49 2.63-13.29 9.92-13.32 21-.2 29.36-.2 58.5-.2 87.68z" />
            <path d="M187 166.32c0-29.18-.06-58.36 0-87.54 0-11.12 4.83-18.41 13.32-21 13-4 25 5.11 25 19.28q.13 72.8 0 145.61c0 2.59-.84 5.82.37 7.65 2.12 3.22 5.55 7.71 8.42 7.7s5.89-4.79 8-8c1.06-1.63.25-4.5.25-6.82V120.93c0-14.46 7.59-23.47 19.66-23.41 11.73 0 19.85 9.46 19.89 23.57.1 32.07 0 64.14 0 96.21 0 2-.74 4.51.17 6 2 3.25 4.51 7.88 7.51 8.49 5.12 1.05 8.37-2.94 8.43-8.49.09-7.8.06-15.6.07-23.4 0-12.71-.23-25.43.08-38.14.29-11.58 8.89-19.45 20.22-19.17 10.76.26 19.1 8.3 19.21 19.38.24 23.4.08 46.8.09 70.21v6.06c.06 5.38 2.32 9 8.09 9.15s8.25-3.54 8.31-8.85c.13-13.87-.22-27.74.17-41.6.42-14.83 14.22-23.63 27.37-17.84 7.41 3.26 11.12 9.3 11.15 17.09.18 37 .91 74-.38 110.92-.44 12.63-6.08 25.1-9.53 37.6-9.6 34.74-19.41 69.43-28.8 104.23-1.46 5.38-3.55 7.38-9.31 7.34q-62-.37-123.94 0c-4.69 0-7.69-1-8.82-5.94-6.43-28-23-50.67-38.88-73.69-8-11.63-16.26-23.2-23.5-35.33-6.29-10.55-12.47-21.42-16.66-32.9-11.2-30.59-21.06-61.67-32-92.36-3-8.34-.17-12.7 7.06-15.55 14.81-5.83 28.66-7.25 37.55 13.09 6.92 15.82 15.35 31 23.84 46.09 1.85 3.3 6.78 6.8 10.28 6.79 5.66 0 7.23-5.1 7.2-10.74.05-29.9.11-59.66.11-89.42z" />
        </svg>
    )
}
export default withIcon(Icon)