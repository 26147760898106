import React, { useState } from 'react';


import {
  Switch,
  Route,
  useLocation
} from "react-router-dom";

import TopBar from './ui/TopBar'

import DniDocsPage from './pages/DniDocsPage'
import MapPage from './pages/MapPage';
import ReportTestPage from './pages/ReportTestPage';

import './App.css'

const menus = [
  { label: 'Kort', value:'/', path: '/' },
  { label: 'Dokumentation', value:'/dokumentation', path: '/dokumentation/01_dniscore' },
]
//  { label: 'Test', value: '/test', path: '/test' }

//const dk = [402535, 6017299, 919204, 6460999]

function App() {
  const [ showSidebar, setShowSidebar ] = useState(true)
  const location = useLocation()
  console.log(location)
  return (
      <div style={{ height: '100vh' }}>
        <TopBar color='default' menus={menus} showToggle={location.pathname !== '/'} onToggleSidebar={() => setShowSidebar(!showSidebar)} />
        <Switch>         
          <Route path="/dokumentation/:selectedDocs">
            <DniDocsPage showSidebar={showSidebar} />
          </Route>
          <Route exact path="/test">
            <ReportTestPage title='Test' fullWidth={true} expanded={true} top={64}/>
          </Route>
          <Route path="/">
            <MapPage showSidebar={showSidebar} />
          </Route>   
        </Switch>

      </div>
  )
}

export default App;
