import React, { useEffect, useState } from 'react'
import uiConfig from '../ui/uiConfig'
import {
    Button,
    CircularProgress,
    TextField,
    Typography
} from '@material-ui/core'
import ClosableDialogTitle from '../ui/ClosableDialogTitle'

import DniReport from '../map/reportSidebar/DniReport'

import { aggregateNaturtypeStats, statType, fetchStats } from '../service/statsService'


const NaturtypeData = props => {
    const { data } = props
    const title = data.naturtype.label
    const area = data.data.reduce((p, c) => p + c.area, 0)
    return (
        <div>
            <span>{title}</span>
            <span>{area}</span>
            <div>
                {JSON.stringify(data.data)}
            </div>
        </div>
    )
}
//5706300, 349403
const SelectedFeature = props => {
    const { selectedFeature, onChangeFeature } = props
    const feature = selectedFeature.features[0]
    const [ boxid, setBoxid ] = useState(feature.attributes.boxid)
    const [ mId, setMId ] = useState(feature.attributes.mId)
    return (
        <div style={{ marginLeft: 80, marginTop: 100}}>
            <Typography variant='body1'>Boxid</Typography>
            <TextField value={boxid} onChange={e => setBoxid(e.target.value)} />
            <Typography variant='body1'>mId</Typography>
            <TextField value={mId} onChange={e => setMId(e.target.value)} />
            <br />
            <Button variant='contained' onClick={() => onChangeFeature({ attributes: { mId, boxid }})}>Update</Button>
        </div>
    )
}

const ReportTestPage = props => {
    const { title, top, fullWidth, expanded } = props

    const [ selectedFeatures, setSelectedFeatures ] = useState([{
        features: [
            {
                attributes: {
                    boxid: 5706300,
                    mId: 349363
                }
            }
        ]
    }])
    
    const [dniData, setDniData] = useState(null)
    const [tilstandData, setTilstandData] = useState(null)
    const [beskyttelseData, setBeskyttelseData] = useState(null)
    const [procesData, setProcesData] = useState(null)
    const [loadingDni, setLoadingDni] = useState(true)
    
    const [error, setError] = useState(false)
    const paddingLeft = expanded ? 16 : 0
    const paddingTop = 8
    const width = expanded ? fullWidth - paddingLeft : 0
    const contentTopMargin = 0
    const heightStyle = 'calc(100vh - ' + contentTopMargin + 'px - ' + top + 'px - ' + 1 * paddingTop + 'px)'
    const transitionStyle = 'all ' + uiConfig.report.animationSpeed + 's'
    //const aggregatedFeatureData = aggregateFeatureData(featureData)    

    useEffect(() => {
        console.log('useEffect')
        setLoadingDni(true)
        setTimeout(async () => {
            if (!selectedFeatures || selectedFeatures.length === 0) {
                setDniData(null)
                return
            }
            try {
                const data = await fetchStats('matrikel', selectedFeatures.map(s => s.features[0].attributes), statType.dni)
                console.log('data', data)
                setDniData(aggregateNaturtypeStats(data, statType.dni))
                setLoadingDni(false)
                setError(false)
                
                const tilstandData = await fetchStats('matrikel', selectedFeatures.map(s => s.features[0].attributes), statType.tilstand)
                setTilstandData(aggregateNaturtypeStats(tilstandData, statType.tilstand))
                const beskyttelseData = await fetchStats('matrikel', selectedFeatures.map(s => s.features[0].attributes), statType.beskyttelse)
                setBeskyttelseData(aggregateNaturtypeStats(beskyttelseData, statType.beskyttelse))
                const procesData = await fetchStats('matrikel', selectedFeatures.map(s => s.features[0].attributes), statType.proces)
                setProcesData(aggregateNaturtypeStats(procesData, statType.proces))
            } catch (error) {
                setError({ code: 'LOAD_ERROR' })
            }
        }, 1)
    }, [ setLoadingDni, setError, selectedFeatures ])
    //    console.log(aggregatedFeatureData)
    return (
        <div>
            <div>
            {selectedFeatures.map(sf => (<SelectedFeature selectedFeature={sf} onChangeFeature={f => setSelectedFeatures([{ features: [f]}])} />))}
            </div>
            <div>
                {dniData && Object.keys(dniData).map(k => (<NaturtypeData data={dniData[k]} />))}
            </div>
        <div style={
            {
                position: 'fixed',
                top,
                right: 0,
                display: 'block',
                minHeight: heightStyle,
                maxHeight: heightStyle,
                transition: transitionStyle,
                paddingLeft: paddingLeft,
                paddingTop: paddingTop,
                overflowY: 'auto',
                overflowX: 'hidden',
                minWidth: width, width, maxWidth: width, backgroundColor: 'white', margin: 0
            }}>
            <ClosableDialogTitle onClose={() => {}}>{title}</ClosableDialogTitle>
            { error ?
                <Typography variant='h4'>Fejl!</Typography>
                :
                (loadingDni ?
                    <CircularProgress />
                    :
                    <DniReport title='Dansk Naturindikator' data={dniData} />)                
            }            
            <DniReport title='Tilstand' data={tilstandData} />
            <DniReport title='Beskyttelse' data={beskyttelseData} />
            <DniReport title='Proces' data={procesData} />
        </div>
        </div>
    )
}
export default ReportTestPage