import React from 'react'
import { useState } from 'react'

import {
    Typography,
    Popover, IconButton, Grid
} from '@material-ui/core'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import {
    FormControl,
    Radio,
    RadioGroup,
    FormControlLabel
} from '@material-ui/core'

//import Layer from 'ol/layer/Layer';


const LayerOption = props => {
    const [showHelp, setShowHelp] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null);
    const { layer, helpEnabled, selected, onChange } = props
    const help = layer.help
    const handleClick = (event) => {
        setShowHelp(true)
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setShowHelp(false)
        setAnchorEl(null)
    }
    const layerData = { id: layer.id, title: layer.title, version: layer.version }
    return (
        <Grid container key={layer.id + '.' + layer.title} spacing={1}>
            <Popover
                open={showHelp}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'left',
                }}
                elevation={16}
            >
                {(help && helpEnabled) ? help(layerData) : null}
            </Popover>
            <Grid item xs={2}>
                {(help && helpEnabled) ? <IconButton style={{ marginLeft: 0, marginTop: -4 }} variant='contained' onClick={handleClick}><HelpOutlineIcon /></IconButton> : null}
            </Grid>
            <Grid item xs={10}>
                <div style={{ minWidth: 250, width: '100%' }}>
                <FormControlLabel key={layer.title} value={layer.title} control={<Radio checked={selected} onChange={(evt, value) => onChange()} color='primary' />}
                    label={<Typography variant="body2" color="textSecondary">{layer.title}</Typography>}
                />
                </div>
            </Grid>
        </Grid>
    )
}


const DniLayerControl = props => {
    const { title, description, layers, helpEnabled, selectedLayers, onSelectedLayersChanged } = props

    const selectedLayer = selectedLayers[0]

    return (<div style={{  marginTop: 8, marginLeft: 0, marginBottom: 16 }}>
        <Typography style={{ paddingLeft: 16, paddingBottom: 8 }} variant='h6'>{title}</Typography>
        { description ? 
            description()
            :
            null
        }
        <FormControl>
            <RadioGroup aria-label="beskyttelseslag" name="beskyttelseslag" value={selectedLayer ? selectedLayer.id : ''} onChange={(evt, value) => {
                console.log(value)
            }}>
                {layers.map(l => {
                    const selected = Boolean(selectedLayer.id === l.id)
                    return (
                        <LayerOption key={l.title} helpEnabled={helpEnabled} layer={l} selected={selected} onChange={() => {
                            if (selected) {
                                onSelectedLayersChanged([l])
                            } else {
                                onSelectedLayersChanged([l])
                            }
                        }} />
                    )
                })}
            </RadioGroup>
        </FormControl>
    </div>)
}

export default DniLayerControl