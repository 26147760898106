//const baseUrl = "https://features.dni.bioskaloe.net/docs/"
import {
    get,
    getText
} from './fetchUtil'

/*
this.fetchRegelsaetToC().then(data => regelsaet.toc = data)
            this.fetchRegelsaetDocs().then(data => regelsaet.content = data)
            this.fetchIkkeArealBundneToC().then(data => ikkeArealBundne.toc = data)
            this.fetchIkkeArealBundneDocs().then(data => ikkeArealBundne.content = data)
            */

class DniDocsService {

    constructor() {
        /* global DNI_CONFIG */
        this.baseUrl = DNI_CONFIG.docsUrl
    }

    setBaseUrl(url) {
        this.baseUrl = url
    }

    async fetchToc() {
        return get(this.baseUrl, 'toc.json')
    }

    async fetchContent(path) {
        return getText(this.baseUrl, path)
    }
}


export default new DniDocsService()