import React from "react";

const Icon = props => {
    const title = props.title || 'Blank'
    const { style, width, height } = props
    return (<svg
        id={title} aria-label={title} width={width}
        height={height}
        style={style}
        data-name={title} xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24">        
    </svg>)
}

export default Icon