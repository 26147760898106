import React from 'react'
import { useEffect, useState, useRef } from 'react'
import { Chart } from 'chart.js'

const WeightedBarChart = props => {
  const [error, setError] = useState(false)
  const myCanvas = useRef(null)
  const myChart = useRef(null)
  const { width, height, labels, weights, data, backgroundColors, borderColors } = props

  const { tooltipLabelFn, tooltipAfterLabelFn } = props

  useEffect(() => {
    if (myChart.current) {
      myChart.current.data.weights = weights
      myChart.current.data.datasets[0].data = data
      myChart.current.update()
      return
    }
    if (error) {
      console.error('Cannot update chart with error')
      return
    }
    try {
      var ctx = myCanvas.current;
      myChart.current = new Chart(ctx, {
        type: 'barw',
        data: {
          labels,
          weights,
          datasets: [{
            data,
            backgroundColor: backgroundColors,
            borderColor: borderColors,
            borderWidth: 1
          }]
        },
        options: {
          scales: {
            y: {
              beginAtZero: true,
              max: 100
            }
          },
          plugins: {
            tooltip: {
              enabled: true,
              callbacks: {
                label: tooltipLabelFn,
                afterLabel: tooltipAfterLabelFn                              
              }
            },
            legend: {
              display: true,
              position: 'bottom',
              fullSize: true
            }
          }
        }
      });
    } catch (error) {
      setError(true)
      console.log(error)
    }
  }, [backgroundColors, borderColors, weights, data, labels, tooltipAfterLabelFn, tooltipLabelFn, error ])

  useEffect(() => {
    return () => {
      myChart.current && myChart.current.destroy()
    }
  }, [])

  return (
    <div style={{ width }}>
      <canvas height={height} ref={myCanvas}></canvas>
    </div>
  )
}

export default WeightedBarChart