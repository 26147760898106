import React from 'react'
import {
    useRef, useState, useEffect
} from 'react'

import { Collapse, CircularProgress, IconButton, Typography } from '@material-ui/core'


import { createWmsSource } from '../ol/olUtils'
import PoiData from './PoiData'
import { PoiDataValue } from './PoiData'
import { ChevronRight, ExpandLess } from '@material-ui/icons'; //ExpandMore
import BlankIcon from '../icons/BlankIcon'
import { defaultMapper } from '../../service/data/dataMappers'

const aborted = {}
const fejl = 'Fejl / Lag ikke oprettet'
const fetchData = async (poi, layer, signal) => {
    const { coordinate, viewResolution, projection } = poi
    const settings = { 'INFO_FORMAT': 'application/json', 'FEATURE_COUNT': 50 }
    const source = createWmsSource(layer.wmtsId)
    const url = source.getFeatureInfoUrl(coordinate, viewResolution, projection, settings)
    try {
        const response = await fetch(url, { signal })
        const json = await response.json()
        if (json.features) {
            return json.features.map(f => f.properties['GRAY_INDEX'])[0]
        } else {
            return 'Ingen data'
        }
    } catch (e) {
        if (e.name === "AbortError") {
            console.log('aborted fetch')
            return aborted
        }
        console.log('Error fetching', layer.wmtsId)
        return fejl
    }
}

const renderExpand = (onClick, canExpand) => {
    if (!canExpand) {
        return (<BlankIcon width={48} height={12} />)
    }
    return <IconButton onClick={() => onClick()}><ChevronRight /></IconButton>
}

const PoiGroupData = props => {
    const { point, layers, title, largeTitle } = props
    const [expanded, setExpanded] = useState(false)
    const [data, setData] = useState([])
    const [score, setScore] = useState(null)
    const isMounted = useRef(false)

    const [controller] = useState(new AbortController())

    useEffect(() => {
        if (isMounted.current || !layers) {
            return
        }
        isMounted.current = true
        if (!point) {
            return
        }
        const { signal } = controller
        setTimeout(async () => {
            let newData = layers.slice(1).map(l => ({ id: l.wmtsId, title: l.title, data: null, rawValue: null}))
            setData(newData)
            const _score = await fetchData(point, layers[0], signal)
            if (_score !== false) {
                const _mapper = layers[0].dataMapper || defaultMapper
                setScore( _mapper(_score))
            }
            for (let i = 1; i < layers.length; i++) {
                const l = layers[i]
                //console.log('layer', i, title, l.wmtsId)
                if (!l.wmtsId) {
                    const tmp = { id: l.title, title: l.title, data: { type: 'string', value: 'Lag ikke oprettet' } }
                    newData = [ ...newData ]
                    newData[i-1] = tmp
                    setData(newData)
                    continue
                }
                const layerData = await fetchData(point, l, signal)
                if (layerData === aborted) {
                    return
                }
                const mappedData = l.dataMapper && layerData !== fejl ? l.dataMapper(layerData) : { type: 'string', value: layerData }
                const tmp = { id: l.wmtsId, title: l.title, data: mappedData, rawValue: layerData }
                newData = [ ...newData ]
                newData[i-1] = tmp
                setData(newData)
            }
        }, 0)

    }, [point, data, layers, controller, setData])

    useEffect(() => {
        return () => {
            controller.abort()
        }
    }, [controller])
    console.log('data', data)
    return (
        <div>
            <Typography variant={largeTitle ? 'h5' : 'h6'}>
                {expanded ? <IconButton onClick={() => setExpanded(false)}><ExpandLess /></IconButton> : renderExpand(() => setExpanded(true), layers.length > 1)}
                {title}{score !== null && `: `}{score !== null ? <PoiDataValue data={score} /> : <CircularProgress />}
            </Typography>
            {largeTitle && (<div style={{ minHeight: 8, height: 8, maxHeight: 8}} />)}
            <Collapse in={expanded}>
                <div style={{ marginLeft: 48 }}>
                    {data.map(d => {
                        return (
                            <PoiData key={d.id} title={d.title} data={d.data} rawValue={d.rawValue} />
                        )
                    })}</div>
            </Collapse>
        </div>
    )
}

export default PoiGroupData