import React from 'react'
import { useState } from 'react'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { Collapse, ListItemIcon } from '@material-ui/core'
//import ListSubheader from '@material-ui/core/ListSubheader';
import { NavLink } from 'react-router-dom';
import { ChevronRight, ExpandLess, ExpandMore } from '@material-ui/icons';

const selectIcon = (childSelected, selected, children, expandSelected) => {
    if (childSelected) {
        return (<ChevronRight />)
    }
    if (!selected || !(children && children.length > 0)) {
        return null
    }
    if (expandSelected) {
        return (<ExpandMore />)
    } else {
        return (<ExpandLess />)
    }    
}

const renderSection = (item, selectedDocs, expandSelected, onClick, parentId) => {
    const selected = item.id === selectedDocs // isExpanded(item, selectedDocs)
    const childSelected = isChildSelected(item, selectedDocs)
    const paddingLeft = 16 * (item.level + 1) //+ (childSelected ? 0 : 48)
    const marginLeft = 0
    //console.log('p', paddingLeft)
    const borderLeft = selected && 'solid #808080 2px'
    const style = { marginLeft, paddingLeft, borderLeft: borderLeft, borderBottom: 'solid #eee 0.5px' }
    if (item.contentPath && item.contentPath.includes("index.html")) {
        item.children.sort((a, b) => a.id.localeCompare(b.id))
    }
    let link = '/dokumentation/'
    let nextParentId;
    if (item.contentPath) {
        link += item.id
        nextParentId = item.id
    } else {
        link += parentId + '#' + item.id
        nextParentId = parentId
    }
    return (
        <React.Fragment key={item.id}>
            <ListItem
                style={style}
                button
                component={NavLink}
                className={selected ? "Mui-selected" : ''}
                to={link}
                onClick={() => onClick(item.id === selectedDocs)}>
                <ListItemIcon>{selectIcon(childSelected, selected, item.children, expandSelected)}</ListItemIcon>
                <ListItemText primary={item.title} />
            </ListItem>
            <Collapse in={childSelected || (selected && expandSelected)} unmountOnExit>
                {item.children.map(h => renderSection(h, selectedDocs, expandSelected, onClick, nextParentId))}                
            </Collapse>
        </React.Fragment>
    )
}

const isChildSelected = (item, selectedMenu) => {
    let result = false
    item.children.forEach(c => {
        result = result || isExpanded(c, selectedMenu)
    })
    //console.log(item, selectedMenu, result)
    return result
}

const isExpanded = (item, selectedMenu) => {
    if (!selectedMenu) {
        return false
    }
    if (item.id === selectedMenu) {
        return true
    }
    const expandedChildren = item.children.filter(c => isExpanded(c, selectedMenu))
    if (expandedChildren.length > 0) {
        return true
    }
    return false
}

const DniDocsToc = props => {
    const { toc, selectedDocs, hashLocation } = props
    const [expandSelected, setExpandSelected] = useState(true)
    if (!toc) {
        return null
    }


    return (
        <List>
            {
                toc.map(entry => renderSection(entry, hashLocation || selectedDocs, expandSelected, (same) => { setExpandSelected(!same || !expandSelected) }))
            }
        </List>
    )
}

export default DniDocsToc