import React, { useEffect } from 'react'
import { Paper } from '@material-ui/core'
import './DniDocs.css'
const scrollToElement = id => {
    if (!id) {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
        return
    }
    const element = document.getElementById(id)
    if (!element) {
        window.scrollTo({ top: 0 })
        return
    }
    var headerOffset = 80;
    var elementPosition = element.getBoundingClientRect().top + window.pageYOffset;
    var offsetPosition = elementPosition - headerOffset;
    window.scrollTo({
        top: offsetPosition,
    });
}
// window.scrollTo ({top: offsetPosition, behavior: "smooth"})



const DniDocs = props => {
    const { content, hashLocation } = props
    useEffect(() => {
        if (!content) {
            return
        }
        scrollToElement(hashLocation)
    }, [content, hashLocation])
    
    if (!content) {
        return null
    }
    
    return (
        <div style={{ marginTop: 80, marginBottom: 16, paddingBottom: 8 , maxWidth: 900, marginRight: 'auto', marginLeft: 'auto' }}>
            <Paper elevation={3} style={{ paddingBottom: 24, paddingTop: 4, paddingLeft: 32, paddingRight: 32, marginRight: 16, marginLeft: 16 }}>           
                <div className='toc_content' dangerouslySetInnerHTML={{ __html: content }}>
                </div>
            </Paper>
        </div>
    )
}

export default DniDocs