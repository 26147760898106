import matrikelLayer from './vector/matrikelLayer'
import {
    kommuneLayer,
    nationalparkLayer,
    fuglebeskyttelsesLayer,
    habitatOmraadeLayer
} from './vector/areaOfInterestLayerHandlers'
//import { mose } from '../../service/data/layerColors'
/*
    ,
    ,
*/

export const isAoiLayerActive = (zoom, layer) => {
    if (!layer) {
        return false
    }
    if (layer.disableDrawControls) {
        return false
    }
    if (!layer.zoom) {
        return true
    }
    return layer.zoom.min < zoom && layer.zoom.max > zoom
}

const createLayer = (title, featureType, handler, zoom, disableDrawControls) => ({title, featureType, handler, zoom, disableDrawControls})

const nullHandler = {
    setVisible: () => {},
    clear: () => {},
    update: () => {},
    getFeature: () => (null),
    getOverlappingGeometries: () => ({ features: [] }),
    layerGroup: null
}


/*    createLayer('Nationalparker', nationalparkLayer, { min: 0, max: 17 } ),
    createLayer('Habitatområder', habitatOmraadeLayer, { min: 0, max: 18 } ),
    createLayer('Fuglebeskyttelsesområder', fuglebeskyttelsesLayer, { min: 0, max: 18 } ),
*/
const aoiLayers = [  
    createLayer('Ingen', null, nullHandler, { min: 0, max: 1000 }, true),
    createLayer('Matrikler', 'matrikel', matrikelLayer, { min: 13, max: 1000 }),
    createLayer('Kommuner', 'kommuner', kommuneLayer, { min: 0, max: 17 }),
    createLayer('Nationalparker', 'nationalparker', nationalparkLayer, { min: 0, max: 17 }),
    createLayer('N2000 - Habitatområder', 'habitat', habitatOmraadeLayer, { min: 0, max: 17 }),
    createLayer('N2000 - Fuglebeskyttelse', 'fuglebeskyttelse', fuglebeskyttelsesLayer, { min: 0, max: 17 })

]

export default aoiLayers
