import GeoJSON from 'ol/format/GeoJSON';

import {
    point
} from '@turf/helpers'
import booleanPointInPolygon from '@turf/boolean-point-in-polygon'
import booleanOverlap from '@turf/boolean-overlap';
import booleanWithin from '@turf/boolean-within';

export const refresh = (layerGroup, delay) => {
    setTimeout(() => layerGroup.changed(), delay)
}

export const getOverlappingGeometries = (polygon, layerGroup) => {
    const geoJson = new GeoJSON({
        featureProjection: "EPSG:28532"
    })
    const a = geoJson.writeFeatureObject(polygon)
    /*const overlapping = {
        type: 'FeatureCollection',
        features: []
    }*/
    const result = []
    const unMatchedFeatures = []
    layerGroup.getLayers().getArray().forEach(layer => {
        if (!layer.getSource().featureCollection) {
            return
        }
        layer.getSource().featureCollection.features.forEach(f => {
            //const b = geoJson.writeFeatureObject(f)
            const b = f
            if (!Boolean(result.find(t => t.id === f.attributes.mId)) && (booleanOverlap(a, b) || booleanWithin(b, a))) {
                result.push({
                    id: f.attributes.mId,
                    features: [f]
                })
            } else {
                unMatchedFeatures.push(f)
            }
            //console.log('feature', f.getGeometry())
        })
    })
    result.forEach(f => {

        f.features = f.features.concat(unMatchedFeatures.filter(uf => f.id === uf.attributes.mId))
    })
    return result
}

export const getFeatureFromCoordinate = (coordinate, layerGroup) => {
    const pt = point(coordinate)
    let result = []
    const unMatchedFeatures = []
    layerGroup.getLayers().getArray().forEach(layer => {
        if (!layer.getSource().featureCollection) {
            return
        }
        layer.getSource().featureCollection.features.forEach(f => {
            if (booleanPointInPolygon(pt, f)) {
                result.push({
                    id: f.attributes.mId,
                    features: [f]
                })
            } else {
                unMatchedFeatures.push(f)
            }
        })
    })
    //console.log(result)    
    result.forEach(f => {
        f.features = f.features.concat(unMatchedFeatures.filter(uf => f.id === uf.attributes.mId))
    })

    //console.log(result)
    if (result.length === 0) {
        return null
    }
    if (result.length === 1) {
        return result[0]
    } else {
        // todo
        console.log('***', result)
        return result[0]
    }
}