import { Tooltip } from '@material-ui/core'
import React from 'react'
import uiConfig from '../../ui/uiConfig'
import {
    IconButton
} from '@material-ui/core'
//import PanToolIcon from '@material-ui/icons/PanTool'; 
import HandIcon from '../icons/HandIcon'
import QueryIcon from '../icons/QueryIcon'
import PolygonIcon from '../icons/PolygonIcon'
import ToggleFeatureIcon from '../icons/ToggleFeatureIcon'
import TrashIcon from '../icons/TrashIcon'
import '../controls/DrawControl.css'

const createContainerStyle = position => ({
    padding: 2,
    borderRadius: 3,
    borderColor: '#ccc',
    borderStyle: 'solid',
    borderWidth: 1,
    backgroundColor: 'rgba(240, 240, 240, 0.8)',
    position: 'fixed',
    cursor: 'pointer',
    top: position.top,
    right: position.right,
    transition: 'all ' + uiConfig.sidebar.animationSpeed + 's'
})

const ControlButton = props => {
    const iconSize = 24    
    const mapIcon = (color, id) => {
        if (id === 'hand') {
            return (<HandIcon color={color} size={iconSize} />)
        } else if (id === 'eyedropper') {
            return (<QueryIcon color={color} size={iconSize} />)
        } else if (id === 'polygon') {
            return (<PolygonIcon color={color} size={iconSize} />)
        } else if (id === 'paintbrush') {
            return (<ToggleFeatureIcon color={color} size={iconSize} />)
        } else if (id === 'trash') {
            return (<TrashIcon color={color} size={iconSize} />)
        }
        return null
    }

    const { id, selectedButton, disabled, tooltip, onClick } = props
    const selected = id === selectedButton
    const color = disabled ? 'disabled' : selected ? 'primary' : 'dark-gray'
    //console.log(id, selected, selectedButton, color)
    return (
        <div>
            {disabled ?
                <IconButton onClick={() => onClick(id)} disabled={disabled}>
                    {mapIcon(color, id)}
                </IconButton> :
                <Tooltip title={tooltip}>
                    <IconButton onClick={() => onClick(id)} disabled={disabled}>
                        {mapIcon(color, id)}
                    </IconButton>
                </Tooltip>}
        </div>
    )
}




class ExternalDrawControl extends React.Component {
    constructor(props) {
        super()
        this.state = {
            selectedButton: 'hand'
        }
    }

    mapClicked = (evt, viewResolution, projection) => {
        const selectedButton = this.state.selectedButton
        if (selectedButton === 'hand' || selectedButton === 'polygon') {
            return
        } else if (selectedButton === 'eyedropper') {
            console.log(evt, evt.coordinate)
            this.props.onQueryCoordinate(evt.coordinate, viewResolution, projection)
        } else if (selectedButton === 'paintbrush') {
            if (this.props.selectedAoiLayer) {
                const feature = this.props.selectedAoiLayer.handler.getFeature(evt.coordinate, viewResolution, projection)
                console.log(feature)
                if (feature) {
                    this.props.onFeatureToggled(feature)
                }
            }
        }
    }

    setSelectedButton = btn => {
        //console.log(btn, this.state)
        if (btn === 'polygon') {
            this.props.onCreateDrawInteraction()
            this.props.setMapCursor('none')
        } else {
            this.props.onDestroyDrawInteraction()
 
        }
        if (btn !== 'hand' && btn !== 'trash') {
            this.props.onDisableDoubleClickZoom()
        }
        if (btn === 'hand') {
            this.props.setMapCursor('grab')
            this.props.onEnableDoubleClickZoom()
        } else if (btn === 'eyedropper') {
            this.props.setMapCursor('help')
        } else if (btn === 'paintbrush') {
            this.props.setMapCursor('pointer')
        }
        this.setState({
            ...this.state,
            selectedButton: btn
        })
    }

    componentDidUpdate(prevProps) {
        const { aoiLayerActive } = this.props
        const { selectedButton } = this.state
        if (!aoiLayerActive && (selectedButton === 'polygon' || selectedButton === 'paintbrush')) {
            this.setSelectedButton('hand')
        }
    }

    render() {
        const { aoiLayerActive, selectedFeatures, position, onClearAll } = this.props
        const { selectedButton } = this.state
        
        const style = createContainerStyle(position)
        return (
            <div style={style}>
                <ControlButton
                    id='hand'
                    tooltip='Panorer kort'
                    selectedButton={selectedButton}
                    onClick={id => this.setSelectedButton(id)}
                />
                <ControlButton
                    id='eyedropper'
                    tooltip='Info om punkt'
                    selectedButton={selectedButton}
                    onClick={id => this.setSelectedButton(id)}
                />
                <ControlButton
                    id='polygon'
                    tooltip='Vælg områder med polygon'
                    selectedButton={selectedButton}
                    disabled={!aoiLayerActive}
                    onClick={id => this.setSelectedButton(id)}
                />
                <ControlButton
                    id='paintbrush'
                    tooltip='Vælg enkelte områder til/fra'
                    selectedButton={selectedButton}
                    disabled={!aoiLayerActive}
                    onClick={id => { this.setSelectedButton(id) }}
                />
                <ControlButton
                    id='trash'
                    tooltip='Nulstil valgte områder'
                    selectedButton={selectedButton}
                    disabled={!selectedFeatures || selectedFeatures.length === 0}
                    onClick={() => onClearAll()}
                />
            </div>
        )
    }
}

export default ExternalDrawControl