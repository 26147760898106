import {
    createMuiTheme
} from '@material-ui/core/styles'

export default createMuiTheme({
    palette: {
        primary: {
            main: '#003D73',
            dark: '#002546'
        },
        secondary: {
            light: '#8BAD3F',
            main: '#425821'
        },
    },
    typography: {
        body1: {
            lineHeight: 1.4
        }
    },
    overrides: {
        MuiTabs: {
            indicator: {
                backgroundColor: '#8BAD3F'
            },
        }
    }
});