import {
    mose, hede, eng, overdrev, strandeng, soe, vandloeb, booleanColors
} from './layerColors'
import layerTypes from './layerTypes'
import { 
    naturbeskyttelsesloven, 
    fredningskendelser, 
    beskyttelseslinjer,
    skovlovenHelp,
    vandlobslovenHelp,
    okkerlovenHelp,
    miljobeskyttelseslovenHelp,
    husdyrbrugsreguleringenHelp,
    kommuneplansretningslinjerHelp,
    natura2000Help,
    soervandlobHelp,
    nationalparkerHelp,
    stoetteordningerHelp,
    biodiversitetsskovHelp,
    vandforsyningslovenHelp,
    reguleringafgodvingsanvendelseHelp,
    pesticidreguleringHelp,
    bilagIvHelp,
    vildtforvaltningsreglerHelp,
    urortskovHelp,
    raastoflovenHelp
 } from '../../map/layers/help/helpBeskyttelse'

 import {
     bitMapper,
     booleanMapper
 } from './dataMappers'


const layers = [
    {
        title: 'Beskyttede naturtyper',
        type: layerTypes.layer,                     
        id: 'r01_07_naturbeskyttelsesloven',
        legend: [
            { title: 'Hede', color: hede },
            { title: 'Mose', color: mose },
            { title: 'Eng', color: eng },
            { title: 'Overdrev', color: overdrev },
            { title: 'Strandeng', color: strandeng },
            { title: 'Sø', color: soe },
            { title: 'Vandløb', color: vandloeb }
        ],
        help: naturbeskyttelsesloven,
        dataMapper: bitMapper('Hede', 'Mose', 'Strandeng', 'Eng', 'Overdrev', 'Sø', 'Vandløb')
    },
    {
        title: 'Fredningskendelser',
        type: layerTypes.layer,                     
        id: 'r08_fredningskendelser',
        legend: [
            { title: 'Fredningskend. - sprøjt.- og gødsk.-forbud', color: booleanColors[0] }
        ],
        help: fredningskendelser,
        dataMapper: booleanMapper
    },
    {
        title: 'Beskyttelseslinjer',
        type: layerTypes.layer,                     
        id: 'r09_12_beskyttelseslinjer',
        legend: [
            { title: 'Klit', color: booleanColors[0] },
            { title: 'Strand', color: booleanColors[1] },
            { title: 'Sø og å', color: soe },
            { title: 'Fortidsminde', color: booleanColors[2] },
            { title: 'Flere', color: booleanColors[3] }            
        ],
        help: beskyttelseslinjer,
        dataMapper: bitMapper('Klit', 'Strand', 'Sø og å', 'Fortidsminde')
    },
    {
        title: 'Skovloven',
        type: layerTypes.layer,                     
        id: 'r13_16_skovloven',
        legend: [
            { title: 'Fredskov', color: booleanColors[0] },
            { title: '§ 25, 26, 28', color: booleanColors[1] },
            { title: 'Flere', color: booleanColors[2] }
        ],
        help: skovlovenHelp,
        dataMapper: bitMapper('Fredskov', '§ 25, 26, 28')
    },
    {
        title: 'Vandløbsloven',
        type: layerTypes.layer,                     
        id: 'r17_18_vandloebsloven',
        legend: [
            { title: 'Sø og vandløb med bræmmer', color: booleanColors[0] },
            { title: 'Øvrige søer og vandløb', color: booleanColors[1] },
            { title: 'Flere', color: booleanColors[2] }
        ],
        help: vandlobslovenHelp,
        dataMapper: bitMapper('Sø og vandløb med bræmmer', 'Øvrige søer og vandløb')
    },
    {
        title: 'Okkerloven',
        type: layerTypes.layer,                     
        id: 'r19_okker',
        legend: [
            { title: 'Okkerlovens - dræningstilladelse', color: booleanColors[0] }
        ],
        help: okkerlovenHelp,
        dataMapper: booleanMapper
    },
    {
        title: 'Vandforsyningsloven',
        type: layerTypes.layer,                     
        id: 'r20_vandforsyningsloven',
        legend: [
            { title: 'Vandforsyningsloven - indvindingstilladelse', color: booleanColors[0] }
        ],
        help: vandforsyningslovenHelp,
        dataMapper: booleanMapper
    },
    {
        title: 'Miljøbeskyttelsesloven',
        type: layerTypes.layer,                     
        id: 'r21_udledningstilladelse_soer',
        legend: [
            { title: 'Miljøbeskyttelsesloven', color: booleanColors[0] }
        ],
        help: miljobeskyttelseslovenHelp,
        dataMapper: booleanMapper
    },
    {
        title: 'Råstofloven',
        type: layerTypes.layer,
        id: 'r22_raastofloven_krav_raastoftilladelse',
        legend: [
            { title: 'Råstoflovens krav om råstoftilladelse', color: booleanColors[0] }
        ],
        help: raastoflovenHelp,
        dataMapper: booleanMapper
    },
    {
        title: 'Jagt- og vildtforvaltningsreglerne',
        type: layerTypes.layer,
        id: 'r23_24_jagt_vildt_regler',
        legend: [
            { title: 'Vildtreservater', color: booleanColors[0] },
            { title: 'Øvrige Danmark', color: booleanColors[1] }
        ],
        help: vildtforvaltningsreglerHelp,
        dataMapper: bitMapper('Øvrige Danmark', 'Vildtreservater')
    },
    {
        title: 'Regulering af gødningsanvendelse',
        type: layerTypes.layer,                     
        id: 'r26_goedningsanvendelse',
        legend: [
            { title: 'Regulering af gødningsanvendelse', color: booleanColors[0] }
        ],
        help: reguleringafgodvingsanvendelseHelp,
        dataMapper: booleanMapper
    },
    {
        title: 'Husdyrbrugsreguleringen',
        type: layerTypes.layer,                     
        id: 'r27_29_husdyrbrugsreguleringen',
        legend: [
            { title: 'Kategori 1 natur', color: booleanColors[0] },
            { title: 'Kategori 2 natur', color: booleanColors[1] },
            { title: 'Kategori 3 natur', color: booleanColors[2] },
            { title: 'Flere', color: booleanColors[3] }
        ],
        help: husdyrbrugsreguleringenHelp,
        dataMapper: bitMapper('Kategori 1 natur', 'Kategori 2 natur', 'Kategori 3 natur')
    },
    {
        title: 'Pesticidregulering',
        type: layerTypes.layer,                     
        id: 'r30_pesticidregulering',
        legend: [
            { title: 'Pesticidregulering', color: booleanColors[0] }
        ],
        help: pesticidreguleringHelp,
        dataMapper: booleanMapper
    },
    {
        title: 'Kommuneplanretningslinjer',
        type: layerTypes.layer,                     
        id: 'r31_32_kommuneplansretningslinjer',
        legend: [
            { title: 'Særlige naturområder, Økoforbind...', color: booleanColors[0] },
            { title: 'Områder hvor skovrejsning er uønsket', color: booleanColors[1] },
            { title: 'Flere', color: booleanColors[2] }
        ],
        help: kommuneplansretningslinjerHelp,
        dataMapper: bitMapper('Særlige naturområder, Økoforbind...', 'Områder hvor skovrejsning er uønsket' )
    },
    {
        title: 'Natura 2000',
        type: layerTypes.layer,                     
        id: 'r33_34_N2000',
        legend: [
            { title: 'Habitatnatur', color: booleanColors[0] },
            { title: 'Ikke habitatnatur', color: booleanColors[1] }
        ],
        help: natura2000Help,
        dataMapper: bitMapper('Habitatnatur', 'Ikke habitatnatur')
    },
    {
        title: 'Bilag IV',
        type: layerTypes.layer,                     
        id: 'r35_bilagIV',
        legend: [
            { title: 'Bilag IV', color: booleanColors[0] },
        ],
        help: bilagIvHelp,
        dataMapper: booleanMapper
    },
    {
        title: 'Miljømål for søer og vandløb',
        type: layerTypes.layer,                     
        id: 'r36_37_miljoemaal',  
        legend: [
            { title: 'Målsatte vandløb', color: booleanColors[0] },
            { title: 'Målsatte søer', color: booleanColors[1] },
            { title: 'Målsatte søer og vandløb', color: booleanColors[2] }
        ],
        help: soervandlobHelp,
        dataMapper: bitMapper('Målsatte vandløb', 'Målsatte søer')
    },
    {
        title: 'Nationalparker',
        type: layerTypes.layer,                     
        id: 'r38_nationalparker',  
        legend: [
            { title: 'Nationalparker', color: booleanColors[0] },
        ],
        help: nationalparkerHelp,
        dataMapper: booleanMapper
    },
    {
        title: 'Støtteordninger',
        type: layerTypes.layer,                     
        id: 'r39_44_stoetteordninger',
        legend: [
            { title: 'Aftaler om lavbunds- og vådområde- projekter', color: booleanColors[0] },
            { title: 'Aftaler om sikring af bevarings- værdige egekrat', color: booleanColors[1] },
            { title: 'Aftaler om urørt skov', color: booleanColors[2] },
            { title: 'Aftaler om bevaring af træer til naturligt henfald', color: booleanColors[3] },
            { title: 'Aftaler om genetablering af naturlige vandstandsforhold', color: booleanColors[4] },
            { title: 'Sammenhængende arealer i NATURA 2000', color: booleanColors[5] },
            { title: 'Flere aftaler er gældende', color: booleanColors[6] }
        ],
        help: stoetteordningerHelp,
        dataMapper: bitMapper(
             'Aftaler om lavbunds- og vådområde- projekter',
             'Aftaler om sikring af bevarings- værdige egekrat',
             'Aftaler om urørt skov',
             'Aftaler om bevaring af træer til naturligt henfald',
             'Aftaler om genetablering af naturlige vandstandsforhold',
             'Sammenhængende arealer i NATURA 2000'
            )
    },
    {
        title: 'Urørt skov',
        type: layerTypes.layer,                     
        id: 'r45_46_uroertStatsskov',  
        legend: [
            { title: 'Eksisterende urørt skov', color: booleanColors[0] },
            { title: 'Fremtidig urørt skov (2026, 2040 & 2066)', color: booleanColors[1] },
            { title: 'Eksisterende og fremtidig urørt skov', color: booleanColors[2] }
        ],
        help: urortskovHelp,
        dataMapper: booleanMapper
    },
    {
        title: 'Biodiversitetsskov',
        type: layerTypes.layer,                     
        id: 'r47_biodiversitetsskov',  
        legend: [
            { title: 'Biodiversitetsskov', color: booleanColors[0] }
        ],
        help: biodiversitetsskovHelp,
        dataMapper: booleanMapper
    }    
]

export default layers
