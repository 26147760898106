import GeoJSON from 'ol/format/GeoJSON';
import VectorImageLayer from 'ol/layer/VectorImage';
import {
    Vector
} from 'ol/source';
import {
    Fill,
    Stroke,
    Style,
} from 'ol/style'
import union from '@turf/union';

const sf = function () {
    // Generate a canvasPattern with two circles on white background
    var pattern = (function () {
        var canvas = document.createElement('canvas');
        var context = canvas.getContext('2d');
        const sizeX = 18
        const sizeY = 12
        canvas.width = sizeX
        canvas.height = sizeY
        // white background
        // outer circle
        context.lineWidth = 1
        context.strokeStyle = 'rgba(102, 102, 102, 0.25)';
        context.fillStyle = 'rgba(102, 102, 102, 0.25)';
        context.beginPath();
        context.moveTo(0, 0);
        context.lineTo(sizeX, sizeY);
        context.stroke();
        return context.createPattern(canvas, 'repeat');
    })();

    var pattern2 = (function () {
        var canvas = document.createElement('canvas');
        var context = canvas.getContext('2d');
        const sizeX = 9
        const sizeY = 6
        canvas.width = sizeX
        canvas.height = sizeY
        // white background
        // outer circle
        context.lineWidth = 1
        context.strokeStyle = 'rgba(102, 102, 102, 0.25)';
        context.fillStyle = 'rgba(102, 102, 102, 0.25)';
        context.beginPath();
        context.moveTo(0, 0);
        context.lineTo(sizeX, sizeY);
        context.stroke();
        return context.createPattern(canvas, 'repeat');
    })();

    /*const styleFilled = new Style({
        fill: new Fill({
            color: 'rgba(128, 128, 128, 0.1)',
        }),
        stroke: new Stroke({
            color: '#000000',
            width: 2,
        })
    })*/

    const styleUpdating = new Style({
        fill: new Fill({
            color: pattern,
        }),
        stroke: new Stroke({
            color: '#A08000',
            width: 1,
        })
    })

    const styleHatch = new Style({
        fill: new Fill({
            color: pattern,
        }),
        stroke: new Stroke({
            color: '#E0A000',
            width: 1.5,
        })
    })

    const styleHatch2 = new Style({
        fill: new Fill({
            color: pattern2,
        }),
        stroke: new Stroke({
            color: '#d32f2f',
            width: 1.5,
        })
    })

    const selectedFeaturesSource = new Vector({
        format: new GeoJSON({
            featureProjection: "EPSG:28532"
        }),
        overlaps: false,
        loader: (extent, resolution, projection) => {}
    })

    const selectedFeaturesLayer = new VectorImageLayer({
        visible: true,
        source: selectedFeaturesSource,
        style: function (feature) {
            if (feature.attributes.isLarge) {
                return styleHatch2
            }
            return styleHatch;
        },
    })

    let currentSetFeaturesId = 0

    const _setFeatures = (_features) => {
        const features = _features.reduce((p, c) => {
            return p.concat(c.features)
        }, [])
        const myId = Date.now()
        currentSetFeaturesId = myId
        if (features.length === 0) {
            selectedFeaturesSource.clear(true)
            selectedFeaturesLayer.changed()
            return
        }
        if (features.length > 20 || features[0].attributes.isLarge) {
            const featureCollection = {
                type: 'FeatureCollection',
                features
            }
            selectedFeaturesLayer.setStyle(function (feature) {
                return styleUpdating
            })
            selectedFeaturesSource.clear(true)
            selectedFeaturesSource.addFeatures(selectedFeaturesSource.getFormat().readFeatures(featureCollection))
            selectedFeaturesLayer.changed()
        }
        // TODO 

        if (!features[0].attributes.isLarge) {
            setTimeout(async () => {
                const combine = (f1, f2) => {
                    return new Promise((resolve, reject) => {
                        setTimeout(() => {
                            try {
                                let res = union(f1, f2)
                                resolve(res)
                            } catch (err) {
                                console.log(err)
                                reject({})
                            }
                        }, 0)
                    })
                }
                //console.log('combining selected features', features)
                let tmp = features[0]
                let isLarge = tmp.attributes.isLarge
                for (var i = 1; i < features.length && currentSetFeaturesId === myId; i++) {
                    try {
                        tmp = await combine(tmp, features[i])
                    } catch (e) {
                        console.log('caught', e)
                        selectedFeaturesSource.clear(true)
                        selectedFeaturesLayer.setStyle(function (feature) {
                            return isLarge ? styleHatch2 : styleHatch
                        })
                        features.forEach(f => selectedFeaturesSource.addFeatures(selectedFeaturesSource.getFormat().readFeatures(f)))
                        selectedFeaturesLayer.changed()
                        return
                    }
                }
                if (currentSetFeaturesId !== myId) {
                    return
                }

                selectedFeaturesSource.clear(true)
                selectedFeaturesLayer.setStyle(function (feature) {
                    return isLarge ? styleHatch2 : styleHatch
                })
                selectedFeaturesSource.addFeatures(selectedFeaturesSource.getFormat().readFeatures(tmp))
                selectedFeaturesLayer.changed()
            }, 10)
            /*setTimeout(() => {
                const combinedFeatures = union(...features)
                console.log(combinedFeatures)
                selectedFeatures = features
                selectedFeaturesSource.clear(true)
                selectedFeaturesSource.addFeatures(selectedFeaturesSource.getFormat().readFeatures(combinedFeatures))
                selectedFeaturesLayer.changed()
            }, 0)*/
        }
    }

    return {
        source: selectedFeaturesSource,
        layer: selectedFeaturesLayer,
        clear: () => {
            _setFeatures([])
        },
        setFeatures: _setFeatures
    }
}()

export default sf