import { BarController } from 'chart.js'
import { isNullOrUndef } from 'chart.js/helpers';

function isFloatBar(custom) {
    return custom && custom.barStart !== undefined && custom.barEnd !== undefined;
}

class WeightedBarController extends BarController {
    draw() {
        super.draw(arguments)
    }

    updateElements(bars, start, count, mode) {
        const me = this;
        const reset = mode === 'reset';
        const vScale = me._cachedMeta.vScale;
        const base = vScale.getBasePixel();
        const horizontal = vScale.isHorizontal();
        const ruler = me._getRuler();
        const firstOpts = me.resolveDataElementOptions(start, mode);
        const sharedOptions = me.getSharedOptions(firstOpts);
        const includeOptions = me.includeOptions(mode, sharedOptions);
        const weights = me.chart.data.weights
        const total = weights.reduce((p, c) => p + c, 0)
        me.chart.data.weightsPct = weights.map(w => w / total)


        me.updateSharedOptions(sharedOptions, mode, firstOpts);
        const ipixelsArray = me._calculateAllBarIndexPixels(weights, ruler.scale)
        for (let i = start; i < start + count; i++) {
            const parsed = me.getParsed(i);
            const vpixels = reset || isNullOrUndef(parsed[vScale.axis]) ? { base, head: base } : me._calculateBarValuePixels(i);
            const ipixels = ipixelsArray[i] // me._calculateBarIndexPixels(0, i, ruler);
            let ymod = 0
            if (Math.abs(vpixels.base - vpixels.head) < 2) {
                ymod = -1
            }
            const properties = {
                horizontal,
                base: vpixels.base,
                x: horizontal ? vpixels.head : ipixels.center,
                y: horizontal ? ipixels.center : vpixels.head + ymod,
                height: horizontal ? ipixels.size : undefined,
                width: horizontal ? undefined : ipixels.size
            };

            if (includeOptions) {
                properties.options = sharedOptions || me.resolveDataElementOptions(i, mode);
            }
            me.updateElement(bars[i], i, properties, mode);
        }
    }

    _calculateAllBarIndexPixels(weights, scale) {
        var fullWidth = scale.right - scale.left
        var total = weights.reduce((m, x) => m + x, 0)
        let positions = [scale.left]
        for (let i = 0; i < weights.length - 1; i++) {
            let width = Math.round(fullWidth * weights[i] / total)
            positions.push(positions[i] + width)
        }
        positions.push(scale.right)
        let iPixels = []
        for (let i = 0; i < weights.length; i++) {
            const w = positions[i + 1] - positions[i]
            const size = w
            const base = positions[i]
            const center = positions[i] + (w / 2)
            const head = base + w
            iPixels.push({ size, base, center, head })
        }
        return iPixels
    }

    getLabelAndValue(index) {
        const me = this;
        const meta = me._cachedMeta;
        const { iScale, vScale } = meta;
        const parsed = me.getParsed(index);
        const custom = parsed._custom;
        const value = isFloatBar(custom)
            ? '[' + custom.start + ', ' + custom.end + ']'
            : '' + vScale.getLabelForValue(parsed[vScale.axis]);
        //console.log(index)
        return {
            label: iScale.getLabelForValue(index),
            value
        };
        // + iScale.getLabelForValue(parsed[iScale.axis]),
    }
}

WeightedBarController.id = 'barw';
WeightedBarController.defaults = BarController.defaults;
WeightedBarController.overrides = {
    interaction: {
        mode: 'index'
    },

    scales: {
        _index_: {
            type: 'linearlabel',
            offset: false,
            min: 0,
            max: 100,
            grid: {
                offset: false
            }
        },
        _value_: {
            type: 'linear',
            beginAtZero: true,
        }
    },

    plugins: {
        legend: {
            labels: {
                generateLabels(chart) {
                    const data = chart.data;
                    if (data.labels.length && data.datasets.length) {
                        return data.labels.map((label, i) => {
                            const meta = chart.getDatasetMeta(0);
                            const style = meta.controller.getStyle(i);

                            return {
                                text: label,
                                fillStyle: style.backgroundColor,
                                strokeStyle: style.borderColor,
                                lineWidth: style.borderWidth,
                                hidden: !chart.getDataVisibility(i),

                                // Extra data used for toggling the correct item
                                index: i
                            };
                        });
                    }
                    return [];
                }
            },
            onClick(e, legendItem, legend) {
                legend.chart.toggleDataVisibility(legendItem.index);
                legend.chart.update();
            }
        }
    }
}

export default WeightedBarController
