const uiConfig = {
    colors: {
        primary: {
            main: '#003D73',
            dark: '#002546'
        },
        secondary: {
            light: '#8BAD3F',
            main: '#425821'
        },
        gray: {
            light: '#eee',
            main: '#888',
            dark: '#444'
        }
    },
    sidebar: {
        width: 380,
        padding: 16,
        animationSpeed: 0.25,
        collapsed: {
            width: 64,
            padding: 0
        }
    },
    report: {
        width: 440,
        animationSpeed: 0.25
    },
    map: {
        dniLayer: {
            opacity: 1
        }
    }
}

export default uiConfig