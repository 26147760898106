import { naturtypeId2Obj } from '../map/reportSidebar/reportNaturtypeMapper'
import { get, safeget } from './fetchUtil'
import { objectKeyMap, sum, groupBy } from './utils'

const baseUrl = 'https://features.dni.bioskaloe.net/report'
const matrikelStatsUrl = '/matrikel'
const statsUrl = {
    'kommuner': '/kommuner',
    'matrikel': '/matrikel',
    'nationalparker': '/nationalparker',
    'habitat': '/habitat_omr',
    'fuglebeskyttelse': '/fugle_bes_omr'
}

export const statType = {
    dni: { path: 'dni', scoreProperty: 'dni' },
    beskyttelse: { path: 'beskyttelse', scoreProperty: 'score' },
    proces: { path: 'proces', scoreProperty: 'score' },
    tilstand: { path: 'tilstand', scoreProperty: 'score' }
}


const boxCache = {
    'dni': [],
    'beskyttelse': [],
    'proces': [],
    'tilstand': []
}

const maxCacheSize = 10

const versions = {
}

/* global DNI_CONFIG */
const versionFile = `${DNI_CONFIG.environment}-version.json`

export const fetchStats = async (featureType, features, statType) => {
    if (!versions[featureType]) {
        const data = await get(baseUrl, encodeURIComponent(statsUrl[featureType]) + '/' + versionFile)
        versions[featureType] = data
    }
    const versionObj = versions[featureType].find(e => e.stattype === statType.path)
    if (!versionObj) {
        console.log('ERROR: no version for statType', statType)
        console.log('versions', versions)
        return []
    }
    const version = versionObj.version
    if (featureType === 'matrikel') {
        return fetchMatrikelStats(features, statType, version)
    } else {        
        let full = []
        for (let i = 0; i < features.length; i++) {
            const path = encodeURIComponent(statsUrl[featureType]) + '/' + encodeURIComponent(statType.path) + '_' + encodeURIComponent(version) + '/id-' + encodeURIComponent(features[i].mId + '.json')            
            const data = await get(baseUrl, path)
            full = full.concat(data[features[i].mId])
        }
        return full
    }

}

export const fetchMatrikelStats = async (matrikler, statType, version) => {
    //console.log('fetching', matrikler)
    const boxes = matrikler.reduce((total, c) => {
        if (!Boolean(total.find(id => id === c.boxid))) {
            return total.concat([c.boxid])
        } else {
            return total
        }
    }, [])
    //console.log(boxes)
    let matrikelData = {}
    for (var i = 0; i < boxes.length; i++) {
        try {
            const boxid = boxes[i]
            const cacheHit = boxCache[statType.path].find(bc => bc.boxid === boxid)
            if (!cacheHit) {
                const boxFilename = 'boxid_' + boxid + '.json';
                const path = matrikelStatsUrl + '/' + statType.path + "_" + version + '/' + boxFilename
                const { data, error } = await safeget(baseUrl, path)
                if (error) {
                    console.log('Error getting box: ' + path)
                }
                if (data) {
                    boxCache[statType.path].push({ boxid, data })
                    matrikelData = {
                        ...matrikelData,
                        ...data
                    }
                } else {
                    console.log('warn: no data', boxes[i])
                }
            } else {
                matrikelData = {
                    ...matrikelData,
                    ...cacheHit.data
                }
            }
        } catch (err) {
            console.log('error fetching box', boxes[i], err)
            return []
        }
    }
    if (boxCache[statType.path].length > maxCacheSize) {
        console.log('boxcache', boxCache)
        boxCache[statType.path].splice(0, boxCache[statType.path].length - maxCacheSize)
    }
    return matrikler.map(matrikel => matrikelData[matrikel.mId]).filter(matrikel => matrikel).flat()
}

export const aggregateNaturtypeStats = (_data, statType) => {
    const scoreProperty = statType.scoreProperty
    let data = _data.filter(e => e.a > 0 && !isNaN(parseInt(e[scoreProperty]))).map(e => ({ dni: parseInt(e[scoreProperty]), nt: e.nt, area: parseFloat(e.a) }))
    data = groupBy(data, e => e.nt)
    //console.log(objectKeyMap, sum)
    //console.log('_data', _data)
    //console.log(data)
    return objectKeyMap(data, (key, values) => {
        let filteredValues = values.filter(v => !isNaN(v.dni))
        return {
            naturtype: naturtypeId2Obj(key),
            data: filteredValues,
            area: sum(filteredValues, e => e.area),
            sum: sum(filteredValues, e => e.dni * e.area)
        }
    })
}
