import {
    beskyttelsesgradColors
} from './layerColors'
import layerTypes from './layerTypes'
import {
    trusselHelpList
} from '../../map/layers/help/helpTrussel'
import {
    beskyttelsesgradMapper
} from './dataMappers'


const range = (start, stop) => {
    const res = []
    for (let i = start; i <= stop; i++) {
        res.push(i)
    }
    return res
}

const zeropad = v => {
    if (v < 10) {
        return '0' + v
    }
    return '' + v
}

const trusselTitles = [
    "Hugst",
    "Rydning af vedplanter",
    "Overgræsning",
    "Grødeskæring i vandløb",
    "Fjernelse af ådsler",
    "Konvertering til jordbrug",
    "Konvertering til skovbrug",
    "Konvertering til bebyggelse",
    "Konvertering til råstofindvinding",
    "Konvertering til infrastruktur",
    "Efterstræbelse af arter",
    "Biocider",
    "Miljøfremmede stoffer",
    "Gødskning",
    "Kvælstofdeposition",
    "Tab af næringsstoffer",
    "Dræning",
    "Vandindvinding",
    "Udretning af vandløb",
    "Regulering og jagt",
    "Tilskudsfodring",
    "Mangel på græsning",
    "Slukning af naturbrande",
    "Kystsikring",
    "Sandflugtsdæmpning"  
]

const tnlayers = range(1, 25).map(i => ({
    title: trusselTitles[i - 1],
    type: layerTypes.layer,
    id: `t${zeropad(i)}_tb_tbg`,
    lowres: true,
    legend: [
        { title: 'Lav trussel, dårlig beskyttelse', color: beskyttelsesgradColors.low[0] },
        { title: 'Middel trussel, dårlig beskyttelse', color: beskyttelsesgradColors.medium[0] },
        { title: 'Høj trussel, dårlig beskyttelse', color: beskyttelsesgradColors.high[0] },
        { title: 'Lav trussel, middel beskyttelse', color: beskyttelsesgradColors.low[1] },
        { title: 'Middel trussel, middel beskyttelse', color: beskyttelsesgradColors.medium[1] },
        { title: 'Høj trussel, middel beskyttelse', color: beskyttelsesgradColors.high[1] },
        { title: 'Lav trussel, god beskyttelse', color: beskyttelsesgradColors.low[2] },
        { title: 'Middel trussel, god beskyttelse', color: beskyttelsesgradColors.medium[2] },
        { title: 'Høj trussel, god beskyttelse', color: beskyttelsesgradColors.high[2] }
    ],
    help: trusselHelpList[i - 1],
    dataMapper: beskyttelsesgradMapper
}))

export default tnlayers