import React from 'react'

import {
  AppBar,
  Toolbar,
  Tabs,
  Tab,
  makeStyles
} from '@material-ui/core'

import {
  Link,
  useLocation
} from "react-router-dom";

import aageJensesLogo from './aagevjensennaturfond.png'
import auLogo from './au.png'

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  buffer: {
    paddingLeft: 8,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}));

const TopBar = props => {
  const classes = useStyles()
  const { menus } = props
  const location = useLocation()
  const selectedTab = '/' + location.pathname.split('/')[1]
  //console.log(location.pathname, selectedTab)
  return (
    <AppBar color={props.color || 'primary'} position="fixed">
      <Toolbar>        
        <img alt='Aarhus Universitet Logo' src={auLogo} height='32px' />
        <div className={classes.buffer} />
        <Tabs value={selectedTab}>
          {menus.map(menu => {
            return (
              <Tab key={menu.path} label={menu.label} value={menu.value} component={Link} to={menu.path} />
            )
          })}
        </Tabs>
        <div className={classes.grow} />
        <img alt='Aage V Jensen Naturfond Logo' src={aageJensesLogo} height='40px' />
      </Toolbar>
    </AppBar>
  )
}

export default TopBar