import React from 'react'
import { HelpSection, HelpContainer, HelpInfo, HelpParagraph, InfoBox, HelpLink } from './help'
// import { Typography } from '@material-ui/core'

const byAU = 'Beregnet af Aarhus Universitet'

export const samletProcessHelp = (layer) => (
    <HelpContainer title={'Samlet process'}>
        <HelpSection>
            <HelpParagraph>
                Processerne afspejler i hvilken grad de naturlige processer på et sted får lov til at
                udfolde sig uden menneskers regulering og kontrol.
                Processerne er udtryk for den dynamik, som karakteriserer den vilde natur, og
                som er essentiel for skabelse og opretholdelse af levesteder i naturlige økosystemer.
                Her kan være mange ting på færde - eksempelvis stormfald, brand og også mere fredelige
                hverdagshændelser som plantevækst og parasitisme.
                Vi har valgt at se på fire centrale processer, som både er vigtige, og samtidig er stærkt påvirket
                af menneskers beslutninger: Hydrologi, græsning, oversvømmelser fra havet og sandflugt.
            </HelpParagraph>
            <HelpParagraph>
                De sidste to processer er begrænset til en zone nær havet, men da Danmark er
                omgivet af hav og huser en stor del af Europas strandenge og klitter har vi
                vurderet at kystdynamikken var vigtig at få inddraget under processerne.
            </HelpParagraph>
            <HelpLink href='dokumentation/04_processer' value="Læs mere her." />
        </HelpSection>
        <InfoBox version={layer.version} />
    </HelpContainer>
)


export const sandHelp = (layer) => (
    <HelpContainer title={'Sandflugt'}>
        <HelpSection>
            <HelpParagraph>
                Sandflugt forekommer hovedsageligt ved de eksponerede kyster
                f.eks. ved den jyske vestkyst hvor der både er stærk vind og
                tilgængeligt sand, der kan transporteres fra kysten og ind i
                landet. I store dele af landet er sandflugt dog hindret i større
                eller mindre grad vha. kystsikring. Længere inde i landet har
                 klitplantagerne, der blev etableret fra midten af 1800-tallet
                 for at standse transporten af sand ind i landet, stadig en stor effekt.
            </HelpParagraph>
            <HelpLink href='dokumentation/03_kystdynamik' value="Læs mere her." />
        </HelpSection>
        <InfoBox version={layer.verison}>
            <HelpInfo label='Kilde' value={byAU} />
            <HelpInfo label='Dato' value='2020' />
        </InfoBox>
    </HelpContainer>
)

export const sandWeightHelp = (layer) => (
    <HelpContainer title={'Sandflugt - relevans'}>
        <HelpSection>
            <HelpParagraph>
                Vægtlaget er et 0/1 lag, der har værdien 1, hvor sandflugtsprocessen er defineret
                og 0 alle andre steder. Sandflugtsprocessen er beregnet for de områder, som ifølge
                kortet over landskabselementer fra Institut for Agroøkologi (2014) er klitlandskaber
                som er karakteriseret ved aflejret flyvesand. Det antages at der i disse områder
                sker en naturlig sandflugt, hvis der ikke er enten erosionsdæmpende kystsikring
                eller en arealanvendelse som fx plantager eller bebyggelse, som bremser vindens
                omlejring af sandet vinkelret på kysten.
            </HelpParagraph>
        </HelpSection>
        <InfoBox version={layer.version}>
            <HelpInfo label='Kilde' value={byAU} />
            <HelpInfo label='Dato' value='2020' />
        </InfoBox>
    </HelpContainer>
)

export const sandBarriererHelp = layer => (
    <HelpContainer title={'Sandflugt - barrierer'}>
        <HelpSection>
            <HelpParagraph>
            Kortlaget viser de områder med bebyggelse, landbrug og trædække, der er blevet
            vurderet som barrierer for sandflugt i modelleringen af sandflugtprocessen.
            Arealerne er hentet fra Basemap03 (Levin 2019).
            </HelpParagraph>
        </HelpSection>
        <InfoBox version={layer.version}>
            <HelpInfo label='Kilde' value={byAU} />
            <HelpInfo label='Dato' value='2020' />
        </InfoBox>
    </HelpContainer>
)

export const sandKystsikringHelp = layer => (
    <HelpContainer title={'Sandflugt - kystsikring'}>
        <HelpSection>
            <HelpParagraph>
            kortlaget viser de kystsikringsanlæg og områder med skråningsbeskyttelse,
            der har til formål at hindre kysterosion og samt at hindre sandflugt.
            Anlæggene har indgået i modellering af sandflugtprocessen.
            Anlæggene er relativt små, så man skal zoome kraftigt i kortet for at kunne se anlæggene.
            </HelpParagraph>
        </HelpSection>
        <InfoBox version={layer.version}>
            <HelpInfo label='Kilde' value={byAU} />
            <HelpInfo label='Dato' value='2020' />
        </InfoBox>
    </HelpContainer>
)


export const floodingScoreHelp = layer => (
    <HelpContainer title={'Oversvømmelse'}>
        <HelpSection>
            <HelpParagraph>
                Oversvømmelsesprocessen er beregnet ved hjælp af en model, hvor vi lader havet skylle ind over lavtliggende landområder ved højvande.
                Oversvømmelsesprocessen er vurderet som naturlig, hvis der ikke sker nogen ændring i processen ved at fjerne
                eksisterende kystbeskyttende diger. For områder som oversvømmes sjældent under naturlige forhold,
                vurderes denne proces at have mindre betydning for naturen end i områder som oversvømmes ved dagligt højvande eller vinterhøjvande.
            </HelpParagraph>
        </HelpSection>
        <InfoBox version={layer.version}>
            <HelpInfo label='Kilde' value={byAU} />
            <HelpInfo label='Dato' value='2020' />
        </InfoBox>
    </HelpContainer>
)

export const floodingKystsikringHelp = layer => (
    <HelpContainer title={'Oversvømmelse - kystsikring'}>
        <HelpSection>
            <HelpParagraph>
            Kortlagt viser forekomst af diger, dæmninger og sluser,
            der har til formål at hindre eller reducere oversvømmelse
            af landområder med havvand. Anlæggene har indgået i modellering
            af oversvømmelsesprocessen.
            </HelpParagraph>
        </HelpSection>
        <InfoBox version={layer.version}>
            <HelpInfo label='Kilde' value={byAU} />
            <HelpInfo label='Dato' value='2020' />
        </InfoBox>
    </HelpContainer>
)

export const floodingWeightHelp = layer => (
    <HelpContainer title={'Oversvømmelse - relevans'}>
        <HelpSection>
            <HelpParagraph>
                Vægtlaget afspejler hvor hyppigt et landområde oversvømmes.
                Højbundsjorder, som ikke oversvømmes ved 100-årshændelser får vægten 0, så
                her tæller oversvømmelsesindikatoren ikke. De øvrige oversvømmelseshyppigheder tildeles vægtene:
                <ul>
                    <li>Under havniveau: 100</li>
                    <li>Højeste tidevand: 100</li>
                    <li>Vinterhøjvande: 100</li>
                    <li>20-års hændelse: 60</li>
                    <li>50-års hændelse: 40</li>
                    <li>100-års hændelse: 20</li>
                </ul>
            </HelpParagraph>
        </HelpSection>
        <InfoBox version={layer.version}>
            <HelpInfo label='Kilde' value={byAU} />
            <HelpInfo label='Dato' value='2020' />
        </InfoBox>
    </HelpContainer>
)

export const grasningHelp = layer => (
    <HelpContainer title={'Græsning'}>
        <HelpSection>
            <HelpParagraph>
            Græsning hører til blandt de vigtigste naturlige processer.
            Græsningen består i at dyr æder planter. Græsning som naturlig 
            proces i naturen afgøres primært af hvilke og hvor mange store
            planteædere, der findes i et økosystem, men det spiller også
            en rolle om dyrene har adgang til arealet hele året rundt 
            (helårsgræsning) eller om de sættes ud i naturområdet i en
            afgrænset periode (sæsongræsning). 
            </HelpParagraph>
            <HelpParagraph>
            I dette projekt afgrænser vi store planteædere til dyr med en
            kropsvægt på `&gt;` 20 kg, dvs. rådyr og større planteædere, dog
            tæller vi bæver med på grund af dens markante og unikke økosystemeffekt.
            </HelpParagraph>
        </HelpSection>
        <InfoBox version={layer.version}>
            <HelpInfo label='Kilde' value={byAU} />
            <HelpInfo label='Dato' value='2020' />
        </InfoBox>
    </HelpContainer>
)

export const grasningWeightHelp = layer => (
    <HelpContainer title={'Græsning'}>
        <HelpSection>
            <HelpParagraph>
                Vægtlaget for græsning er et 0/1 lag, 
                der har værdien 1 på land og i bredzonen
                på søer og værdien 0 alle andre steder.
            </HelpParagraph>
        </HelpSection>
        <InfoBox version={layer.version}>
            <HelpInfo label='Kilde' value={byAU} />
            <HelpInfo label='Dato' value='2020' />
        </InfoBox>
    </HelpContainer>
)

export const grasningDensitetHelp = layer => (
    <HelpContainer title={'Græsning - densitet'}>
        <HelpSection>
            <HelpParagraph>
            Tætheden af planteædere på et givent areal er afgørende for deres effekt. For meget og for lidt er lige unaturligt. Overgræsning i sommerperioden fører til kulstofkonkurrence, hvor de store planteædere æder biomassen, så insektfaunaen mister deres fødegrundlag, og mangel på græsning skaber problemer med tilgroning af de lysåbne blomsterrige naturarealer med høje urter, buske og træer.
            På baggrund af en gennemgang af danske og udenlandske studier af planteædere i forskellige økosystemer, samt erfaringer opsamlet i europåiske forsøg med græsning, vurderes det at den naturlige tæthed  af planteædere i gennemsnitlige, varierede danske økosystemer ligger mellem 70-250 kg/ha.
            Vi har sammensat kendt viden om forekomsten af græssere fra hhv. jagtudbyttestatistikken (vildtlevende hjorte), landbrugsoplysninger (græsningsordninger med husdyr) samt fra arealer hvor der sker helårsgræsning eller rewilding med flere arter af græssere.
            Det estimerede græsningstryk indgår i evalueringen af græsningsprocessen. 
            </HelpParagraph>
        </HelpSection>
        <InfoBox version={layer.version}>
            <HelpInfo label='Kilde' value={byAU} />
            <HelpInfo label='Dato' value='2020' />
        </InfoBox>
    </HelpContainer>
)


export const hydrologiHelp = layer => (
    <HelpContainer title={'Hydrologi'}>
        <HelpSection>
            <HelpParagraph>
                Hydrologien er beregnet for alle potentielt våde områder i Danmark, det vil sige ådalene, lavbundsjorderne og søerne.
                Her har vi vurderet at sandsynligheden for naturlig hydrologi var størst i moser og ferskvandsområder, mindre i fugtige naturtyper,
                mindre i tørre naturtyper og mindst i opdyrkede landbrugsarealer. Dernæst har vi vurderet at sandsynligheden for naturlig hydrologi var
                størst i områder med slyngede vandløb og uden stor tæthed af grøfter i arealerne. 
            </HelpParagraph>
        </HelpSection>
        <InfoBox version={layer.version}>
            <HelpInfo label='Kilde' value={byAU} />
            <HelpInfo label='Dato' value='2020' />
        </InfoBox>
    </HelpContainer>
)

export const hydrologiWeightHelp = layer => (
    <HelpContainer title={'Hydrologi - relevans'}>
        <HelpSection>
            <HelpParagraph>
                Vægtlaget er et 0/1 lag, der er 1 på lavbundsarealer, å- og sø-nære
                arealer samt søer  og 0 i resten af landet.
            </HelpParagraph>
        </HelpSection>
        <InfoBox version={layer.version}>
            <HelpInfo label='Kilde' value={byAU} />
            <HelpInfo label='Dato' value='2020' />
        </InfoBox>
    </HelpContainer>
)

export const hydrologiArealtyperHelp = layer => (
    <HelpContainer title={'Hydrologi - arealtyper'}>
        <HelpSection>
            <HelpParagraph>
            Kortlaget viser naturtyper og andre arealkategorier for den del af landskabet, hvor hydrologiindikatoren er defineret. Dvs. i ådale, på lavbundsjord, samt i søer.
            Som den grundlæggende pointtildeling i hydrologiindikatoren bruger vi arealtyperne som indikation på, om der stadigvæk er vand i vådområderne, eller vandet antages at være drænet væk.
            </HelpParagraph>
        </HelpSection>
        <InfoBox version={layer.version}>
            <HelpInfo label='Kilde' value={byAU} />
            <HelpInfo label='Dato' value='2021' />
        </InfoBox>
    </HelpContainer>
)

export const hydrologiNaturandelHelp = (layer) => (
    <HelpContainer title={'Hydrologi - naturandel'}>
        <HelpSection>
            <HelpParagraph>
            Denne indikator angiver tætheden af natur i landskabet omkring et potentielt vådområde.
            Princippet bag at anvende naturandelen er, at jo mere af lokalområdet som er lagt ud til natur,
            jo mindre vil man forvente at man har anstrengt sig for at afvande området.
            Denne indikator er beregnet ligesom naturandelen i biodiversitetskortet. Områder med 
            lavest naturtæthed får reduceret hydrologiscoren med 20 point mens områder med høj
            naturtæthed for opjusteret hydrologiscoren med op til 30 point.  
            </HelpParagraph>
        </HelpSection>
        <InfoBox version={layer.version}>
            <HelpInfo label='Kilde' value={byAU} />
            <HelpInfo label='Dato' value='2021' />
        </InfoBox>
    </HelpContainer>
)

export const hydrologiPlantetalHelp = (layer) => (
    <HelpContainer title={'Hydrologi - plantetal'}>
        <HelpSection>
            <HelpParagraph>
            Plantetallet er baseret på plantelister fra naturområder som er overvåget, kortlagt eller
            besigtiget af myndighederne. Plantetallet baseres på middelværdien af arternes indikatortal,
            som er udviklet til naturtilstandsvurdering efter Miljømålsloven. Denne middelværdi afspejler
            i hvor høj grad plantearterne i området er vilde planter med forkærlighed for naturligt
            næringsfattige levesteder. Næringsfattige levesteder er et godt tegn på naturlig hydrologi,
            da næringsstofferne typisk kommer ind i naturområderne via drænvand, efter afvanding og
            nedbrydning af næringsrige tørvelag eller efter afvanding og opdyrkning. Begrebet "plantetal"
            i denne betydning anvendes i High Nature Value (HNV) kortet (Bladt & Moeslund, 2016) samt
            i biodiversitetskortet. Områder med vegetationslister der ikke kvalificeret til plantetal
            1 (lavt) får reduceret hydrologiscoren med 10 point. Områder med plantetal 1 (moderat)
            får opjusteret hydrologiscoren medd 10 point, mens områder med plantetal 2 og 3 (højt)
            får opjusteret hydrologiscoren med 20 point.  
            </HelpParagraph>
        </HelpSection>
        <InfoBox version={layer.version}>
            <HelpInfo label='Kilde' value={byAU} />
            <HelpInfo label='Dato' value='2021' />
        </InfoBox>
    </HelpContainer>
)

export const hydrologiSlyngningHelp = (layer) => (
    <HelpContainer title={'Hydrologi - slyngningsgrad'}>
        <HelpSection>
            <HelpParagraph>
            Mens grøfter og stærkt modificerede vandløb næsten altid løber dybt under terræn
            pga. nedgravning, så løber naturlige vandløb typisk terrænnært, og udvikler med
            tiden et mæandrerende forløb. Vi antager, at hovedparten af de mest slyngede
            strækninger af de danske vandløb ligger terrænnært og ikke har en unaturligt
            drænende effekt på de omkringliggende lavbundsarealer. De dele af ådalene der
            ligger tættest på de mest slyngede vandløbsstrækninger får reguleret naturtypescoren
            positivt, mens de resterende dele af ådalene får reguleret naturtypescoren negativt.
            De stærkt slyngede vandløbsstrækninger er blevet identificeret ved at analysere
            slyngningsforhold af vandløbene i GeoDanmark datasættet.
            </HelpParagraph>
        </HelpSection>
        <InfoBox version={layer.version}>
            <HelpInfo label='Kilde' value={byAU} />
            <HelpInfo label='Dato' value='2021' />
        </InfoBox>
    </HelpContainer>
)


export const hydrologiGroeftetalHelp = (layer) => (
    <HelpContainer title={'Hydrologi - grøftetæthed'}>
        <HelpSection>
            <HelpParagraph>
            Vi vurderer, at områder med høj grøftetæthed er mere afvandede end områder med lav grøftetæthed.
            Områder med høj og moderat grøftetæthed får således reduceret hydrologiscoren med hhv. 30 og 10 point,
            mens områder med lav grøftetæthed ikke får reduceret hydrologiscoren.
            Formodede grøfter og stærkt modificerede vandløbsstrækninger er blevet identificeret ved at analysere
            slyngningsforhold af vandløbene i GeoDanmark datasættet.
            </HelpParagraph>
        </HelpSection>
        <InfoBox version={layer.version}>    
        <HelpInfo label='Kilde' value={byAU} />
            <HelpInfo label='Dato' value='2021' />                    
        </InfoBox>
    </HelpContainer>
)